.navbar .navbar-brand {
  margin-left: -40px;
  margin-right: unset;
  @media (max-width: $screen-lg) {
    margin-left: 0;
  }
  @media (max-width: $screen-md) {
    .logo {
      height: 60px;
    }
  }
}
.container.block-home {
  padding: 0;
  > section {
    margin: 20px 0;
  }
  @media (max-width: $screen-md) {
    padding: 0 10px;
  }
}
.navbar {
  box-shadow: unset;
  .logo {
    height: 100px;
  }
  .navbar-collapse {
    .navbar-nav {
      .nav-item {
        .nav-link {
          font-size: 18px;
          color: #B3B3B3;
        }
      }
    }
  }
  .icon-profile-nonsign {
    background-color: rgb(179, 179, 179, 0.3);
    border-radius: 50%;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: $screen-sm) {
      width: 30px;
      height: 30px;
    }
    svg {
      width: 30px;
      height: 30px;
      color: #00A9DC;
      @media (max-width: $screen-sm) {
        width: 20px;
        height: 20px;
      }
    }
  }
  .navbar-button {
    gap: 12px;
    .nav-item {
      margin-left: 0;
      width: max-content;
      .btn-link {
        font-size: 18px;
        color: #B3B3B3;
        @media (max-width: $screen-lg) {
          font-size: 16px;
        }
        @media (max-width: $screen-sm) {
          font-size: 14px;
          padding: 0;
        }
      }
    }
    .sign-in::after {
      content: "";
      border-right: 1px solid #B3B3B3;
    }
    .navbar-toggler {
      margin-left: 8px;
      flex-basis: unset;
    }
  }
}
.canvas-menu {
  .container {
    @media (max-width: $screen-lg) {
      justify-content: unset;
      .navbar-toggler {
        flex-basis: unset;
      }
      .navbar-brand {
        width: 90%;
        margin: auto;
        text-align: center;
        .logo {
          margin: auto;
        }
      }
   }
  }
  .search {
    @media (max-width: $screen-lg) {
      margin-top: 130px;
      padding: 1rem calc(var(--bs-gutter-x)* 0.5) 1rem;
    }
  }
}
.search-bar {
  top: 128px !important;
  @media (max-width: $screen-lg) {
    top: 115px !important;
  }
  @media (max-width: $screen-md) {
    top: 75px !important;
  }
  @media (max-width: $screen-sm) {
    top: 85px !important;
  }
}
section.main {
  margin-top: 130px;
  .container.block-home {
    .landing {
      position: relative;
      display: flex;
      flex-direction: column;
      border-radius: 20px;
      min-height: 600px;
      align-items: unset;
      background-color: #F6FDFF;
      @media (max-width: $screen-lg) {
        padding: 67px 0;
      }
      .img-hero {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 0;
        right: 0;
        top: 0;
        object-fit: contain;
        @media (max-width: $screen-lg) {
          position: relative;
        }
      }
      .box-text {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: unset;
        max-width: unset;
        padding: 0 5%;
        z-index: 1;
        margin: auto 0;
        > .title-label {
          font-size: 48px;
          line-height: 65px;
          @media (max-width: $screen-sm) {
            font-size: 32px;
            line-height: 40px;
          }
        }
        > .label-text {
          font-size: 24px;
          line-height: 30px;
          @media (max-width: $screen-sm) {
            font-size: 20px;
            line-height: 28px;
          }
        }
        max-width: unset;
        padding: 0 5%;
        @media (max-width: $screen-lg) {
          // width: 500px;
          padding: 0 10%;
        }
      }
      .box-search-new {
        width: 90%;
        margin-left: 5%;
        border: 1px solid #EBEBEB;
        background: #fff;
        box-shadow: 2px 4px 8px 0px rgba(0, 0, 0, 0.25);
        gap: unset;
        bottom: 100px;
        padding: 1rem 1rem 0;
        position: relative;
        @media (max-width: $screen-lg) {
          bottom: 80px;
        }
        .react-datepicker-wrapper {
          width: 100%;
        }
        .btn-search {
          font-weight: bold;
          min-width: unset;
          width: 100%;
          padding: 10.65px 0.75rem;
          @media (max-width: $screen-lg) {
            min-width: unset;
          }
        }
      }
    }
  }
}
hr.line {
  width: 250px;
  border: 10px solid;
  border-image-slice: 1;
  border-width: 5px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-image-source: linear-gradient(to right, rgba(14, 168, 220, 1) 0%, rgba(153, 69, 184, 1) 100%);
  opacity: 1;
  margin: 0;
}
hr.line2 {
  border-image-source: linear-gradient(to right, rgba(14, 168, 220, 0.2) 0%, rgba(153, 69, 184, 0.2) 100%);
}
._headline {
  .title {
    font-size: 48px;
    line-height: normal;
    font-weight: bold;
    @media (max-width: $screen-sm) {
      font-size: 32px;
    }
  }
}
section.experiences {
  margin-top: 0 !important;
  background: #091A33;
  padding: 80px 50px;
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: space-around;
  .text-box {
    display: flex;
    flex-direction: column;
    color: #FFF;
    line-height: 30px;
    font-size: 22px;
    gap: 5px;
    max-width: 280px;
    @media (max-width: $screen-sm) {
      font-size: 18px;
      line-height: normal;
    }
    .heading {
      font-size: 48px;
      font-weight: bolder;
      line-height: normal;
      display: flex;
      gap: 5px;
      max-width: max-content;
      @media (max-width: $screen-sm) {
        font-size: 32px;
      }
      .highlight {
        color: #3FA9F5;
      }
      border: 10px solid;
      border-image-slice: 1;
      border-width: 5px;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-image-source: linear-gradient(to right, rgba(14, 168, 220, 1) 0%, rgba(153, 69, 184, 1) 100%);
    }
    .text {
      font-size: 16px;
    }
  }
  .block-icon {
    display: flex;
    gap: 20px;
  }
  .icon-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: max-content;
    margin: auto;
    gap: 15px;
    background-image: url('../images/asset3.png');
    background-repeat: no-repeat;
    background-size: contain;
    padding: 20px;
    width: 220px;
    height: 220px;
    img {
      height: 70px;
      margin: 10px 0;
    }
    .number {
      font-size: 38px;
      color: #FFF;
      font-weight: 600;
    }
    .label {
      font-size: 16px;
      color: #3FA9F5;
      font-weight: 400;
    }
  }
}
section.promotion {
  margin-bottom: 0 !important;
  background-color: #E4FAFF;
  padding: 50px;
  @media (max-width: $screen-md) {
    padding: 30px 10px;
  }
  ._headline {
    margin-bottom: 10px;
    .headline {
      display: flex;
      font-size: 48px;
      line-height: 50px;
      font-weight: bold;
      height: auto;
      margin-bottom: 0;
      @media (max-width: $screen-sm) {
        font-size: 28px;
        line-height: normal;
      }
    }
    .title {
      font-size: 28px;
      font-weight: 400;
      line-height: normal;
      @media (max-width: $screen-sm) {
        font-size: 18px;
      }
    }
  }
  > img {
    width: 100%;
  }
  .carousel-item {
    img {
      width: 100%;
      object-fit: contain;
    }
  }
}
.search-province {
  min-height: 300px;
  padding: 0 50px 80px;
  @media (max-width: $screen-md) {
    padding: 0 10px 40px;
  }
  ._headline {
    padding-top: 30px;
    margin-bottom: 50px;
    .headline {
      display: flex;
      font-size: 48px;
      line-height: 50px;
      font-weight: bold;
      margin-bottom: unset;
      @media (max-width: $screen-sm) {
        font-size: 30px;
        line-height: 48px;
      }
    }
    .title {
      font-size: 28px;
      font-weight: 400;
      line-height: normal;
      @media (max-width: $screen-sm) {
        font-size: 20px;
      }
    }
  }
  img.bg-layout {
    right: 20%;
    position: absolute;
    object-fit: contain;
    z-index: -1;
    height: 900px;
    @media (max-width: $screen-lg) {
      right: 10%;
    }
    @media (max-width: $screen-md) {
      right: 5%;
    }
    @media (max-width: $screen-xs) {
      right: 0;
    }
  }
  .img-box {
    position: relative;
    width: 32%;
    img {
      width: 100%;
      object-fit: contain;
    }
    .label-box {
      position: absolute;
      top: 40%;
      width: 100%;
      .label {
        text-align: center;
        color: #FFF;
        font-size: 38px;
        font-weight: 400;
        @media (max-width: $screen-sm) {
          font-size: 28px;
        }
      }
    }
  }
}
.card-nursing-new {
  box-shadow: 0px 0px 20px 5px #0000000F;
  background-color: #FFF;
  border-radius: 20px;
  border: none;
  height: 100%;
  .pin-map {
    height: 20px;
  }
  .detail {
    .title {
      h6 {
        font-size: 20px;
      }
    }
  }
  .location {
    margin-top: auto;
    font-size: 14px;
  }
  .price {
    border-top: unset;
    .price_new {
      padding: 5px 10px;
      background-color: $primary-400;
      border-radius: 20px;
      width: max-content;
      .per-month {
        color: #FFF;
      }
    }
  }
  .rating {
    position: absolute;
    padding: 10px;
    top: 5px;
    right: 5px;
    background-color: #FFF;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 18px;
    border: 1px solid rgb(179, 179, 179, 0.1);
    .rate {
      color: #B3B3B3;
      font-size: 20px;
      font-weight: 600;
    }
    .review {
      font-size: 12px;
      color: #B3B3B3;
    }
  }
  .block-image {
    height: 85%;
    img {
      aspect-ratio: 4/3;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .bookmark {
    display: flex;
    align-items: center;
    color: #B3B3B3;
    margin-top: 4px;
    font-size: 12px;
    img.bookmark {
      margin: 0;
      height: 20px;
    }
  }
}
section.partner-contact {
  margin-bottom: 0 !important;
  background-color: #E4FAFF;
  padding: 30px 0;
  .block {
    position: relative;
    z-index: 2;
    .block-img {
      .partner-img-bg {
        width: 100%;
        height: auto;
        z-index: 1;
      }
      position: relative;
      .partner-img {
        position: absolute;
        bottom: -30px;
        height: auto;
        z-index: 0;
        width: 100%;
        right: -20px;
        @media (max-width: $screen-md) {
          width: 80%;
          right: 0;
          bottom: -10px;
        }
      }
    }
    .block-label {
      position: relative;
      z-index: 3;
      margin: auto;
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: max-content;
      .title {
        font-size: 24px;
        font-weight: 200;
        line-height: 28px;
        @media (max-width: $screen-sm) {
          font-size: 18px;
          line-height: 24px;
        }
      }
      .label-1 {
        font-size: 40px;
        font-weight: 600;
        line-height: 48px;
        @media (max-width: $screen-sm) {
          font-size: 28px;
          line-height: 34px;
        }
      }
      .btn-icon {
        width: fit-content;
        display: flex;
        align-items: center;
        gap: 4px;
        color: #F5F4FD;
        background-color: #11223E;
        border-color: #11223E;
        border-radius: 30px;
        font-size: 14px;
      }
      @media (max-width: $screen-sm) {
        font-size: 14px;
      }
    }
  }
}
section.our-partner {
  margin-bottom: 0 !important;
  padding: 30px 0;
  .title {
    width: fit-content;
    margin: auto;
    font-size: 24px;
    font-weight: 600;
    line-height: 34px;
    @media (max-width: $screen-sm) {
      font-size: 18px;
      line-height: 24px;
    }
  }
  .content {
    width: fit-content;
    margin: auto;
    font-size: 24px;
    font-weight: 600;
    line-height: 34px;
    .highlight {
      color: #9945B8;
    }
    @media (max-width: $screen-sm) {
      font-size: 18px;
      line-height: 24px;
    }
  }
  .our-images {
    margin-top: 30px;
    img {
      margin: auto;
      width: 180px;
      height: auto;
      display: flex;
      @media (max-width: $screen-lg) {
        width: 250px;
      }
      @media (max-width: $screen-sm) {
        width: 120px;
      }
    }
  }
}
section.contact {
  margin-bottom: 0 !important;
  background-color: #AEE0FF;
  padding: 30px 0;
  .content {
    position: relative;
    min-height: 304px;
    img.image {
      width: 600px;
      right: 0;
      bottom: -30px;
      height: auto;
      position: absolute;
      z-index: 0;
      @media (max-width: $screen-md) {
        width: 450px;
      }
    }
    img.image.mini {
      width: 180px;
      height: auto;
    }
    .label {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin: auto 0 0 5%;
      @media (max-width: $screen-sm) {
        width: auto;
      }
      > * {
        z-index: 1;
      }
      @media (max-width: $screen-sm) {
        font-size: 10px;
        line-height: 14px;
      }
      .label-1 {
        width: fit-content;
        font-size: 20px;
        line-height: 28px;
        color: #FFF;
        @media (max-width: $screen-sm) {
          font-size: 16px;
          line-height: 20px;
        }
      }
      .label-2 {
        width: fit-content;
        font-size: 32px;
        line-height: 40px;
        font-weight: 500;
        @media (max-width: $screen-sm) {
          font-size: 24px;
          line-height: 32px;
        }
      }
      .btn-icon {
        margin-left: 20px;
        width: fit-content;
        display: flex;
        align-items: center;
        gap: 4px;
        color: #F5F4FD;
        border-radius: 30px;
        font-size: 20px;
        font-weight: 600;
        .phone {
          width: 24px;
          height: auto;
        }
        @media (max-width: $screen-sm) {
          margin-left: 0;
        }
      }
    }
  }
}
.floating-banner {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 3;
  display: flex;
  flex-direction: column;
  gap: 8px;
  img {
    height: 110px;
    width: auto;
  }
  .btn-icon.line {
    background-color: #3EB657;
    background-color: #3EB657;
    border-color: #3EB657;
  }
  .btn-icon {
    margin: auto;
    display: flex;
    align-items: center;
    background-color: #11223E;
    color: #FFF;
    border-radius: 30px;
    background-color: #11223E;
    border-color: #11223E;
    width: fit-content;
    .phone {
      width: 28px;
      height: auto;
    }
    .line {
      width: 28px;
      height: auto;
    }
    .text {
      padding: 0 12px;
      font-size: 18px;
      font-weight: 600;
      .label {
        font-size: 8px;
        font-weight: 200;
      }
    }
  }
  @media (max-width: $screen-sm) {
    right: 0.5rem;
    img {
      height: 110px;
    }
    .phone {
      width: 28px !important;
    }
    .btn-icon {
      .text {
        font-size: 28px;
        .label {
          font-size: 8px;
        }
      }
    }
  }
}
section.footer {
  background-color: #11223E;
  padding: 20px 0;
  @media (max-width: $screen-md) {
    padding: 20px 20px;
  }
  .footer-brand {
    height: 120px;
    width: auto;
    padding: 20px 0;
  }
  .location {
    margin: 10px 0;
    display: flex;
    gap: 8px;
    align-items: center;
    img {
      height: 20px;
      width: auto;
    }
  }
  .clock {
    margin: 10px 0;
    display: flex;
    gap: 8px;
    img {
      height: 20px;
      width: auto;
    }
    .column {
      display: flex;
      flex-direction: column;
    }
  }
  .icon-social {
    display: flex;
    gap: 12px;
    margin-bottom: 10px;
    a {
      margin: 0;
      padding: 15px;
      background-color: #071328;
      border-radius: 50%;
      img {
        width: auto;
        height: 40px;
      }
    }
  }
  .text-right {
    text-align: right;
  }
  .section-2 {
    padding-top: 40px;
    .title {
      font-size: 24px;
      font-weight: 400;
    }
    .data {
      margin-top: 20px;
      display: flex;
      gap: 8px;
      flex-direction: column;
    }
  }
}
section.footer.copy {
  background-color: #091A33;
}
.custom-autocomplete .wrapper > :not(:first-child):last-child {
  position: absolute;
  background-color: #FFF;
  top: 50px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  max-width: calc(100vw - 4rem);
  .autocomplete-item {
    img {
      margin: auto;
      width: 60px;
      height: 60px;
      object-fit: contain;
      border-radius: 30px;
      @media (max-width: $screen-sm) {
        width: 40px;
        height: 40px;
      }
    }
  }
}
