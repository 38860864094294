$screen-lg: 991px;
$screen-md: 768px;
$screen-sm: 479px;
$screen-xs: 375px;

$primary-900: #004C84;
$primary-800: #006AA4;
$primary-700: #007BB7;
$primary-600: #008DCA;
$primary-500: #009AD7;
$primary-400: #00A9DC;
$primary-300: #42B7E0;
$primary-200: #78CBE9;
$primary-100: #AEE0F2;
$primary-50: #DFF3FA;
$primary: $primary-400;

$secondary: #A23F96;
$secondary-600: #851B81;
$secondary-300: #B260A8;
$secondary-200: #C98CC7;
$secondary-100: #DEBAD9;
$secondary-50: #F2E3EF;

$success: #45BF5D;
$warning: #FFC012;
$danger: #FF2828;

$background: #F7F7F7;
$surface: #F7F7F7;
$light-gray: #ACACAC;
$dark-gray: #808080;
$line-gray: #EBEBEB;
$color-white: #FFFFFF;

$bg-surface: #F7F7F7;
$bg-light: #FFFFFF;

$color-black: #000;

$size-8: 8px;
$size-10: 10px;
$size-12: 12px;
$size-14: 14px;
$size-16: 16px;
$size-18: 18px;
$size-20: 20px;
$size-22: 22px;
$size-24: 24px;
$size-26: 26px;
$size-28: 28px;
$size-30: 30px;
$size-32: 32px;
$size-34: 34px;
$size-36: 36px;
