@font-face {
	font-family: 'Noto Sans Thai';
	src: url('./NotoSansThai-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Noto Sans Thai';
	src: url('./NotoSansThai-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: bold;
}