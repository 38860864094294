@import "../fonts/noto-sans/font.scss";

::placeholder {
	color: $light-gray !important;
}
:focus-visible {
	outline: none;
}
html {
	touch-action: pan-x pan-y;
}
body, .react-tel-input {
	font-family: 'Noto Sans Thai', sans-serif;
	font-size: 16px;
	line-height: 24px;
	background-color: #FFF;
	color: #000;
}
body.modal-open {
	overflow: hidden;
}
.react-tel-input {
	background-color: transparent;
}
a, .nav-link, .btn-link, .link {
	color: $color-black;
	text-decoration: none;
}
a:hover, .nav-link:hover, a:focus, .nav-link:focus, .btn-link:hover, .link:hover {
	color: $secondary;
}
.nav-link.active {
	font-weight: bold;
	color: $secondary !important;
}
.link:hover {
	cursor: pointer;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6, b, strong, .text-bold {
	font-weight: bold;
}
.text-normal {
	font-family: 'Noto Sans Thai';
}
.font-10 {
	font-size: 10px;
}
.font-12 {
	font-size: 12px;
}
.font-14 {
	font-size: 14px !important;
}
.font-16 {
	font-size: 16px !important;
}
.font-20 {
	font-size: 20px;
}
.font-24 {
	font-size: 24px;
}
.font-28 {
	font-size: 28px;
}
.font-36 {
	font-size: 36px;
}
.gap-8 {
	gap: 8px;
}
.gap-20 {
	gap: 20px;
}
.pre-wrap {
	white-space: pre-wrap;
}
.line-through {
	text-decoration: line-through;
}
.none-resize {
	resize: none;
}
.text-pbb {
  font-family: 'Noto Sans Thai', sans-serif;
  font-style: bold;
  font-size: $size-16 !important;
  font-weight: 600 !important;
  line-height: 24px;
}
.text-pb {
  font-family: 'Noto Sans Thai', sans-serif;
  font-size: $size-16 !important;
  font-weight: 300 !important;
  line-height: 24px;
}
.text-sbb {
  font-family: 'Noto Sans Thai', sans-serif;
  font-style: bold;
  font-size: $size-12 !important;
  line-height: 16px;
  font-weight: 600;
}
.text-sb {
  font-family: 'Noto Sans Thai', sans-serif;
  font-size: $size-12 !important;
  line-height: 16px;
  font-weight: 300;
}
.text-underline {
	text-decoration: underline;
}
.text-primary {
	color: $primary !important;
}
.text-primary-300 {
	color: $primary-300;
}
.text-primary-900 {
	color: $primary-900 !important;
}
.text-secondary {
	color: $secondary !important;
}
.text-secondary-200 {
	color: $secondary-200 !important;
}
.text-success {
	color: $success !important;
}
.text-warning {
	color: $warning !important;
}
.text-danger {
	color: $danger !important;
}
.text-light-gray {
	color: $light-gray !important;
}
.text-dark-gray {
	color: $dark-gray;
}
.text-line-gray {
	color: $line-gray;
}
.text-9acd32 {
	color: #9acd32;
}
.text-ffc43f {
	color: #ffc43f;
}
.text-ff7556 {
	color: #ff7556;
}
.bg-success {
	background-color: $success !important;
}
.bg-primary {
	background-color: $primary !important;
}
.bg-primary-600 {
	background-color: $primary-600;
}
.bg-secondary {
	background-color: $secondary !important;
}
.bg-surface {
	background-color: $surface !important;
}
.bg-dark-gray {
	background-color: $dark-gray;
}
.bg-disable {
	background-color: $light-gray;
}
.border-secondary-200 {
	border-color: $secondary-200;
}
.border-surface {
	border-color: $surface !important;
}
.badge.bg-surface {
	color: $primary-900;
}
.w-200px {
	width: 200px;
}
.min-width-50 {
	min-width: 50px;
}
.min-width-100 {
	min-width: 100px !important;
}
.min-width-120 {
	min-width: 120px;
}
.min-width-200, .miw-200 {
	min-width: 200px;
}
.min-width-230 {
	min-width: 230px !important;
}
.min-width-280 {
	min-width: 280px !important;
}
.min-width-300 {
	min-width: 300px !important;
}
.min-width-350 {
	min-width: 350px;
}
.min-width-400 {
	min-width: 400px !important;
}
.maw-200 {
	max-width: 200px;
}
.max-width-65 {
	max-width: 65%;
}
.max-width-250 {
	max-width: 250px;
}
.max-width-260 {
	max-width: 260px;
}
.max-width-300 {
	max-width: 300px;
}
.max-width-400 {
	max-width: 400px;
}
.max-width-450 {
	max-width: 450px;
}
.max-width-500 {
	max-width: 500px;
}
.max-width-600 {
	max-width: 600px;
}
.max-width-620 {
	max-width: 620px;
}
.max-width-820 {
	max-width: 820px;
}
.input-min-w-400 {
	min-width: 400px;
}
.cursor-pointer {
	cursor: pointer !important;
}
.lh-normal {
	line-height: normal;
}
.lh-15 {
	line-height: 15px;
}
.lh-15-6 {
	line-height: 15.6px;
}
.lh-19 {
	line-height: 19px;
}
.lh-20 {
	line-height: 20px;
}
.lh-30 {
	line-height: 30px;
}
.mt-300 {
	margin-top: 300px;
}
.mb-00 {
	margin-bottom: 0 !important;
}
.mb-22 {
	margin-bottom: 0.5rem !important;
}
.mb-25 {
	margin-bottom: 12px;
}
.mb-80 {
	margin-bottom: 80px;
}
.mb-120 {
	margin-bottom: 120px !important;
}
.me-120 {
	margin-right: 120px !important;
}
.p-20 {
	padding: 20px;
}
.clearfix {
	clear: both;
	display: inline-block;
}
.word-break-all {
	word-break: break-all;
}
.wspace-wrap {
	white-space: pre-wrap;
}
.table {
	color: $primary-900;
}
.form-control, .form-select {
	padding: 0.5rem 1rem;
	border-radius: 8px;
	border-color: $line-gray;
	line-height: 30px;
}
.form-control:focus, .form-select:focus, .form-data .form-control {
	border-color: $light-gray;
}
textarea.form-control {
	border-radius: 8px;
}
.slide-range {
	appearance: none;
	width: 100%;
	height: 8px;
	background: $line-gray;
	border-radius: 10px;
	background-image: linear-gradient($secondary-200, $secondary-200);
	background-size: 1% 100%;
	background-repeat: no-repeat;
}
.slide-range::-webkit-slider-thumb {
	appearance: none;
	height: 40px;
	width: 40px;
	border-radius: 50%;
	background: $secondary-100;
	cursor: pointer;
	transition: background .3s ease-in-out;
}
.slide-range::-webkit-slider-thumb:hover {
	background: $secondary-100;
}
.slide-range::-webkit-slider-runnable-track {
	appearance: none;
	box-shadow: none;
	border: none;
	background: transparent;
}
.symptoms-button {
	display: block;
	background-color: $secondary-200;
	border-radius: 50%;
	width: 32px;
	height: 32px;
	color: #fff;
	font-size: 30px;
	text-align: center;
	padding-top: 2px;
	cursor: pointer;
}
.symptoms .badge {
	border-radius: 10px;
	padding: 4px 8px;
}
.modal-open .navbar {
	padding-right: 15px;
}
.navbar {
	background-color: #FFF;
	box-shadow: 0px 4px 8px 0px #0000000D;
	padding: 14px 0;
}
.search-open .navbar {
	box-shadow: none;
	border-bottom: 1px solid $surface;
}
.navbar-toggler {
	padding: 0 8px;
}
.navbar-toggler:focus {
	box-shadow: none;
}
.navbar-toggler svg {
	color: $primary;
}
.navbar .navbar-brand {
	padding: 0;
	margin: 0 2rem 0 0;
}
.navbar .logo {
	height: 40px;
	padding: 5px 0;
}
.navbar-expand-lg .navbar-nav .nav-link {
	padding: 0 1rem;
}
.navbar-button .nav-item {
	margin-left: 20px;
}
.navbar-button .nav-item .btn {
	min-width: 100px;
}
.navbar-button .nav-item .btn.icon {
	justify-content: center;
	background-color: $surface !important;
	border: solid 1px #fff;
	border-radius: 50%;
	height: 44px;
	width: 44px;
	min-width: auto;
	padding: 0;
}
.navbar-button .nav-item .btn.icon:hover, .navbar-button .nav-item.show .btn.icon {
	background-color: $secondary-200 !important;
	color: #fff;
}
.navbar-button .nav-item .btn.icon.account {
	display: inline-flex;
	justify-content: flex-start;
	align-items: center;
	border-radius: 22px;
	width: auto;
	padding: 5px 8px 5px 5px;
	min-width: 100px;
}
.navbar-button .nav-item .btn.icon.account:hover, .navbar-button .nav-item.show .btn.icon.account {
	background-color: $secondary-200 !important;
}
.navbar-button .nav-item.show .btn.icon.account svg {
	color: $primary-900;
}
.navbar-button .nav-item .btn.icon.account .account-icon, .navbar-button .dropdown-menu .card-account .account-icon, .doctor-img, .block-img-profile {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: $primary-50;
	border-radius: 50%;
	height: 32px;
	width: 32px;
}
.navbar-button .dropdown-menu .card-account .account-icon, .doctor-img {
	color: $primary-900;
	height: 64px;
	width: 64px;
	padding: 18px;
}
.doctor-img, .block-img-profile {
	position: relative;
	height: 72px;
	width: 72px;
	text-align: center;
	display: block;
	margin: 0 auto;
	margin-bottom: 7px;
	padding: 0;
}
.doctor-img img {
	object-fit: cover;
	height: 72px;
}
.block-img-profile img {
	object-fit: cover;
}
.doctor-img .status {
	width: 12px;
	height: 12px;
	border-radius: 50%;
	position: absolute;
	top: 4px;
	right: 4px;
	background-color: $success;
}
.doctor-img .status.busy {
	background-color: $warning;
}
.doctor-img.avatar-60 {
	height: 60px;
	width: 60px;
	padding: 14px;
	margin: 0;
}
.dropdown-item.active, .dropdown-item:active {
	color: #1e2125;
	background-color: #e9ecef;
}
.dropdown-item:focus, .dropdown-item:hover {
	background-color: $primary-50;
}
.navbar-button .nav-item .btn.icon.account .account-icon img, .navbar-button .dropdown-menu .card-account .account-icon img, .doctor-img img, .block-img-profile img, .block-nursing-detail .title .center-logo img {
	border-radius: 50%;
	height: 100%;
	width: 100%;
	object-fit: cover;
}
.navbar-button .nav-item .btn.icon.account:hover .account-icon, .block-img-profile {
	color: $primary-900;
}
.navbar-button .nav-item.dropdown .dropdown-menu, .consult .timezone .dropdown-menu {
	border: 1px solid $line-gray;
	box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
	border-radius: 10px;
	top: 16px !important;
	padding: 16px;
	overflow-y: auto;
	width: 400px;
	max-height: 600px;
}
.navbar-button .nav-item.dropdown .dropdown-menu::-webkit-scrollbar, .consult .timezone .dropdown-menu::-webkit-scrollbar, .block-list-cookie::-webkit-scrollbar, .modal-dialog-scrollable .modal-body::-webkit-scrollbar {
	width: 0;
}
.navbar-button .dropdown-menu > :nth-child(2), .navbar-button .dropdown-menu .dropdown-item.first-child {
	border-radius: 10px 10px 0 0;
}
.navbar-button .dropdown-menu > :last-child, .navbar-button .dropdown-menu .dropdown-item.last-child {
	border-radius: 0 0 10px 10px;
}
.navbar-button .dropdown-menu .dropdown-item.round {
	border-radius: 10px;
}
.navbar-button .dropdown-menu > :last-child .row {
	border: none;
}
.navbar-button .dropdown-header {
	font-size: 24px;
	line-height: 36px;
	letter-spacing: 0.02em;
	color: $secondary-200;
	margin-bottom: 16px;
	padding: 0;
}
.navbar-button .dropdown-item {
	display: block;
	background: $surface !important;
	color: $primary-900;
	padding: 0 13px !important;
	border: none;
	border-radius: 0;
}
.navbar-button .dropdown-item .row {
	padding: 16px 0;
	border-bottom: solid 1px #fff;
}
.navbar-button .dropdown-item .row .col, .navbar-button .dropdown-item .row .col-auto {
	padding: 0;
}
.navbar-button .dropdown-item .menu-icon {
	width: 35px;
}
.navbar-button .dropdown-item:hover {
	background-color: $primary-50 !important;
}
.navbar-button .dropdown-item:hover .row {
	border-color: $primary-50;
}
.navbar-button .dropdown-item.p-3 {
	padding: 1rem !important;
}
.navbar-button .dropdown-menu .card-account {
	background-color: $surface;
	padding: 16px;
	border-radius: 10px;
}
.card-account .action-account {
	color: $secondary-200;
}
.card-account .action-account:hover {
	text-decoration: underline;
}
.navbar-button .dropdown-menu .card-level {
	padding: 16px 16px 12px;
	border-radius: 10px;
}
.card-level.platinum {
	background: linear-gradient(360deg, #CDCCCC 11.74%, #EAEAEA 72.09%);
}
.card-level hr {
	border: solid 1px #fff;
}
.btn, .btn-check:focus + .btn, .btn:focus {
	font-size: 16px;
	border: solid 1px;
	padding: 11px 0.75rem;
	box-shadow: none;
	border-radius: 10px;
	letter-spacing: 0.32px;
}
.btn-sm, .btn-check:focus + .btn-sm, .btn-sm:focus {
	padding: 7px 0.5rem;
}
.btn-icon {
	color: $primary;
}
.btn-radius {
	border-radius: 10px;
}
.text-radius {
	border-radius: 8px;
}
.btn-40, .btn-40:focus {
	padding: 3px 0.75rem;
}
.btn-close, .btn-close:focus {
	padding: 0.25em 0.25em;
	border: none;
	box-shadow: none;
}
.btn-link, .btn-link:focus {
	padding: 0.375rem 0.75rem;
	border: none;
}
.btn-primary, .btn-primary.disabled:hover {
	background-color: $primary;
	border-color: $primary;
}
.btn-primary:hover, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:first-child:hover, :not(.btn-check) + .btn-primary:hover, .btn-primary:focus {
	background-color: $primary-600;
	border-color: $primary-600;
}
.btn-primary.disabled, .btn-primary:disabled {
	background-color: $primary-50;
	border-color: $primary-50;
}
.btn-secondary, .btn-secondary.disabled:hover {
	background-color: $secondary;
	border-color: $secondary;
	color: #fff;
}
.btn-secondary:hover, .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:first-child:hover, :not(.btn-check) + .btn-secondary:hover {
	background-color: $secondary-600;
	border-color: $secondary-600;
	color: #fff;
}
.btn-secondary:focus {
	background-color: $secondary-600;
	border-color: $secondary-600;
	color: #fff;
}
.btn-secondary.disabled, .btn-secondary:disabled {
	background-color: $secondary-50;
	border-color: $secondary-50;
}
.btn-outline-primary, .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active {
	background-color: transparent;
	border: solid 1px $primary;
	color: $primary;
}
.btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:first-child:hover, :not(.btn-check) + .btn-outline-primary:hover {
	background-color: transparent;
	border-color: $primary-600 !important;
	color: $primary-600;
}
.btn-outline-primary:focus {
	border: solid 1px $primary-600 !important;
	color: $primary-600;
}
.btn-outline-secondary, .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active {
	background-color: transparent;
	border: solid 1px $secondary;
	color: $secondary;
}
.btn-outline-secondary:hover, .btn-outline-secondary:first-child:hover, :not(.btn-check) + .btn-outline-secondary:hover, .btn-outline-secondary:focus {
	background-color: transparent;
	border-color: $secondary-600;
	color: $secondary-600;
}
.btn-outline-light-gray, .btn-outline-light-gray:not(:disabled):not(.disabled):active, .btn-outline-light-gray:not(:disabled):not(.disabled).active {
	background-color: transparent;
	border: solid 2px $light-gray;
	color: $light-gray;
	padding: 7px 0.75rem;
}
.btn-outline-light-gray:hover, .btn-outline-light-gray:focus, .btn-outline-light-gray:not(:disabled):not(.disabled):active {
	background-color: transparent;
	border: solid 5px $light-gray !important;
	color: $light-gray;
	padding: 4px 0.75rem;
}
.btn-outline-light-gray:focus {
	border: solid 2px $light-gray !important;
	color: $light-gray;
	padding: 7px 0.75rem;
}
.btn-default, .btn-default.disabled:hover {
	background-color: $background;
	border-color: $line-gray;
	color: $primary;
}
.btn-default:hover, .btn-default:not(:disabled):not(.disabled):active, .btn-default:not(:disabled):not(.disabled).active, .btn-default:first-child:hover, :not(.btn-check) + .btn-default:hover, .btn-default:focus {
	background-color: $background;
	border-color: $light-gray;
	color: $primary;
}
section.main {
	margin-top: 72px;
	min-height: 400px;
}
section.download {
	background-color: $primary-700;
	color: #fff;
	padding: 25px 0;
}
section.download .description {
	font-size: 20px;
}
section.download a {
	min-width: 400px;
}
section.footer {
	background-color: $primary-900;
	color: #fff;
	padding: 60px 0 36px;
}
section.footer h5 {
	line-height: 30px;
	margin-bottom: 1rem;
}
section.footer ul {
	list-style: none;
	padding: 0;
	line-height: 30px;
	margin-bottom: 0;
}
section.footer hr {
	margin: 40px 0;
}
section.footer a {
	color: #fff;
	text-decoration: none;
}
section.footer a:hover {
	color: $secondary-200;
}
section.footer .icon-social a {
	margin-right: 40px;
	color: #fff;
}
section.footer .icon-social a:hover {
	color: $secondary-200;
}
section.footer .contact-form {
	align-self: center;
}
section.footer .contact-form .form-msg {
	font-size: 20px;
	margin-bottom: 1rem;
}
.carousel-control-next, .carousel-control-prev {
	width: 40px;
	opacity: 1;
}
.carousel-indicators .active {
	background-color: $secondary-200;
}
.carousel-promotion {
	height: 250px;
}
.carousel-promotion .splide__track {
	border-radius: 20px;
	background: linear-gradient(90deg, #00A9DC 0%, #A23F96 100%);
	height: 100%;
}
.carousel-promotion img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.splide .splide__arrow {
	height: 40px;
	width: 40px;
	background: rgba(255, 255, 255, 0.70);
	box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.10);
	opacity: 1;
}
.slide-nusrsing .splide__arrow, .slide-doctor .splide__arrow {
	background: $background;
}
.splide .splide__arrow:disabled {
	opacity: 0;
}
.splide .splide__arrow svg {
	fill: $primary;
}
.splide .splide__pagination {
	bottom: -30px;
}
.splide .splide__pagination__page {
	opacity: 1;
	background-color: $line-gray;
	margin: 0 6px;
}
.splide .splide__pagination__page.is-active {
	background-color: $primary;
	transform: scale(1)
}
.splide__track--nav > .splide__list > .splide__slide {
	border: 1.5px solid transparent;
}
.splide__track--nav > .splide__list > .splide__slide.is-active {
	border-radius: 4px;
	border: 1.5px solid $primary;
}
.block-home, .block-nursing {
	padding-top: 100px;
	padding-bottom: 100px;
}
.block-nursing-detail, .block-booking-nursing {
	padding-top: 40px;
	padding-bottom: 80px;
}
.block-home > section, .block-nursing > section {
	margin: 100px 0;
}
.block-nursing-detail > section {
	margin: 80px 0;
}
.block-home .medical-services-title {
	font-size: 36px;
	text-align: center;
}
.block-home .medical-services-desc {
	font-size: 20px;
	max-width: 700px;
	margin: 0.75em auto 80px;
	text-align: center;
	line-height: 30px;
}
.block-home .service {
	margin-bottom: 120px;
	text-align: center;
}
.block-home .service h3 {
	margin-top: 1.5rem;
	font-size: 28px;
}
.block-home .view-more {
	margin-bottom: 1rem;
	color: $secondary-200;
	text-decoration: underline;
	font-size: 20px;
}
.block-home .landing {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 67px 0;
}
.block-home .landing .box-image {
	width: 528px;
	margin-right: 30px;
}
.block-home .landing .box-image img {
	border-radius: 20px;
}
.block-home .landing .box-text {
	max-width: 587px;
}
.block-home .landing .box-text > div {
	font-size: 32px;
	line-height: 40px;
	margin-bottom: 12px;
}
.block-home .landing .box-text h1 {
	font-size: 48px;
	line-height: 56px;
	margin: 0;
}
.block-home .landing .box-text p {
	padding: 40px 0;
	margin-bottom: 67px;
	line-height: 24px;
}
.block-home .landing .box-search {
	position: absolute;
	display: flex;
	width: 100%;
	border-radius: 1rem;
	border: 1px solid $line-gray;
	background: #fff;
	box-shadow: 2px 4px 8px 0px rgba(0, 0, 0, 0.25);
	padding: 1rem;
	gap: 1rem;
	bottom: 130px;
}
.block-nursing .box-search {
	margin-bottom: 40px;
}
.block-nursing .box-search input, .search-bar .box-search.nursing input {
	background-color: $background;
	color: #000;
	padding: 17px 1rem;
}
.search-bar .box-search.nursing input {
	line-height: 24px;
}
.input-group-text {
	border-radius: 8px;
}
.block-nursing .box-search .input-group-text, .search-bar .box-search.nursing .input-group-text {
	background-color: $primary;
	border-color: $primary;
	color: #FFF;
	padding: 17px;
}
.search-bar .box-search {
	display: flex;
	gap: 1rem;
}
.search-bar .box-search.nursing {
	padding-top: 8px;
	padding-bottom: 8px;
}
.block-home .landing .box-search .btn-search, .search-bar .box-search .btn-search {
	font-weight: bold;
	min-width: 200px;
	padding: 10.65px 0.75rem;
}
.block-home .landing .box-search .dropdown .btn, .search-bar .box-search .dropdown .btn {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #FFF;
	color: $light-gray;
	border: 1px solid $line-gray;
	border-radius: 10px;
	gap: 8px;
	min-width: 160px;
	padding: 10.65px 0.75rem;
}
.block-home .landing .box-search .dropdown .btn > div:first-child, .search-bar .box-search .dropdown .btn > div:first-child {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	text-align: left;
}
.block-home .landing .box-search .dropdown .btn svg, .search-bar .box-search .dropdown .btn svg {
	color: $primary;
}
.block-home .landing .box-search .react-datepicker__tab-loop, .search-bar .box-search .react-datepicker__tab-loop {
	margin-left: -1rem;
}
.react-datepicker__close-icon::after {
	background-color: $primary;
}
.block-home .headline, .block-nursing .headline, .block-nursing-detail .headline {
	line-height: 36px;
	margin-bottom: 20px;
}
.block-home .see-all {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
}
.block-home .see-all > a {
	color: $secondary;
	font-weight: bold;
}
.block-home .see-all .headline {
	margin: 0;
}
.consult-anytime {
	align-items: center;
}
.consult-anytime {
	margin-bottom: 120px;
}
.consult-anytime p, .h-promotion p {
	font-size: 20px;
	line-height: 30px;
}
.consult-anytime .icon-download {
	margin-bottom: 3rem;
}
.consult-anytime .icon-download .col-6 {
	width: auto;
}
.consult-anytime img {
	width: 200px;
}
.consult-anytime img.img-mobile-app {
	width: auto;
}
.title-content h3 {
	display: flex;
	align-items: center;
	font-size: 28px;
	margin-bottom: 1rem;
	line-height: 36px;
}
.title-content p {
	max-width: 450px;
}
.title-content.health-record {
	display: flex;
	align-items: center;
}
.title-content .health-record img {
	width: 530px;
}
.h-promotion p {
	padding-right: 1.5rem;
}
.h-promotion .action {
	text-align: center;
	margin-top: 1.5rem;
}
.modal {
	--bs-modal-width: 600px;
}
.modal-header {
	margin: 0 1rem;
	padding: 1rem 0 12px;
	border-color: $line-gray;
}
.modal-header .btn-close {
	background: url(../images/ion-close.svg) no-repeat center center;
	opacity: 1;
	background-size: 1.5rem;
}
.modal-header .btn-close:hover {
	background-image: url(../images/ion-close-hover.svg);
}
.modal-header .btn-close:focus {
	padding: calc(1rem * 0.5);
}
.modal-header .modal-title {
	font-size: 16px;
}
.modal .coupon-lists {
	max-width: 414px;
}
.coupon-lists .block-coupon {
	display: flex;
	height: 150px;
	margin-bottom: 1rem;
}
.coupon-lists .block-coupon:last-child {
	margin: 0;
}
.coupon-lists .block-coupon > div {
	position: relative;
}
.coupon-lists .block-coupon > div:first-child {
	border: 1px solid $line-gray;
	border-radius: 10px 0 0 10px;
	padding: 1rem;
	width: 100%;
}
.coupon-lists .block-coupon .btn {
	border-radius: 0 10px 10px 0;
	width: 105px;
	padding: 0;
}
.coupon-lists .block-coupon h6 {
	margin-bottom: 4px;
}
.coupon-lists .block-coupon p {
	margin-bottom: 6px;
}
.coupon-lists .block-coupon .expired {
	position: absolute;
	font-size: 12px;
	color: $light-gray;
	bottom: 1rem;
	line-height: normal;
}
.modal-dialog-scrollable .modal-body {
	padding-bottom: 0;
	margin-bottom: 1rem;
}
.modal-custom {
	max-width: 1030px;
}
.modal-custom .modal-content, .modal-content {
	border: none;
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.25);
	border-radius: 20px;
}
.modal-content {
	border-radius: 10px;
}
.modal-dialog.action {
	max-width: 600px;
}
.modal-dialog.action .modal-content {
	background-color: $surface;
}
.modal-custom .block-banner {
	position: relative;
	max-width: 400px;
	padding: 16px;
	background: linear-gradient(180deg, $primary-400 -58.21%, $primary-900 100%);
	border-radius: 20px 0 0 20px;
	color: #fff;
}
.modal-custom .block-banner .logo-icon {
	position: absolute;
	background-image: url(../images/logo-icon-white.svg);
	background-repeat: no-repeat;
	height: 240px;
	width: 255px;
	left: 0;
	bottom: 0;
	border-radius: 20px;
}
.modal-custom .block-banner .action-login {
	text-align: center;
	padding: 100px 0 165px;
	display: grid;
}
.modal-custom .block-banner .action-login button {
	z-index: 1;
}
.modal-custom .block-form {
	padding: 80px 62px;
	min-height: 600px;
}
.modal-custom .block-form .bg-logo-left {
	position: absolute;
	background: transparent url(../images/bg/bg-logo-left.svg) no-repeat left bottom;
	width: 382px;
	height: 471px;
	left: 0;
	bottom: 0;
	border-radius: 20px;
}
.modal-custom .block-form .bg-logo-right {
	position: absolute;
	background: transparent url(../images/bg/bg-logo-right.svg) no-repeat right top;
	width: 158px;
	height: 253px;
	right: 0;
	top: 0;
	border-radius: 20px;
}
.modal-custom .block-form .otp, .otp {
	display: inline-flex !important;
}
.modal-custom .block-form .otp input, .otp input {
	width: 56px !important;
	height: 60px;
	border: 1px solid $secondary-200;
	border-radius: 10px;
	margin: 0px 7px;
	font-size: 24px;
	color: $primary-900;
}
.modal-custom .block-form .otp div:first-child input, .otp div:first-child input {
	margin-left: 0;
}
.modal-custom .block-form .otp div:last-child input, .otp div:last-child input {
	margin-right: 0;
}
.modal-custom .block-form .otp input:focus-visible, .otp input:focus-visible {
	outline-color: $secondary-200;
}
.modal-custom.sign-in .block-banner {
	border-radius: 0 20px 20px 0;
}
.modal-custom.sign-in .block-banner .btn-close, .modal-custom .block-form .btn-close {
	position: absolute;
	top: 16px;
	right: 16px;
	z-index: 9999;
}
.modal-custom.sign-in .block-banner .btn-close.btn-close-white {
	opacity: 1;
}
.modal-custom.sign-in .block-banner .logo-icon {
	background-image: url(../images/logo-icon-white-2.svg);
	height: auto;
	width: 292px;
	left: auto;
	right: 0;
	top: 0;
	border-radius: 20px;
	z-index: 0;
}
.modal-custom.sign-in .block-banner .action-login {
	padding: 175px 0 165px;
}
.modal-custom.sign-in .block-form, .modal-custom.forgot-password .block-form {
	padding: 33px 33px 50px;
}
.modal-custom.sign-in .block-form .img-logo, .modal-custom .block-form .img-logo {
	height: 40px;
}
.modal-custom.sign-in .input-group .input-group-text, .modal-custom.register .input-group .input-group-text, .modal-custom.forgot-password .input-group .input-group-text, .react-datepicker-wrapper .input-group .input-group-text, .register .input-group .input-group-text {
	background-color: #fff;
	border-color: $line-gray;
	border-radius: 8px;
}
.modal-custom.sign-in .input-group .input-group-text.view-password, .modal-custom.register .input-group .input-group-text.view-password, .modal-custom.forgot-password .input-group .input-group-text.view-password, .register .input-group .input-group-text.view-password {
	padding-left: 0;
	color: $light-gray;
}
.modal-custom.sign-in .input-group .input-group-text.view-password svg, .modal-custom.register .input-group .input-group-text.view-password svg, .modal-custom.forgot-password .input-group .input-group-text.view-password svg, .register .input-group .input-group-text.view-password svg {
	cursor: pointer;
}
.modal-custom.sign-in .input-group input, .modal-custom.register .input-group input, .modal-custom.forgot-password .input-group input, .register .input-group input {
	border-left: none;
	padding-left: 0;
}
.fix-design {
	max-width: 820px;
}
.fix-design .modal-header {
	border-color: $line-gray;
	padding: 26.5px 26.5px 8px 32px;
}
.fix-design .modal-header .modal-title {
	font-size: 28px;
}
.fix-design .modal-header .modal-title > div {
	font-size: 16px;
}
.fix-design .modal-header .btn-close {
	background-size: 14px;
	align-self: baseline;
	position: absolute;
	right: 26.5px;
	padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
}
.fix-design .modal-body {
	padding: 1rem 32px 24px;
}
.text-open-alway {
	position: absolute;
	color: $primary-200;
	top: 8px;
	right: 8px;
	text-align: right;
}
.link-forgot-password {
	font-size: 12px;
	cursor: pointer;
	color: $secondary-200;
}
.link-forgot-password:hover {
	text-decoration: underline;
}
.react-tel-input .form-control {
	border-radius: 4px;
	width: 100% !important;
	height: calc(1.5em + 0.9rem + 2px) !important;
	border: 1px solid #ced4da;
}
.react-tel-input .form-control:focus {
	border-color: $secondary-200;
}
.react-tel-input .form-control {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
.react-tel-input .invalid-number-message {
	top: -11px;
	padding: 0 5px;
}
.form-control:focus, .form-select:focus, .btn:focus, .btn.focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus, .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus, .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
	box-shadow: none;
}
.block-form .react-tel-input, .react-tel-input.custom {
	display: flex;
}
.block-form .react-tel-input .form-control, .react-tel-input.custom .form-control {
	height: 40px !important;
	margin-left: 110px;
	padding: 8px 16px;
	border: 1px solid $line-gray;
	border-radius: 8px;
}
.react-tel-input.custom .form-control {
	height: 48px !important;
	margin-left: 72px;
	padding: 0.5rem 1rem;
	line-height: 30px;
}
.react-tel-input.custom .form-control:focus {
	border-color: $light-gray;
}
.block-form .react-tel-input .flag-dropdown, .react-tel-input.custom .flag-dropdown {
	background-color: transparent;
	border: none;
}
.block-form .react-tel-input .selected-flag, .react-tel-input.custom .selected-flag {
	background-color: $secondary-200;
	border-radius: 8px;
	width: 100px;
	padding: 0 40px;
}
.react-tel-input.custom .selected-flag {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $primary;
	width: 68px;
	padding: 0;
}
.block-form .react-tel-input .flag-dropdown.open .selected-flag {
	background-color: $secondary-200;
	border-radius: 8px;
}
.react-tel-input.custom .flag-dropdown.open .selected-flag {
	background-color: $primary;
	border-radius: 8px;
}
.block-form .react-tel-input .selected-flag .arrow, .react-tel-input.custom .selected-flag .arrow {
	border-top-color: #fff;
}
.block-form .react-tel-input .selected-flag .arrow.up, .react-tel-input.custom .selected-flag .arrow.up {
	border-bottom-color: #fff;
}
.block-form .react-tel-input .invalid-number-message {
	left: 135px;
	border-radius: 4px;
	color: $danger;
	font-size: 12px;
}
.block-form .invalid-feedback {
	font-size: 12px;
	line-height: 15px;
	margin: 5px 0 0 0;
}
.block-form .invalid-feedback.mobile {
	margin: 5px 0 0 110px;
}
.block-form .form-control, .account .form-control, .account .form-select, .setting .form-control, .setting .form-select {
	border-color: $line-gray;
}
.account .cancel-booking .form-select {
	background-color: $surface;
	border: none;
	border-radius: 10px;
}
select:invalid {
	color: $light-gray;
}
.heading-line {
	position: relative;
}
.heading-line:before {
	top: 47%;
	content: "";
	position: absolute;
	left: auto;
	right: 0;
	width: 100%;
	height: 0;
	border: 1px solid $line-gray;
	overflow: hidden;
}
.heading-line .title-section {
	position: relative;
	display: table;
	margin: 0 auto;
	background-color: #fff;
	color: $light-gray;
	letter-spacing: 0.02em;
	padding: 0 10px;
}
.login-with span {
	margin: 0 14px;
	cursor: pointer;
}
.Toastify__toast {
	background: #FFFFFF;
	box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
	border-radius: 10px;
}
.Toastify__toast-container {
	width: 500px;
}
.Toastify__close-button--light {
	background: #9CA8C7;
	border-radius: 50%;
	width: 20px;
	height: 20px;
	opacity: 0.7;
	color: #fff;
}
.Toastify__toast-theme--colored {
	padding: 10px 12px;
}
.Toastify__toast-theme--colored.Toastify__toast--success {
	background-color: $success;
}
.Toastify__toast-theme--colored.Toastify__toast--warning {
	background-color: $warning;
}
.Toastify__toast-theme--colored.Toastify__toast--error {
	background-color: $danger;
}
.Toastify__toast-icon {
	width: 39px;
	margin-right: 20px;
}
.Toastify__close-button > svg {
	width: 13px;
	display: block;
	margin: 0 auto;
}
.Toastify__toast-theme--colored .Toastify__toast-body {
	padding: 0;
}
.Toastify__toast-body .alert-block {
	max-width: 415px;
}
.Toastify__toast-body .alert-time {
	position: absolute;
	right: 8px;
	bottom: 8px;
	font-size: 11px;
	color: $light-gray;
	line-height: 15px;
}
.Toastify__toast-body .alert-icon {
	display: flex;
	align-items: center;
	width: 60px;
	height: 60px;
	background: $surface;
	border-radius: 50%;
}
.Toastify__toast-body .alert-icon > svg {
	margin: 0 auto;
}
.Toastify__toast-body .alert-title {
	font-weight: bold;
	line-height: 24px;
}
.Toastify__toast-body .alert-message {
	font-size: 12px;
	color: #000;
	word-break: break-all;
	white-space: pre-line;
	line-height: 18px;
}
.Toastify__toast-theme--colored .Toastify__toast-body .alert-message {
	color: #fff;
}
.Toastify__toast-body a {
	text-decoration: none;
}
.form-check-label {
	position: relative;
	cursor: pointer;
	margin: 0;
	user-select: none;
}
.form-check-input {
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}
.checkmark-checkbox, .checkmark-radio {
	position: absolute;
	top: 0;
	left: 0;
	height: 24px;
	width: 24px;
	border-radius: 6px;
	border: 2px solid $primary;
}
.checkmark-radio {
	border-radius: 50%;
}
.form-check-label .checkmark-checkbox::after, .form-check-label .checkmark-radio.check::after {
	content: "";
	position: absolute;
	display: none;
	border: solid #fff;
	transform: rotate(45deg);
	border-color: $primary;
	left: 7px;
	top: 2px;
	width: 7px;
	height: 14px;
	border-width: 0 3px 3px 0;
	border-radius: 0;
}
.form-check-label .checkmark-radio:after {
	content: "";
	position: absolute;
	display: none;
	top: 4px;
	left: 4px;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background: $secondary-200;
}
.navbar-button .form-check-label .checkmark-radio:after, .form-check-label .checkmark-radio.check:after {
	background: none;
}
.form-check-label input:checked ~ .checkmark-radio.check {
	background-color: $primary;
}
.form-check-label.tertiary .checkmark-checkbox, .form-check-label.tertiary .checkmark-radio {
	border-color: $secondary-200;
}
.form-check-label.tertiary input:checked ~ .checkmark-radio.check {
	background-color: $secondary-200;
}
.form-check-label .checkmark-radio.check::after {
	border-color: #fff;
	left: 7px;
	top: 2px;
	width: 6px;
	height: 13px;
	border-width: 0 2px 2px 0;
}
.form-check-label input:checked ~ .checkmark-checkbox:after, .form-check-label input:checked ~ .checkmark-radio:after {
	display: block;
}
.form-check-label span.checkbox-text {
	padding: 0 0 0 30px;
}
.form-check-label span.radio-text {
	padding: 0 30px;
}
.account-image {
	position: relative;
	display: inline-block;
	text-align: center;
	border-radius: 50%;
	background-color: $primary-50;
	width: 80px;
	height: 80px;
	padding: 22px;
	margin-top: 1rem;
}
.account-image .icon-plus {
	position: absolute;
	right: 2px;
	bottom: 2px;
}
.account-image input, .block-img-profile input, .add-document input {
	position: absolute;
	outline: none;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	opacity: 0;
	cursor: pointer;
}
.account-image img {
	border-radius: 50%;
	height: 80px;
	object-fit: cover;
}
.new-account {
	padding: 1.5rem 0;
	max-width: 620px;
	margin: 0 auto;
}
.new-account .block-img-profile {
	width: 100px;
	height: 100px;
}
.required > label::after {
	content: ' *';
	color: red;
}
.form-label {
	margin-bottom: 0.25rem;
}
.react-datepicker {
	font-family: 'Noto Sans Thai';
	font-size: 12px;
	background-color: $background;
	border: none;
	box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
	border-radius: 10px;
	padding: 20px 0 15px;
}
.react-datepicker__header, .react-datepicker__day-name {
	background-color: $background;
	border: none;
	font-weight: bold;
	padding-bottom: 0;
}
.react-datepicker__header {
	padding: 0;
}
.react-datepicker__day-name {
	font-size: 16px;
	height: 40px;
	width: 40px;
	padding: 8px;
	margin: 0;
}
.react-datepicker__header .react-datepicker__day-names {
	margin: 0;
}
.react-datepicker__header .btn-link {
	position: absolute;
	color: $primary;
	padding: 0;
}
.react-datepicker__header .btn-link:hover {
	color: $primary-600;
}
.react-datepicker__header .btn-link.back {
	left: 6px;
}
.react-datepicker__header .btn-link.forward {
	right: 10px;
}
.react-datepicker__header span.label {
	font-size: 20px;
}
.react-datepicker__header .dropdown {
	font-size: 14px;
	color: $secondary-200;
	border: none;
	padding: 2px 5px;
}
.react-datepicker__header .dropdown:focus-visible {
	outline: none;
}
.react-datepicker__month {
	margin-bottom: 0;
}
.react-datepicker__input-container .input-group input {
	border: none;
	padding: 8px 0.75rem !important;
	flex: 1;
	background-color: transparent;
}
.react-datepicker__input-container .input-group input:focus-visible {
	outline: none;
}
.react-datepicker__input-container .input-group .input-group-text {
	border: none;
	color: $primary;
}
.react-datepicker__day {
	width: 40px;
	height: 40px;
	margin: 0;
	padding: 8px;
}
.react-datepicker__day:hover {
	border-radius: 50%;
	background-color: $primary-50;
}
.react-datepicker__day--disabled, .react-datepicker__day--disabled.react-datepicker__day--outside-month {
	color: $light-gray;
}
.react-datepicker__day--disabled:hover {
	background-color: transparent;
}
.react-datepicker__current-month {
	color: $secondary-200;
}
.react-datepicker__day--outside-month {
	color: $dark-gray;
}
.react-datepicker__navigation-icon::before {
	border-color: $secondary-200;
}
.react-datepicker__day--selected, .react-datepicker__day--selected:hover, .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--ke {
	border-radius: 50%;
	background-color: $primary;
	color: #fff;
}
.react-datepicker__day--keyboard-selected:hover {
	color: $primary-900;
}
.react-datepicker__navigation--previous {
	top: 16px;
	left: 10px;
}
.react-datepicker__navigation--next {
	top: 16px;
	right: 10px;
}
.react-datepicker__triangle {
	display: none;
}
.img-service {
	height: 215px;
	position: relative;
}
.img-service img {
	position: absolute;
	margin: auto;
	left: 0;
	right: 0;
	bottom: 0;
}
.free-program {
	align-items: center;
	margin-bottom: 1rem;
}
.free-program h1 {
	font-size: 36px;
	margin-bottom: 0;
}
.accordion-button, .accordion-body {
	padding: 1rem;
}
.accordion-button:focus {
	box-shadow: none;
}
.accordion-button::after {
	background-image: url(../images/arrow-down.svg);
	background-size: 17px;
	width: 18px;
	height: 11px;
}
.accordion-button:not(.collapsed)::after {
	background-image: url(../images/arrow-down.svg);
}
.card {
	border-radius: 10px;
	border: 1px solid $line-gray;
}
.doctor-list {
	margin-bottom: 120px;
}
.doctor-list .card, .consult-program .card {
	border-radius: 20px;
	padding: 1rem;
	border: solid 1px $line-gray;
	margin-bottom: 1.5rem;
}
.slide-doctor .card, .slide-doctor.doctor-list .card {
	margin-bottom: 0;
}
.doctor-list .card.show, .card-doctor.show {
	padding: 1rem 1rem 0.646rem;
}
.doctor-list a.card:hover, .doctor-list a.card:focus, .consult-program a.card:hover, .consult-program a.card:focus {
	color: $primary-900;
}
.doctor-list .card .card-detail {
	padding: 0 0 8px;
	text-align: center;
}
.doctor-list .card .card-detail .fev-star {
	position: absolute;
	display: flex;
	right: 16px;
	top: 12px;
	align-items: center;
}
.doctor-list .card .card-detail .fev-star div:first-child {
	display: inline-flex;
	align-items: center;
	color: $warning;
	margin-right: 0.5rem;
}
.doctor-list .card .card-detail h3 {
	display: inline-flex;
	align-items: center;
	font-size: 20px;
	margin-bottom: 0.25rem;
	color: #000;
}
.doctor-list .card .card-detail .specialty {
	display: flex;
	justify-content: center;
	gap: 0.25rem;
}
.doctor-list .card .card-detail .specialty .badge {
	font-weight: normal;
	color: #000;
	font-size: 14px;
	line-height: 20px;
	padding: 2px 4px;
}
.doctor-list .card .card-detail .text-secondary-200 svg {
	margin-right: 0.5rem;
}
.doctor-list .card .card-detail h3 img {
	width: 24px;
}
.doctor-list .card .card-action, .card-doctor .card-action {
	border-top: solid 1px $line-gray;
	padding: 1rem 0 0;
}
.doctor-list .card.show .card-action, .card-doctor.show .card-action {
	padding: 0.605rem 0 0;
}
.doctor-list .card .card-action .consult-btn, .doctor-list .card.show .card-action .consult-text, .slide-doctor .card .card-action .consult-btn {
	display: none;
}
.doctor-list .card-action .consult-text .col {
	display: inline-flex;
	justify-content: center;
	align-items: center;
}
.doctor-list .card-action .consult-text .col svg {
	width: 21px;
	margin-right: 0.5rem;
}
.doctor-list .card.show .card-action .consult-btn {
	display: flex;
}
.doctor-list.max-width-820 {
	margin: 0 auto;
}
.consult-program .card {
	padding: 0;
}
.consult-program .card .card-body {
	padding: 6px 1rem 12px;
}
.consult-program .img-wrap {
	max-height: 200px;
	background-color: $line-gray;
	border-radius: 20px 20px 0 0;
}
.consult-program .img-wrap img {
	width: 100%;
	height: 200px;
	object-fit: cover;
	border-radius: 20px 20px 0 0;
}
.consult-program.pagination {
	margin-bottom: 120px;
}
.consult-program.pagination ul {
	list-style: none;
	display: inline-flex;
	margin: 0;
	padding-left: 0;
}
.consult-program.pagination ul li {
	padding: 0 10px;
}
.consult-program.pagination ul li:hover, .consult-program.pagination svg:hover {
	color: $secondary-200;
	cursor: pointer;
}
.consult-program.pagination ul li.active {
	color: $secondary-200;
	cursor: default;
}
.consult-program.pagination svg.disabled {
	color: $light-gray;
	cursor: default;
}
.text-ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
.text-ellipsis.line-clamp-1 {
	-webkit-line-clamp: 1;
}
.text-ellipsis.line-clamp-3 {
	-webkit-line-clamp: 3;
}
.doctor-profile {
	padding-top: 40px;
	padding-bottom: 150px;
}
.doctor-profile .card {
	border-radius: 20px;
	border: 1px solid $line-gray;
	padding: 20px;
}
.doctor-profile img.avatar, .consult img.avatar, .payment img.avatar, .history img.avatar {
	width: 262px;
	height: 262px;
	border-radius: 50%;
	object-fit: cover;
	margin-right: 10px;
}
.consult img.avatar.avatar-60, .payment img.avatar.avatar-60, .history img.avatar.avatar-60 {
	width: 60px;
	height: 60px;
	margin: 0;
}
.history img.avatar.avatar-52 {
	width: 52px;
	height: 52px;
}
.consult img.avatar.avatar-100 {
	width: 100px;
	height: 100px;
	margin: 0;
}
.consult h3.name {
	color: #000;
	text-align: center;
}
.consult .doc-detail {
	margin-bottom: 1.5rem;
}
.consult .doc-showtime {
	margin-bottom: 20px;
}
.consult .doc-datetime-select {
	margin-bottom: 3rem;
	padding-top: 1.5rem;
}
.g-special {
	font-size: 14px;
	background-color: $surface;
	color: $primary-900;
	margin-bottom: 0.25rem;
	padding: 2px 4px;
}
.nav-tabs {
	border: none;
}
.doctor-profile .nav-tabs {
	border: solid 1px $line-gray;
}
.doctor-profile .fev-star {
	display: flex;
	justify-content: flex-end;
}
.doctor-profile .fev-star .score {
	font-size: 24px;
	margin-right: 0.5rem;
}
.doctor-profile .fev-star > div:first-child div span {
	font-size: 26px !important;
}
.doctor-profile .fev-star > div:last-child svg {
	width: 30px;
	height: 30px;
}
.doctor-profile .dr-name {
	margin-bottom: 1rem;
	align-items: center;
}
.doctor-profile h1 {
	font-size: 36px;
	color: #000;
	margin: 0;
}
.doctor-profile .b-header {
	font-size: 14px;
}
.doctor-profile .icon-insure {
	width: 34px;
	margin-left: 0.5rem;
}
.doctor-profile .dr-price {
	display: flex;
	align-items: flex-end;
}
.doctor-profile .dr-price div {
	display: flex;
	align-items: center;
}
.doctor-profile .bage-price {
	background-color: $primary-50 !important;
	color: $primary-700;
	border-radius: 10px;
	padding: 4px 8px;
	font-size: 20px;
}
.doctor-profile .action button, .payment .action .btn, .history .action button {
	display: inline-flex;
	justify-content: center;
	min-width: 280px;
	font-size: 20px;
	padding: 10px;
}
.doctor-profile .action button svg {
	width: 28px;
	height: 28px;
	margin-right: 0.5rem;
}
.history .action button {
	font-size: 16px;
	min-width: 200px;
}
.doctor-profile .nav-tabs, .account .nav-tabs, .setting .nav-tabs {
	background-color: #fff;
	border-radius: 10px;
}
.account .nav-tabs {
	border: 1px solid $line-gray;
}
.account .nav-tabs > li {
	border-bottom: 1px solid $line-gray;
}
.account .nav-tabs > li:last-child {
	border: none;
}
.doctor-profile .nav-tabs .nav-link, .account .nav-tabs .nav-link, .setting .nav-tabs .nav-link {
	padding: 8px 8px 8px 20px;
	margin: 0 0 8px;
	color: #000;
	border: none;
}
.doctor-profile .nav-tabs .nav-item:last-child .nav-link, .account .nav-tabs .nav-item:last-child .nav-link, .setting .nav-tabs .nav-item:last-child .nav-link {
	margin-bottom: 0;
}
.doctor-profile .nav-tabs .nav-link:focus, .doctor-profile .nav-tabs .nav-link:hover, .account .nav-tabs .nav-link:focus, .account .nav-tabs .nav-link:hover, .setting .nav-tabs .nav-link:focus, .setting .nav-tabs .nav-link:hover {
	border-radius: 5px;
	border: none;
	background-color: $primary-50;
	cursor: pointer;
}
.doctor-profile .nav-tabs .nav-link.active, .account .nav-tabs .nav-link.active, .setting .nav-tabs .nav-link.active {
	font-weight: bold;
	border-radius: 5px;
	background-color: $primary-600;
	color: #fff !important;
	border: none;
}
.doctor-profile .tab-content .card {
	border-radius: 10px;
}
.list-cert {
	overflow: hidden;
	overflow-x: auto;
	display: inline-flex;
	height: 170px;
}
.list-cert::-webkit-scrollbar {
	height: 6px;
}
.list-cert::-webkit-scrollbar-track {
	background-color: #ececec;
	border-radius: 50px;
}
.list-cert::-webkit-scrollbar-thumb {
	border-radius: 50px;
	background-color: #ccc;
}
.doctor-profile .block-cert {
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid $line-gray;
	border-radius: 10px;
	width: 320px;
	height: 160px;
	min-width: 320px;
	margin: 0 8px 8px 0;
	cursor: pointer;
}
.doctor-profile .block-cert img {
	border-radius: 10px;
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.doctor-profile .doc-desc .hospital {
	align-items: center;
	margin-bottom: 1rem;
}
.doctor-profile .doc-desc .education {
	margin-bottom: 24px;
}
.consult img.avatar {
	width: 120px;
	height: 120px;
	margin: 30px auto 1.5rem;
}
.telemed-schedules {
	border-bottom: solid 1px $line-gray;
	padding: 8px 0;
	margin: 0;
	min-height: 65px;
}
.schedule div:last-child .telemed-schedules {
	border: none;
}
.telemed-schedules .col {
	padding: 0;
}
.telemed-schedules .list-time {
	text-align: right;
}
.list-reviews {
	margin: 0;
	border-bottom: solid 1px $line-gray;
	padding: 16px 0;
}
.list-reviews .avatar {
	display: inline-block;
	background-color: $primary-50;
	border-radius: 50%;
	height: 60px;
	width: 60px;
	padding: 15px;
	text-align: center;
}
.list-reviews .avatar img {
	object-fit: cover;
	width: 100%;
	border-radius: 50%;
}
.block-assessment, .assessments, .event, .drug-payment {
	padding-top: 60px;
	margin-bottom: 80px;
}
.block-assessment .col:first-child {
	display: flex;
	justify-content: flex-end;
}
.block-assessment .assessment, .block-assessment .insurance {
	display: flex;
	align-items: center;
	height: 120px;
	width: 505px;
	border-radius: 10px;
	cursor: pointer;
}
.block-assessment .assessment {
	background: $primary-50 url(../images/doctor-diagnose.png) no-repeat top right;
	padding-left: 30px;
}
.block-assessment .insurance {
	background: $secondary-50 url(../images/adult-children.png) no-repeat top left;
	padding-right: 45px;
	justify-content: flex-end;
}
.block-assessment .assessment div, .block-assessment .insurance div {
	display: inline-block;
	text-align: center;
}
.block-assessment .assessment div h3, .block-assessment .insurance div h3 {
	margin-bottom: 0.25rem;
}
.block-assessment .assessment div p, .block-assessment .insurance div p {
	margin: 0;
}
.assessments .banner {
	background: #FFFFFF url(../images/doctor-diagnose-xl.png) no-repeat top right;
	border-radius: 10px;
	padding: 35px 0 35px 80px;
	margin-bottom: 60px;
}
.assessments .banner div {
	text-align: center;
	width: 650px;
}
.assessments .banner div h3 {
	font-size: 36px;
	margin-bottom: 1rem;
}
.assessments .banner div p {
	font-size: 20px;
}
.commen-problems .badge {
	margin: 0 20px 1rem 0;
	padding: 8px 16px;
	border-radius: 22px;
	background-color: $secondary-50 !important;
	color: $primary-900;
	font-size: 20px;
	cursor: pointer;
}
.list-assessment {
	margin-top: 45px;
}
.list-assessment .assess-item {
	position: relative;
	background-color: $primary-800;
	border-radius: 10px;
	margin-bottom: 1.5rem;
	padding: 20px;
	height: 200px;
	text-align: center;
	cursor: pointer;
}
.list-assessment .assess-item:hover {
	background-color: $primary-700;
}
.list-assessment .assess-item > div {
	position: absolute;
	margin: 0 auto;
	left: 0;
	right: 0;
	bottom: 0;
}
.list-assessment .assess-item > div > p {
	margin-top: 1rem;
	color: #fff;
	font-size: 20px;
}
.list-assessment .assess-item img {
	max-height: 100px;
}
.event .banner {
	background: #FFFFFF url(../images/event.svg) no-repeat top right;
	border-radius: 10px;
	padding: 2.4rem;
	margin-bottom: 60px;
}
.event .banner h3 {
	text-align: center;
	font-size: 36px;
	margin: 0;
}
.event .event-img {
	border-radius: 10px;
	height: 160px;
	width: 295px;
	object-fit: cover;
}
.event .col h3 {
	display: inline-block;
	cursor: pointer;
}
.event .col h3:hover {
	color: $secondary-200;
}
.commen-problems .badge:hover {
	background-color: $secondary-100 !important;
}
.medical-dpt {
	display: inline-flex;
	align-items: center;
	width: 190px;
	height: 66px;
	margin: 0 20px 16px 0;
	border-radius: 10px;
	cursor: pointer;
	padding-left: 8px;
}
.medical-dpt:hover {
	background-color: $primary-50;
}
.medical-dpt.active {
	background-color: $primary-800;
	color: #fff;
}
.medical-dpt .meical-img {
	width: 50px;
	height: 50px;
	padding: 0;
}
.medical-dpt .meical-img img {
	width: 100%;
	border-radius: 50%;
	object-fit: cover;
}
.filter-doctor {
	margin-bottom: 1rem;
	text-align: right;
}
.dropdown button {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #FFF !important;
	color: #000;
	border-radius: 8px;
	border: 1px solid $line-gray;
	padding: 11px 16px;
	width: 100%;
}
.dropdown button:hover {
	border-color: $light-gray;
}
.dropdown button:focus {
	padding: 11px 16px;
	border-color: $light-gray;
}
.dropdown-toggle::after {
	position: absolute;
	background-image: url(../images/arrow-down-sm-gold.svg);
	background-size: 13px;
	width: 13px;
	height: 8px;
	border: none;
	right: 16px;
	top: 19px;
	transition: transform 0.2s ease-in-out;
}
.dropdown.show .dropdown-toggle::after {
	transform: rotate(-180deg);
}
.filter-doctor .dropdown, .record .dropdown {
	display: inline-flex;
}
.record .dropdown-toggle {
	font-size: 12px;
	border: none;
	background-color: $surface;
}
.record .card {
	margin-bottom: 80px;
}
.filter-doctor .dropdown-toggle.select::after {
	background-image: url(../images/arrow-down-sm-white.svg);
}
.filter-doctor .dropdown-toggle, .filter-doctor .dropdown-toggle:active {
	background-color: #fff;
	color: $primary-900;
	border: solid 1px $primary-200;
	border-radius: 24px;
	font-weight: bold;
	padding: 10px 16px;
	min-width: 150px;
	text-align: left;
	margin-left: 16px;
}
.filter-doctor .tag-event {
	position: relative;
	display: inline-flex;
	align-items: center;
	font-weight: bold;
	border-radius: 24px;
	padding: 15px 50px 15px 16px;
	margin-left: 1rem;
	min-width: 100px;
	font-size: 1rem;
}
.filter-doctor .tag-event .cancel {
	position: absolute;
	right: 10px;
	cursor: pointer;
}
.filter-doctor .tag-event .cancel:hover {
	opacity: 0.8;
}
.filter-doctor .dropdown-toggle:focus-visible {
	outline: none;
}
.filter-doctor .dropdown-toggle span {
	padding-right: 40px;
}
.record .dropdown-toggle span {
	padding-right: 25px;
}
.filter-doctor .dropdown-toggle span.select {
	border-radius: 20px;
	background: $secondary-200;
	color: #fff;
	padding: 0px 26px 0 8px;
	margin-right: -8px;
}
.filter-doctor .dropdown-item, .record .dropdown-item {
	padding: 10px 1rem;
}
.filter-doctor .dropdown-item:hover, .filter-doctor .dropdown-item:active {
	background-color: $primary-50;
	border-radius: 10px;
	color: $primary-900;
}
.filter-doctor .dropdown-item:focus-visible {
	outline: none;
}
.filter-doctor .dropdown-menu.show {
	border: solid 1px $primary-200;
	border-radius: 15px;
	padding: 10px 0;
	top: 5px !important;
	min-width: 200px;
}
.filter-doctor .dropdown-menu .form-label {
	margin-bottom: 0;
}
.health-record .filter-doctor .dropdown-toggle, .health-record .filter-doctor .dropdown-toggle:active {
	border-radius: 10px;
	border: none;
	padding: 0.5rem 1rem;
	color: $light-gray;
	background-color: #FFFFFF;
}
.health-record .filter-doctor .dropdown-toggle::after {
	background-image: url(../images/arrow-down-blue.svg);
	top: 16px;
}
.health-record .record {
	max-width: 600px;
	margin: 3rem auto 120px;
}
.health-record .record .dropdown-toggle::after {
	background-image: url(../images/arrow-down-blue.svg);
	top: 11px;
}
.health-record .filter-doctor .dropdown.show .dropdown-toggle {
	border-radius: 10px 10px 0 0;
}
.health-record .filter-doctor .dropdown-menu.show {
	border-radius: 0 0 10px 10px;
	border: none;
	top: 0 !important;
}
.health-record .record .dropdown-menu.show {
	font-size: 12px;
	min-width: 100px;
	top: 0.5rem !important;
}
.health-record .record .dropdown-menu button {
	padding: 4px 1rem;
}
.health-record .filter-doctor .dropdown-item {
	color: $primary-900;
}
.health-record .filter-doctor .dropdown-item:hover, .health-record .filter-doctor .dropdown-item:active {
	border-radius: 0;
}
.health-record .filter-doctor .dropdown-toggle span.select {
	position: absolute;
	background-color: $primary-900;
	right: 8px;
	margin-right: 0;
	padding: 0 32px 0 1rem;
}
.health-record .filter-doctor .dropdown-toggle.select::after {
	background-image: url(../images/arrow-down.svg);
}
.health-record .add-data {
	cursor: pointer;
	color: $secondary-200;
	font-weight: bold;
}
.health-record .tab-pane.health, .history .tab-pane.consultation, .history .tab-pane.drug, .history .tab-pane.nursing-home {
	margin-bottom: 120px;
	padding-top: 1.5rem;
}
.health-record .tab-pane.document, .history .tab-pane.consultation, .history .tab-pane.drug {
	padding: 1.5rem;
}
.health-record .tab-pane.document .row {
	align-items: center;
	margin: 0;
	padding: 1rem 0;
	border-bottom: 1px solid $line-gray;
}
.health-record .tab-pane.document .empty, .health-record .tab-pane.health .empty {
	font-size: 20px;
	margin-top: 120px;
	text-align: center;
	color: $light-gray;
}
ol.wizard {
	position: relative;
	display: flex;
	justify-content: center;
	overflow: hidden;
	counter-reset: wizard;
	list-style: none;
	padding: 40px 0;
}
ol.wizard li {
	position: relative;
	float: left;
	width: 25%;
	text-align: center;
	color: $secondary-200;
}
ol.wizard .current ~ li {
	color: $light-gray;
}
ol.wizard li:before {
	counter-increment: wizard;
	content: counter(wizard);
	display: block;
	background-color: $secondary-200;
	color: #fff;
	border: 2px solid $secondary-200;
	text-align: center;
	width: 60px;
	height: 60px;
	line-height: 55px;
	border-radius: 50%;
	position: relative;
	left: 50%;
	margin-bottom: 10px;
	margin-left: calc(60px * -0.5);
	z-index: 1;
	font-size: 36px;
	font-weight: bold;
	cursor: pointer;
}
ol.wizard li.current::before {
	color: $secondary-200;
	background-color: #fff;
	cursor: default;
}
ol.wizard .current ~ li:before {
	color: $light-gray;
	border-color: $light-gray;
	background-color: #fff;
	cursor: default;
}
ol.wizard li + li {
	&:after {
		content: "";
		display: block;
		border: solid 2px $secondary-200;
		width: 100%;
		position: absolute;
		left: -50%;
		top: 30px;
		z-index: 0;
	}
}
ol.wizard li + li.current::after {
	border-style: dashed;
}
ol.wizard .current ~ li:after {
	border-color: $light-gray;
}
.consult .timezone span {
	font-size: 12px;
}
.consult .timezone .dropdown-toggle::after {
	position: initial;
	background-image: url(../images/arrow-down-sm-blue.svg);
	width: 8px;
	height: 5px;
	background-size: 8px;
	vertical-align: 1.5px;
}
.consult .timezone .dropdown-menu {
	padding: 1rem 0;
	font-size: 12px;
	width: auto;
	max-height: 300px;
}
.line-group-time {
	border-bottom: solid 4px $secondary-200;
	padding-bottom: 5px;
	margin-top: 1.5rem;
}
.consult .block-group-time {
	margin: 1rem 0 60px;
}
.consult .block-time {
	border-radius: 20px;
	border: solid 1px $primary-900;
	padding: 6px;
	text-align: center;
	margin-bottom: 0.75rem;
	cursor: pointer;
}
.consult .block-time.active {
	cursor: default;
	background-color: $secondary-200;
	border-color: $secondary-300;
	color: #fff;
}
.consult .check-have, .setting .check-have {
	padding-left: 110px;
}
.account .check-have {
	padding-left: 60px;
}
.block-attachments {
	display: flex;
	flex-wrap: wrap;
}
.block-attachments div, .block-attachments a {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	border: solid 1px $secondary-200;
	width: 100px;
	height: 100px;
	border-radius: 20px;
	margin: 0 10px 10px 0;
	cursor: pointer;
}
.block-attachments div.s-52 {
	width: 52px;
	height: 52px;
	border-radius: 10px;
	margin: 0 5px 5px 0;
	min-width: 52px;
}
.block-attachments div img, .block-attachments a img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 20px;
}
.block-attachments div.s-52 img {
	border-radius: 10px;
}
.block-attachments div.add {
	border: dashed 2px $secondary-200;
	color: $secondary-200;
	font-size: 13px;
	cursor: pointer;
}
.block-attachments div.add p {
	color: $primary-900;
	margin: 0;
}
.block-attachments div.add input {
	position: absolute;
	outline: none;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	opacity: 0;
	cursor: pointer;
}
.block-attachments div .remove {
	position: absolute;
	color: red;
	right: 5px;
	top: 0;
	cursor: pointer;
}
.consult .payment .card-title, .payment h5.card-title, .payment h6.card-title {
	background-color: $primary-50;
	border-radius: 10px 10px 0 0;
	padding: 1rem;
	margin: -1rem -1rem 1rem;
}
.payment.address {
	padding: 0.5rem;
}
.payment h6.card-title {
	padding: 0.5rem;
	margin: -0.5rem -0.5rem 0.5rem;
}
.card-title .badge {
	font-size: 16px;
	position: absolute;
	right: 1rem;
}
.consult .choose-insurance, .drug-payment .choose-insurance {
	border-radius: 10px;
	border: solid 1px $secondary-200;
	width: 100%;
	padding: 23px 16px;
	text-align: center;
	font-size: 20px;
	cursor: pointer;
}
.block-payment {
	border-radius: 10px;
	border: solid 1px $secondary-200;
	height: 68px;
	margin-bottom: 1rem;
}
.block-payment .form-check-label {
	margin: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	padding: 8px;
}
.block-payment .payment-icon {
	width: 70px;
}
.block-payment .payment-icon svg, .block-payment .payment-icon img {
	width: 52px;
	height: 52px;
}
.block-payment .checkmark-radio {
	position: inherit;
}
.block-payment .form-check-label .radio-text {
	padding: 0 0 0 20px;
}
.consult .summary, .drug-payment .summary {
	background-color: $primary-50;
	padding: 1rem;
	margin: 0 -1rem;
}
.invoice-table {
	margin-bottom: 0;
}
.invoice-table td {
	border: none;
	padding: 0.2rem 0;
}
.invoice-table.drug tr {
	border-bottom: solid 1px rgba(172, 172, 172, 0.25);
}
.invoice-table.drug td {
	padding: 1rem 0;
}
.payment.address .invoice-table.drug td {
	padding: 0.5rem 0;
}
.container.setting {
	margin-top: 115px;
}
.setting .tab-content.terms .tab-pane {
	padding: 1.5rem;
}
.setting .tab-pane button, .tab-pane .btn-w, .add-contact .btn-w, .contact-res .btn-w, .btn-w-400, .btn-wm-400 {
	min-width: 400px;
}
.btn-w-200 {
	min-width: 200px;
}
.setting h2 {
	font-size: 36px;
	color: $secondary-200;
}
.setting .pad.r1 {
	margin: 3rem 3rem 0.5rem;
}
.setting .pad.r2 {
	margin: 0.5rem 3rem 3rem;
}
.account .header {
	display: flex;
	flex-direction: column;
	background: url(../images/bg-header.svg) no-repeat center right 106px, linear-gradient(90deg, $primary 0%, $secondary 100%);
	color: #FFF;
	padding: 40px 1rem 28px;
	margin: 115px 0 20px;
	gap: 8px;
	border-radius: 8px;
}
.account .header > div {
	font-weight: bold;
	font-size: 32px;
	line-height: 40px;
}
.account .header > p {
	margin-bottom: 0;
}
.account .add-contact, .account .close-account, .account .cancel-booking {
	display: block;
	margin: 115px 0 40px;
	padding: 40px;
}
.account .close-account h2 {
	font-size: 36px;
	margin-top: 1rem;
	text-align: center;
}
.account .close-account p {
	font-size: 20px;
	text-align: center;
	margin: 1rem 0;
}
.account .close-account p.detail {
	font-size: 16px;
}
.account .close-account select {
	margin: 0 auto;
	max-width: 600px;
	border-radius: 10px;
	border: none;
	padding: 8px 20px;
	background: url(../images//arrow-down-blue.svg) no-repeat calc(100% - 1rem) $background;
}
.account .nav-tabs .nav-link, .setting .nav-tabs .nav-link {
	padding: 1rem 1rem 1rem 10px;
	margin: 0;
	color: #000;
}
.account .nav-tabs .nav-item .nav-link:focus, .account .nav-tabs .nav-item .nav-link:hover, .account .nav-tabs .nav-item .nav-link.active,
.setting .nav-tabs .nav-item .nav-link:focus, .setting .nav-tabs .nav-item .nav-link:hover, .setting .nav-tabs .nav-item .nav-link.active {
	border-radius: 0;
}
.account .nav-tabs .nav-item:first-child .nav-link:focus, .account .nav-tabs .nav-item:first-child .nav-link:hover, .account .nav-tabs .nav-item:first-child .nav-link.active,
.setting .nav-tabs .nav-item:first-child .nav-link:focus, .setting .nav-tabs .nav-item:first-child .nav-link:hover, .setting .nav-tabs .nav-item:first-child .nav-link.active {
	border-radius: 5px 5px 0 0;
}
.account .nav-tabs .nav-item:last-child .nav-link:focus, .account .nav-tabs .nav-item:last-child .nav-link:hover, .account .nav-tabs .nav-item:last-child .nav-link.active,
.setting .nav-tabs .nav-item:last-child .nav-link:focus, .setting .nav-tabs .nav-item:last-child .nav-link:hover, .setting .nav-tabs .nav-item:last-child .nav-link.active {
	border-radius: 0 0 5px 5px;
}
.account .nav-tabs .nav-link .menu-icon, .setting .nav-tabs .nav-link .menu-icon {
	width: 35px;
	align-self: center;
	color: $primary;
}
.account .nav-tabs .nav-link.active .menu-icon {
	color: #FFF;
}
.account .nav-tabs .nav-item .nav-link.round:hover, .setting .nav-tabs .nav-item .nav-link.round:hover {
	border-radius: 10px;
}
.account .tab-pane h1.title, .setting .tab-pane h1.title {
	position: relative;
	display: flex;
	align-items: center;
	font-size: 32px;
	border-bottom: solid 1px $line-gray;
	padding: 0.75rem 0;
	margin-bottom: 1rem;
}
.account .tab-pane.nursing-home h1.title {
	font-size: 24px;
	margin-bottom: 20px;
}
.account .tab-pane.nursing-home h1.title.icon-left > span {
	margin-right: 8px;
}
.account .tab-pane h1.title > span, .manual h1.title > span {
	position: absolute;
	right: 0;
	cursor: pointer;
}
.account .tab-pane h1.title.icon-left > span, .manual h1.title.icon-left > span {
	position: relative;
	margin-right: 35px;
}
.account .tab-pane h1.title.icon-left span > svg, .manual h1.title.icon-left span > svg {
	margin-top: -5px;
}
.account .tab-pane.nursing-home h1.title.icon-left span > svg {
	margin: 0;
}
.account h1.title .menu.dropdown {
	position: absolute !important;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	background-color: #FFF;
	border-radius: 50%;
	width: 44px;
	height: 44px;
	margin: 0 !important;
}
.account .connection {
	margin-bottom: 120px;
}
.account .connection .social {
	align-items: center;
	margin: 0 1.5rem;
	border-bottom: 1px solid $line-gray;
}
.account .connection .social > div {
	padding: 1rem 0;
}
.account .connection .social > div:last-child {
	display: flex;
}
.account-close .dropdown-menu, .menu .dropdown-menu, .health-record .record .dropdown-menu {
	font-size: 16px;
	border-radius: 10px;
	border: none;
	padding: 0;
	box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
}
.account-close .dropdown-menu button, .menu .dropdown-menu button {
	padding: 1rem;
}
.account-close .dropdown-menu button:hover {
	border-radius: 10px;
}
.menu .dropdown-menu {
	right: -13px !important;
	top: 10px !important;
}
.nhb-detail .menu .dropdown-menu {
	background: $background;
	right: 0 !important;
}
.nhb-detail .menu .dropdown-menu button {
	background: $background !important;
	border: none;
}
.nhb-detail .menu .dropdown-menu button:first-child {
	border-bottom: 1px solid $line-gray;
}
.menu .dropdown-menu button:first-child:hover {
	border-radius: 10px 10px 0 0;
}
.menu .dropdown-menu button:last-child:hover {
	border-radius: 0 0 10px 10px;
}
.block-img-profile {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 150px;
	height: 150px;
	margin: 30px auto 60px;
}
.block-img-profile .icon-plus {
	position: absolute;
	right: 5px;
	bottom: 5px;
}
.block-img-profile.caregiver {
	margin: 0;
	padding: 0;
	width: 48px;
	height: 48px;
}
.block-img-profile.chat {
	margin: 0;
	padding: 0;
	width: 36px;
	height: 36px;
}
.payment.nursing {
	margin-bottom: 80px;
}
.payment .block-status {
	margin: 115px 0 1.5rem;
	padding: 40px;
	text-align: center;
}
.payment.nursing .block-status {
	border: solid 1px $line-gray;
}
.payment .block-status svg {
	margin: 0 auto;
	margin-bottom: 25px;
	width: 120px;
	height: 120px;
}
.payment.container {
	max-width: 1030px;
}
body.coupon-show {
	overflow: hidden;
}
.overlay-coupon {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: rgba(0, 0, 0, 0.25);
	z-index: 9999;
	display: none;
}
.overlay-coupon.open {
	display: block;
	overflow: hidden;
}
.account .history .nav-tabs {
	border-radius: 8px;
	background: $background;
	border: none;
	padding: 8px 10px;
	margin-bottom: 20px;
}
.history .nav .nav-item {
	border: none;
}
.history .nav .nav-item .nav-link {
	color: #000;
	padding: 8px;
}
.history .nav .nav-item .nav-link:hover {
	background-color: transparent;
	color: $primary;
}
.history .nav .nav-item .nav-link.active {
	background-color: #FFF;
	color: $primary !important;
	border-radius: 8px !important;
}
.history .card.list {
	border: 1px solid $line-gray;
	border-radius: 10px;
	margin-bottom: 1rem;
}
.history .card.list:hover {
	border-color: $secondary-200;
}
.history .card.list table {
	margin: 0;
}
.history .card.list table td {
	border: none;
	padding: 0;
}
.history .card.list table td button {
	min-width: 200px;
	z-index: 9;
}
.history .card.list .consult-no {
	padding-bottom: 0.5rem;
}
.history .card.list .consult-no > span {
	color: $secondary-200;
	margin-left: 0.25rem;
}
.history .card.list .consult-date, .history .card.list .consult-status-text, .history .card.list .consult-view {
	color: $light-gray;
	padding-bottom: 0.5rem;
}
.history .card.list .consult-date {
	width: 145px;
}
.history .card.list .consult-status-text {
	width: 170px;
}
.history .card.list .consult-view {
	font-size: 12px;
	width: 200px;
	text-align: end;
}
.history .card.list.nhc .consult-view {
	width: 180px;
}
.history .card.list h4.name {
	align-self: center;
	margin: 0;
}
.history .card.list .consult-status {
	font-size: 16px;
	padding: 0.5rem 1rem;
	white-space: break-spaces;
}
.nursing-status {
	font-weight: normal;
	font-size: 12px;
	padding: 4px 8px;
	border-radius: 20px;
	line-height: 16px;
	min-width: 100px;
}
.history .nursing-home .card.list {
	display: flex;
	padding: 12px;
	border-radius: 1rem;
	cursor: pointer;
}
.history .nursing-home .card.list.nhc {
	line-height: normal;
	margin-bottom: 80px;
}
.history .nursing-home .card.list:hover {
	border-color: $line-gray;
	background: $background;
}
.history .nursing-home .card.list.nhc:hover table td {
	background: transparent;
}
.consultation .consult-title, .drug .consult-title {
	padding-right: 0;
}
.consultation .consult-title > div, .drug .consult-title > div {
	font-size: 20px;
	margin-top: 1.5rem;
	color: #000;
}
.consultation .consult-title > p, .drug .consult-title > p {
	font-size: 12px;
	color: $light-gray;
}
.consultation .consult-title-action {
	align-self: center;
	text-align: end;
}
.consultation .consult-title-action .btn-send-doc {
	align-items: center;
	margin-left: 0.75rem;
}
.line-status-consult {
	display: flex;
	justify-content: center;
	color: $secondary;
	padding: 1.5rem 0;
}
.timeline-status .line-status-consult {
	justify-content: space-between;
	padding: 0;
}
.line-status-consult .line {
	border-bottom: 4px solid $secondary;
	width: 120px;
	height: 0;
	margin: 0px 20px;
	align-self: center;
}
.timeline-status .line-status-consult .line {
	border-bottom: 4px dashed $secondary;
	width: 100%;
	margin: 0 1rem;
}
.timeline-status .line-status-consult .icon {
	color: $light-gray;
}
.timeline-status .line-status-consult .icon.active {
	color: $secondary;
	border: none;
}
.timeline-status .line-status-consult div.active ~ div.line {
	border-bottom: 4px dashed $secondary;
}
.timeline-status .line-status-consult.signout div.active ~ div.line {
	border-bottom: 4px solid $secondary;
}
.line-status-consult div.active {
	border-bottom-style: dashed;
}
.line-status-consult div.active ~ div {
	color: $light-gray;
}
.line-status-consult div.active ~ div.line {
	border-bottom: 4px dashed $light-gray;
}
.line-status-consult.submit {
	color: $light-gray;
}
.line-status-consult.submit .line, .line-status-consult.waiting_verification .line {
	border-bottom: 4px dashed $light-gray;
}
.line-status-consult.cancel, .timeline-status .line-status-consult.cancel .icon {
	color: $danger;
}
.line-status-consult.cancel .line {
	border-bottom: 4px dashed $danger;
}
.line-status-consult .icon svg, .line-status-consult .icon img {
	width: 40px;
	height: 40px;
}
.timeline-status .line-status-consult .icon svg {
	width: 28px;
	height: 28px;
}
.follow-case {
	background-color: $secondary-100;
	margin: 1rem -1rem -1rem;
	padding: 1rem;
	border-radius: 0 0 10px 10px;
	text-align: center;
}
.card-body.follow-up {
	background-color: $secondary-100;
	padding: 1rem 1.5rem;
}
.follow-up .col-12 > svg:last-child {
	margin-left: 1rem;
}
.alert-appointment {
	background-color: $secondary-100;
	padding: 1rem 0;
}
.alert-appointment .booking-ref {
	margin-right: 3rem;
}
.alert-appointment .booking-ref .badge {
	font-size: 16px;
	padding: 0.5rem 1rem;
}
.alert-appointment .booking-detail {
	padding: 1rem 0;
}
.alert-appointment .booking-detail .row:first-child {
	margin-bottom: 1rem;
}
.cookie-permission, .coupon-check {
	position: fixed;
	bottom: 0;
	width: 100%;
	background-color: #fff;
	box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.15);
	padding: 1.5rem;
	z-index: 200;
}
.cookie-permission .close {
	position: absolute;
	display: flex;
	top: 0;
	bottom: 0;
	right: 1rem;
	align-items: center;
	cursor: pointer;
}
.coupon-check {
	background: transparent;
	box-shadow: none;
	padding: 0;
}
.coupon-check .alert {
	padding: 0.5rem;
	text-align: center;
	font-size: 14px;
}
.coupon-check .alert .cancel {
	cursor: pointer;
}
.coupon-check .alert .cancel:hover {
	text-decoration: underline;
}
.help .accordion-button, .help .accordion-item {
	background-color: transparent;
}
.help .accordion-item {
	border: 1px solid #fff;
	border-right: 0;
	border-left: 0;
}
.help .accordion-button, .cookies .accordion-button {
	color: $primary-900;
}
.help .accordion-button:not(.collapsed), .cookies .accordion-button:not(.collapsed) {
	box-shadow: none;
}
.help .accordion-button::after, .cookies .accordion-button::after, .drug-recommendations .accordion-button::after, .list-shipping .accordion-button::after {
	background-image: url(../images/arrow-down-sm-blue.svg);
	background-size: 12px;
	width: 12px;
	height: 8px;
}
.block-list-cookie {
	height: 350px;
	overflow-y: auto;
}
.cookies .accordion-button {
	background-color: $surface;
	padding: 8px 8px 8px 12px;
}
.cookies .accordion-item:first-of-type .accordion-button {
	border-radius: 10px;
}
.cookies .accordion-button::before {
	content: "";
	background-image: url(../images/arrow-down-sm-blue.svg);
	background-repeat: no-repeat;
	background-size: 16px;
	width: 16px;
	height: 10px;
	margin-right: 20px;
	transition: transform 0.2s ease-in-out;
}
.cookies .accordion-button::after {
	display: none;
}
.cookies .accordion-button .row {
	width: 100%;
}
.cookies .accordion-button:not(.collapsed) {
	border-radius: 10px 10px 0 0 !important;
}
.cookies .accordion-button:not(.collapsed)::before {
	transform: rotate(-180deg);
}
.cookies .accordion-body {
	background-color: $surface;
	border-radius: 0 0 10px 10px;
}
.cookies .accordion-body .table {
	background-color: $primary-50;
	border-radius: 10px;
	margin: 0;
}
.cookies .accordion-body .table th {
	font-weight: bold;
	text-align: center;
	padding: 1rem;
	border-color: #FFF;
}
.cookies .accordion-body .table td {
	padding: 1rem;
	border: none;
	word-break: break-all;
}
.drug-recommendations .accordion-button {
	font-size: 12px;
	color: $secondary-200;
	background-color: transparent;
	padding: 8px 0 0;
	width: auto;
	box-shadow: none;
	align-items: baseline;
}
.drug-recommendations .accordion-button::after {
	background-image: url(../images/arrow-down-sm-gold.svg);
	margin-left: 10px;
}
.drug-recommendations .accordion-body {
	font-size: 12px;
	color: $primary-900;
	background-color: transparent;
	padding: 0;
}
.list-shipping .accordion-button {
	color: $primary-900;
	background-color: $surface;
	border-radius: 10px 10px 0 0 !important;
	padding: 8px 1rem;
	box-shadow: none !important;
}
.list-shipping .accordion-button.collapsed {
	border-radius: 10px !important;
}
.list-shipping .accordion-collapse {
	background-color: $surface;
}
.list-shipping .accordion-body {
	padding: 0 1rem 4px;
}
.list-shipping .accordion-body > .row {
	padding: 8px 0;
}
.list-shipping .accordion-item img {
	border-radius: 5px;
	width: 24px;
}
.manual .icon {
	width: 80px;
	height: 80px;
	background: $primary-50;
	border-radius: 10px;
	display: flex;
	justify-content: center;
}
.manual .icon img {
	width: 50px;
}
.manual .manual-img {
	width: 250px;
	height: 150px;
	border: 4px solid $secondary-200;
	border-radius: 20px;
	object-fit: cover;
}
.react-toggle-track, .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
	background-color: $light-gray;
}
.react-toggle-thumb {
	border-color: $light-gray;
}
.react-toggle--focus .react-toggle-thumb, .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
	box-shadow: none;
}
.react-toggle--checked .react-toggle-track, .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
	background-color: $primary-900;
}
.react-toggle--checked .react-toggle-thumb {
	border-color: $primary-900;
}
.cookies .react-toggle-track {
	width: 52px;
	height: 28px;
}
.cookies .react-toggle-thumb {
	width: 24px;
	height: 24px;
	top: 2px;
	left: 2px;
}
.cookies .react-toggle--checked .react-toggle-thumb {
	left: 26px;
}
.notify {
	position: absolute !important;
	right: -7px;
}
.notifications.dropdown-menu {
	padding: 0 !important;
}
.notifications.dropdown-menu > :nth-child(2), .notifications.dropdown-menu .dropdown-item.first-child {
	border-radius: 0;
}
.notifications .dropdown-header {
	position: sticky;
	top: 0;
	padding: 16px;
	margin-bottom: 0;
	background-color: #FFFFFF;
	z-index: 9;
}
.notifications .dropdown-item {
	background-color: #FFFFFF;
	padding: 0 16px;
}
.notifications .dropdown-item .row {
	border-bottom: solid 1px $line-gray;
	padding: 8px 0;
}
.notifications .dropdown-item .menu-icon {
	width: auto;
	padding-right: 1rem !important;
}
.notifications .dropdown-item .menu-icon > div {
	background-color: $surface;
	border-radius: 50%;
	width: 52px;
	height: 52px;
	padding: 12px
}
.notifications .dropdown-item .time {
	position: absolute;
	font-size: 12px;
	margin-top: 0.5rem;
	right: 1rem;
	color: $secondary-200;
}
.notifications .dropdown-item .noti-title {
	font-weight: bold;
	word-break: break-all;
	white-space: normal;
	max-width: 225px;
}
.notifications .dropdown-item .noti-desc {
	font-size: 12px;
	line-height: 15.6px;
	word-break: break-all;
	white-space: normal;
	margin-top: 2px;
}
.search-bar {
	position: fixed;
	overflow: hidden;
	width: 100%;
	background: #FFF;
	box-shadow: 0 4px 8px 0px rgba(0, 0, 0, 0.05);
	transition: max-height 0.3s ease-in;
	max-height: 0;
	z-index: 3;
}
.search-bar.open {
	max-height: 80px;
}
.search-bar.open.ovf-unset {
	overflow: unset;
}
.search-bar .text-search {
	border-radius: 10px;
	text-align: center;
	border: 1px solid $line-gray;
}
.search-bar .btn-search, .canvas-menu .is-search .btn-search {
	min-width: 400px
}
.search-bar .dropdown .btn {
	background-color: $surface;
	color: $primary-900;
	padding: 8px 16px;
	border-radius: 10px;
	border: 1px solid $line-gray;
	width: 100%;
}
.search-bar .dropdown .btn .filter, .canvas-menu .is-search .dropdown .btn .filter {
	font-weight: bold;
	font-size: 16px;
	color: $secondary-200;
}
.search-bar .dropdown .btn .arrow, .canvas-menu .is-search .dropdown .btn .arrow {
	display: inline-block;
	background-image: url(../images/arrow-down-sm-gold.svg);
	background-size: 15px;
	width: 15px;
	height: 10px;
	margin-left: 10px;
	transition: transform 0.2s ease-in-out;
}
.dropdown.show button > svg {
	transition: transform 0.2s ease-in-out;
}
.search-bar .dropdown.show .btn .arrow, .search-bar .dropdown.react-datepicker-ignore-onclickoutside .btn .arrow, .dropdown.show button > svg {
	transform: rotate(-180deg);
}
.navbar-button .dropdown.show button > svg {
	transform: none;
}
.search-bar .dropdown .btn .filter.select, .canvas-menu .is-search .dropdown .btn .filter.select {
	border-radius: 20px;
	background: $secondary-200;
	color: #fff;
	padding: 0 8px 0;
	display: inline-block;
	line-height: 23px;
}
.search-bar .dropdown .btn .select .arrow, .canvas-menu .is-search .dropdown .btn .select .arrow {
	background-image: url(../images/arrow-down-sm-white.svg);
}
.search-bar .dropdown-menu, .canvas-menu .is-search .dropdown-menu, .box-search .dropdown-menu {
	max-height: 430px;
	width: 320px;
	overflow-y: auto;
	border-radius: 8px;
	background-color: $background;
	border: none;
	box-shadow: 0px 0px 16px rgb(0 0 0 / 15%);
	padding: 0;
}
.box-search .dropdown-menu[data-popper-placement="bottom-start"] {
	top: 10px !important;
}
.box-search .dropdown-menu[data-popper-placement="top-start"] {
	bottom: 10px !important;
}
.search-bar .dropdown-menu::-webkit-scrollbar, .box-search .dropdown-menu::-webkit-scrollbar {
	width: 0;
}
.search-bar .dropdown-menu .dropdown-item, .box-search .dropdown-menu .dropdown-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px 12px;
	border-radius: 0;
	border: none;
	border-bottom: 1px solid $line-gray;
}
.box-search .dropdown-menu .dropdown-item:last-child {
	border: none;
}
.box-search .dropdown-menu .dropdown-item .form-check-label {
	width: 24px;
}
.box-search .dropdown-menu .dropdown-item > div {
	max-width: 230px;
	white-space: break-spaces;
}
.search-bar .dropdown .dropdown-toggle::after {
	top: auto;
	bottom: 16px;
}
.list-coupons {
	margin: 40px auto 80px;
	max-width: 600px;
}
.list-coupons .block-coupon, .consultation .block-coupon {
	background: transparent url(../images/bg-coupon.svg) no-repeat left;
	width: 600px;
	margin-top: 20px;
	padding: 1rem 1rem 1rem 67px;
	line-height: 23.92px;
}
.list-coupons .block-coupon.gray, .consultation .block-coupon {
	background: transparent url(../images/bg-coupon-gray.svg) no-repeat left;
}
.list-coupons .block-coupon.slide {
	background: transparent url(../images/bg-coupon-sm.svg) no-repeat left;
	max-width: 360px;
	padding: 1rem 1rem 1rem 50px;
}
.list-coupons .block-coupon.slide:first-child {
	margin-top: 0;
}
.list-coupons .block-coupon .description, .consultation .block-coupon .description {
	font-size: 12px;
	min-height: 32px;
	margin: 8px 0;
	line-height: 15.6px;
}
.list-coupons .block-coupon .expired, .consultation .block-coupon .expired {
	font-size: 12px;
	line-height: 15.6px;
	color: $light-gray;
}
.list-coupons .block-coupon .btn, .consultation .block-coupon .btn {
	min-width: 200px;
}
.list-coupons .block-insure {
	border: 1px solid $secondary-200;
	border-radius: 10px;
	margin-bottom: 20px;
	padding: 20px;
}
.coupon-search {
	margin: 0 auto 60px;
	max-width: 600px;
}
.offcanvas.offcanvas-start, .offcanvas-end {
	border: none;
}
.canvas-coupon .offcanvas-title {
	font-size: 28px;
}
.modal-coupon .list-coupons, .canvas-coupon .list-coupons {
	margin: 0;
}
.canvas-chat {
	border-radius: 20px 0px 0px 20px;
}
.canvas-chat .offcanvas-header {
	display: block;
	background: linear-gradient(209.83deg, $primary-900 7.82%, $primary-400 158.8%);
	border-radius: 20px 0px 0px 0;
	text-align: center;
	padding: 11px 1rem;
}
.canvas-chat .offcanvas-title {
	font-size: 20px;
	color: #fff;
}
.canvas-chat .offcanvas-body {
	padding: 0;
	overflow: hidden;
}
.canvas-chat .list-chat {
	height: calc(100% - 65px);
	padding: 1rem 24px;
	overflow-y: auto;
}
.canvas-chat .list-chat::-webkit-scrollbar, .canvas-address .offcanvas-body::-webkit-scrollbar {
	width: 0px;
}
.canvas-chat .list-table {
	display: table;
	width: 100%;
	height: 100%;
}
.canvas-chat .list-cell {
	display: table-cell;
	vertical-align: bottom;
}
.canvas-chat .list-cell .message {
	margin-top: 8px;
	display: flex;
	flex-direction: column-reverse;
	align-items: flex-start;
}
.canvas-chat .list-cell .message.mine {
	align-items: flex-end;
}
.canvas-chat .list-cell .message.mine .row {
	flex-direction: row-reverse;
}
.canvas-chat .list-cell .message .msg-text {
	display: flex;
	margin: 3px 1rem 0 0;
}
.canvas-chat .list-cell .message.mine .msg-text {
	margin: 3px 0 0 1rem;
}
.canvas-chat .list-cell .message .msg-text span {
	font-size: 12px;
	background-color: $surface;
	color: #000;
	padding: 8px 16px;
	border-radius: 0px 24px 24px;
	word-break: break-all;
}
.canvas-chat .list-cell .message .msg-text span.file {
	max-width: 140px;
	padding: 4px;
}
.canvas-chat .list-cell .message .msg-text span.file img {
	cursor: pointer;
	object-fit: cover;
	width: 100%;
	max-height: 180px;
	border-radius: 4px;
}
.canvas-chat .list-cell .message.mine .msg-text span {
	border-radius: 24px 0px 24px 24px;
}
.canvas-chat .list-cell .message.mine .msg-text svg {
	cursor: pointer;
	align-self: center;
	margin-right: 6px;
	min-width: 20px;
	color: $light-gray;
}
.canvas-chat .list-cell .message .msg-time {
	font-size: 10px;
	color: $light-gray;
	line-height: 16px;
}
.canvas-chat .list-cell .message.mine .msg-time {
	text-align: right;
}
.canvas-chat .block-chat {
	position: absolute;
	width: 100%;
	bottom: 0;
	padding: 14px 24px;
	border-top: 1px solid $line-gray;
}
.canvas-chat .block-chat input {
	background-color: $surface;
	border: none;
	border-radius: 18px;
	padding: 9px;
	font-size: 12px;
}
.canvas-chat .chat-attacch {
	position: relative;
	cursor: pointer;
}
.canvas-chat .chat-attacch input {
	position: absolute;
	outline: none;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	opacity: 0;
	cursor: pointer;
}
.canvas-chat .close {
	position: absolute;
	left: 1rem;
	cursor: pointer;
}
.canvas-chat .close:hover {
	opacity: 0.9;
}
.video {
	position: absolute;
	background-color: #000;
	color: $surface;
	height: 100%;
	width: 100%;
	overflow: hidden;
}
.video .menu-bar {
	position: absolute;
	width: 100%;
	height: 80px;
	background: #292929;
	border-radius: 10px 10px 0px 0px;
	bottom: 0px;
	padding: 14px 120px;
}
.video .menu-bar .end-call {
	display: flex;
	align-items: center;
	justify-content: center;
	background: #FF2828;
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
	border-radius: 30px;
	width: 100px;
	height: 52px;
	margin: 0 20px;
	padding: 8px;
	cursor: pointer;
}
.video .menu-bar .end-call svg {
	width: 35px;
	height: 35px;
}
.video .menu-bar .end-call:hover, .video .menu-bar .mic:hover, .video .menu-bar .camera:hover {
	opacity: 0.9;
}
.video .menu-bar .mic, .video .menu-bar .camera {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 52px;
	height: 52px;
	background: rgba(255, 255, 255, 0.3);
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
	border-radius: 30px;
	cursor: pointer;
}
.video .menu-bar .mic svg, .video .menu-bar .camera svg {
	width: 27px;
	height: 27px;
}
.video .menu-bar .end-call:hover, .video .menu-bar .mic:hover, .video .menu-bar .record:hover {
	opacity: 0.9;
}
.video .menu-bar .mic, .video .menu-bar .record {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 52px;
	height: 52px;
	background: rgba(255, 255, 255, 0.3);
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
	border-radius: 30px;
	cursor: pointer;
	margin-left: 10px;
	margin-right: 5px;
}
.video .menu-bar .mic svg, .video .menu-bar .record svg {
	width: 27px;
	height: 27px;
}
.video .menu-bar .end-call:hover, .video .menu-bar .mic:hover, .video .menu-bar .sharescreen:hover {
	opacity: 0.9;
}
.video .menu-bar .mic, .video .menu-bar .sharescreen {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 52px;
	height: 52px;
	background: rgba(255, 255, 255, 0.3);
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
	border-radius: 30px;
	cursor: pointer;
	margin-left: 5px;
	margin-right: 5px;
}
.video .menu-bar .mic svg, .video .menu-bar .sharescreen svg {
	width: 27px;
	height: 27px;
}
.video .menu-bar #period {
	font-size: 20px;
	min-width: 77px;
}
.video .menu-bar .name {
	margin-left: 3rem;
	line-height: 19px;
}
.block-waiting-period {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: calc(100% - 80px);
}
.block-waiting-period > div {
	width: 500px;
	text-align: center;
}
.block-waiting-period .doc-img {
	width: 200px;
	height: 200px;
	margin: 0 auto 2rem;
}
.block-waiting-period .doc-img img {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	object-fit: cover;
}
.block-waiting-period .name {
	font-size: 36px;
	margin-bottom: 1.5rem;
}
.block-waiting-period > div > div:last-child img {
	width: 332px;
}
.container.telemed-no-response {
	margin: 72px auto;
	max-width: 600px;
}
.telemed-no-response .desc {
	background: rgba(255, 255, 255, 0.2);
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
	border-radius: 10px;
	color: #fff;
	padding: 20px 1rem 1rem;
	text-align: center;
}
.telemed-no-response .action {
	margin-top: 130px;
}
.telemed-no-response .action > div:first-child {
	margin-bottom: 3rem;
}
.telemed-no-response .block {
	display: inline-block;
	text-align: center;
	cursor: pointer;
}
.telemed-no-response .action .icon {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto auto 0.5rem;
	width: 80px;
	height: 80px;
	background-color: $primary-50;
	border-radius: 50%;
	color: $primary-900;
}
.telemed-no-response .action .icon svg {
	width: 35px;
	height: 35px;
}
.telemed-no-response .action .icon.videocam {
	background-color: $success;
	color: #fff;
}
.telemed-no-response .action .icon.change {
	background-color: $secondary-50;
	color: $secondary-200;
}
.telemed-no-response .action .icon.chat {
	width: 40px;
	height: 40px;
	background: rgba(255, 255, 255, 0.2);
	color: $secondary-200;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
}
.telemed-no-response .action .icon.videocam svg, .telemed-no-response .action .icon.change svg {
	width: 40px;
	height: 40px;
}
.telemed-no-response .action .icon.chat svg {
	width: 24px;
	height: 24px;
}
.telemed-no-response .block:hover {
	color: $secondary-200;
}
.telemed-no-response .block:hover .icon {
	opacity: 0.9;
}
.block-video {
	width: 100%;
	height: calc(100% - 80px);
	padding: 30px 32px 28px;
}
.block-video iframe {
	border-radius: 20px;
}
.container.review {
	max-width: 440px;
	margin: 108px auto 0;
}
.review img {
	width: 80px;
	height: 80px;
	border-radius: 50%;
	margin: 0 auto 10px;
}
.review .star span, .review .star span::before {
	height: 52px !important;
	padding: 1rem 0.5rem 0;
}
.review .badge {
	cursor: pointer;
}
.calling {
	min-width: 800px;
}
.calling .modal-body {
	padding: 40px;
}
.calling .consult {
	justify-content: center;
	margin: 3rem 0;
}
.calling .consult h2 {
	margin-bottom: 0.25rem;
}
.calling .btn {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 9px 0.75rem;
	min-width: 200px;
}
.calling .btn-outline-secondary {
	padding: 1rem 0.75rem;
}
.calling .btn-outline-secondary:hover {
	padding: 13px 0.75rem;
}
.icon-select {
	display: inline-block;
	width: 24px;
	margin-right: 10px;
}
.icon-select img {
	width: 24px;
	border-radius: 5px;
}
.share-screen {
	border-radius: 10px;
	margin-right: 1.5rem;
	padding: 4px;
}
.share-screen svg, .icon-chat {
	color: $secondary-200;
	cursor: pointer;
	width: 32px;
	height: 32px;
}
.share-screen.on {
	background-color: $secondary-200;
}
.share-screen.on svg {
	color: #fff;
}
.manage-add-account {
	color: $secondary-200;
	font-size: 16px;
}
.logo-pharmacy {
	width: 60px;
	height: 60px;
	border-radius: 10px;
	background-color: $primary-50;
}
.logo-pharmacy img {
	width: 100%;
	object-fit: cover;
	border-radius: 10px;
}
.maps.modal-open .navbar {
	padding-right: 0;
}
.map {
	margin: 72px auto 0;
}
.modal .map {
	margin: 0;
	padding-top: 72px;
}
.modal .map .modal-body {
	overflow: hidden;
}
.map .address-secleced {
	background-color: $primary-900;
	color: #fff;
	padding: 1rem 0;
}
.map .map-overlay {
	position: absolute;
	width: 100%;
	height: calc(100% - 215px);
}
.map .map-overlay.step2, .modal .map .map-overlay {
	height: calc(100% - 72px);
}
.map .map-overlay .address-list {
	position: absolute;
	top: 60px;
	width: 100%;
}
.map .map-overlay .address-list .container > .row, .drug-address-list .container > .row {
	flex-wrap: unset;
	overflow: hidden;
	overflow-x: auto;
	margin: 0;
	padding-bottom: 5px;
}
.map .map-overlay .address-list .container > .row::-webkit-scrollbar {
	height: 5px;
}
.map .map-overlay .address-list .container > .row::-webkit-scrollbar-thumb {
	background-color: #ccc;
	border-radius: 50px;
}
.map .map-overlay .address-list .container > .row > div, .drug-address-list .container > .row > div {
	padding-left: 0;
}
.map .map-overlay .address-confirm {
	position: absolute;
	max-width: 400px;
	left: 0;
	right: 0;
	bottom: 60px;
	margin: 0 auto;
}
.map .map-overlay .address-confirm button {
	width: 100%;
}
.map .drug-address-list {
	position: absolute;
	bottom: 80px;
	width: 100%;
	z-index: 9;
}
.address-list .card-address, .drug-address-list .card-address {
	border: 1px solid $secondary-200;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
	border-radius: 10px;
	padding: 8px 1rem;
}
.address-list .card-address .full-address {
	font-size: 12px;
	color: #000;
	line-height: 15.6px;
}
.canvas-address {
	margin-top: 72px;
	background: $surface;
	box-shadow: 4px 0px 20px rgba(0, 0, 0, 0.25);
}
.canvas-address .offcanvas-header {
	background-color: $primary-900;
}
.canvas-address .offcanvas-body {
	padding: 20px;
}
.canvas-address .offcanvas-body .card-body {
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
	border-radius: 10px;
	margin-bottom: 20px;
	min-height: 97px;
}
.canvas-address .offcanvas-body .card-body img {
	width: 60px;
	height: 60px;
	object-fit: cover;
}
.canvas-address .offcanvas-body .card-body button, .drug-address-list .card-address button {
	position: absolute;
	right: 8px;
	bottom: 8px;
}
.list-address {
	margin: 35px calc(-0.5 * var(--bs-gutter-x)) 120px;
}
.list-address .card-body {
	background-color: transparent;
	border: 1px solid $secondary-200;
	border-radius: 10px;
	margin-bottom: 1.5rem;
	min-height: 212px;
	cursor: pointer;
}
.list-address.insure .card-body {
	cursor: default
}
.list-address .card-body:hover {
	border-color: $secondary-300;
}
.list-address .card-body .contact-person {
	position: absolute;
	font-size: 12px;
	line-height: 15.6px;
	bottom: 1rem;
}
.list-address .card-body .new-address {
	font-weight: bold;
	color: $secondary-200;
	text-align: center;
}
.list-address .card-body .remove {
	position: absolute;
	right: 1rem;
	cursor: pointer;
	color: $secondary-200;
}
.list-address .card-body .remove:hover {
	opacity: 0.8;
}
.payment .list-address {
	margin: 0;
	padding: 0 2rem;
}
.select-address-type {
	display: inline-flex;
	align-items: center;
	background: #FFFFFF;
	color: $light-gray;
	padding: 8px 20px;
	border-radius: 10px;
	cursor: pointer;
}
.select-address-type:hover {
	background: $primary-50;
	color: $light-gray;
}
.select-address-type.active {
	background: $primary-900;
	color: #FFFFFF;
}
.map-display {
	width: 100%;
	height: 160px;
	border-radius: 20px;
}
.wait-pharmacy {
	margin-top: 155px;
	text-align: center;
}
.kyc-step {
	display: flex;
	align-items: center;
}
.kyc-step span {
	display: inline-flex;
	font-size: 20px;
	background-color: $primary-900;
	color: #fff;
	width: 32px;
	height: 32px;
	margin-right: 1rem;
	border-radius: 50%;
	justify-content: center;
	align-items: center;
}
.add-document {
	position: relative;
	display: table;
	border: 1px solid $secondary-200;
	border-radius: 10px;
	padding: 1rem 20px;
	margin: 40px auto 0;
	cursor: pointer;
}
.add-document:hover {
	border-width: 2px;
	padding: 15px 19px;
}
.bmi div {
	padding: 10px;
}
.bmi div:first-child {
	background-color: #ffc43f;
	border-radius: 10px 0 0 10px;
}
.bmi div:nth-child(2) {
	background-color: #9acd32;
}
.bmi div:last-child {
	background-color: #ff7556;
	border-radius: 0 10px 10px 0;
}
.bmi div.active::before {
	content: "";
	position: absolute;
	width: 12px;
	height: 12px;
	background: #f6f7f9;
	transform: rotate(45deg);
	top: 14px;
	right: 0;
	left: 0;
	margin: auto;
}
.list-department {
	margin-bottom: 80px;
}
.department-viewmore {
	display: none;
}
.value-status {
	font-size: 14px;
	margin-left: 0.5rem;
}
.list-accounts {
	margin: 1.5rem 0 120px;
}
.list-accounts .account-block {
	align-items: center;
	margin: 0;
	padding: 1rem 0;
}
.cargiver .tab-pane.search {
	max-width: 620px;
	margin: auto;
	padding: 1.5rem 0;
}
.link-his {
	margin-bottom: 120px;
	text-align: center;
}
.link-his .col > span:first-child {
	margin-right: 3rem;
}
.timeline-status {
	padding: 1rem;
	margin-bottom: 1.5rem;
}
.timeline-status .text-is {
	color: #000;
	margin-right: 1rem;
}
.timeline-status .text-status {
	font-size: 24px;
}
.modal-terms {
	--bs-modal-width: 800px;
}
.font-size {
	line-height: 15.6px;
}
.font-size .label {
	font-size: 12px;
	color: $light-gray;
}
.font-size .size {
	display: flex;
	align-items: baseline;
	gap: 8px;
}
.font-size .size .s13 {
	font-size: 13.5px;
}
.font-size .size .s16 {
	font-size: 16px;
}
.font-size .size .s18 {
	font-size: 18.5px;
}
.font-size .size span:hover {
	cursor: pointer;
	color: $secondary-200;
}
.history .card.list .icon-drugs {
	position: absolute;
	margin: 2px 0 0 8px;
}
.loading-chating {
	color: #1e2125;
	text-align: center;
	font-size: 13px;
}
.network-signal {
	position: fixed;
	top: 0;
	left: 50%;
	z-index: 5000;
	font-size: 12px;
	width: 300px;
	text-align: center;
	margin-left: -150px;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
	background-color: #e9ecef;
	padding: 2px;
}
.block-video-guest {
	position: fixed;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	border: 1px solid #1e2125;
}
.card-nursing, .card-news {
	border-radius: 1rem;
	border: 1px solid $line-gray;
	background: $background;
}
.card-nursing img, .card-news img, .card-nursing div.no-image {
	border-radius: 1rem 1rem 0 0;
	height: 200px;
	object-fit: cover;
}
.card-nursing div.no-image {
	background-color: $light-gray;
}
.card-nursing .detail {
	padding: 8px;
}
.card-nursing .detail .title {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 5px;
}
.card-nursing .detail .title h6 {
	color: #000;
	margin: 0;
}
.card-nursing .detail .title h6:hover {
	color: $secondary;
}
.card-nursing .detail .title svg {
	color: $primary;
	min-width: 24px;
}
.card-nursing .detail .location {
	display: flex;
	align-items: center;
	font-size: 12px;
	color: #000;
	line-height: normal;
	margin-bottom: 5px;
}
.card-nursing .detail .rating, .list-item .rating {
	display: flex;
	font-size: 12px;
	color: $light-gray;
	line-height: normal;
}
.list-item .rating {
	font-size: 16px;
	align-items: center;
}
.card-nursing .detail .rating svg {
	margin-top: -3px;
}
.card-nursing .detail .rating span, .list-item .rating span {
	display: flex;
	align-items: center;
	font-weight: bold;
	color: $warning;
	margin-right: 0.5rem;
}
.card-nursing .price {
	text-align: right;
	padding: 7px 8px;
	border-top: 1px solid $line-gray;
}
.card-nursing .price.hot-deal {
	position: relative;
	display: flex;
	align-items: flex-start;
	justify-content: flex-end;
	border-color: $danger;
	min-height: 47px;
}
.card-nursing .price.hot-deal > span:first-child {
	position: absolute;
	display: inline-flex;
	align-items: center;
	left: 0;
	top: 0;
	background-color: $danger;
	color: #fff;
	padding: 2px 8px;
	gap: 4px;
	border-radius: 0px 4px 4px 0px;
}
.card-nursing .price.hot-deal .start {
	line-height: 16px;
}
.card-nursing .price.hot-deal.not-promotion {
	align-items: center;
}
.card-nursing .price.hot-deal.not-promotion .start {
	position: relative;
	background-color: transparent;
	padding: 0;
}
.card-nursing .price .per-month {
	color: $secondary;
}
.card-nursing .price.hot-deal .per-month {
	color: $danger;
}
.card-news img {
	height: 155px;
}
.card-news .detail {
	padding: 0 1rem 6px 1rem;
}
.card-news .detail h6 {
	font-size: 14px;
	font-weight: normal;
	color: $secondary;
	line-height: 20px;
	margin: 2px 0;
}
.card-news .detail p {
	color: #000;
	margin: 0;
}
.canvas-search {
	border-radius: 1rem 1rem 0 0;
}
.offcanvas.offcanvas-bottom {
	height: 93%;
}
.canvas-search .offcanvas-header {
	justify-content: flex-end;
}
.canvas-search .offcanvas-header button {
	border: none;
	background-color: transparent;
	color: $primary;
	padding: 0;
}
.canvas-search .filter-section {
	border-bottom: 1px solid $line-gray;
	padding: 0 1rem;
	margin-bottom: 1rem;
}
.canvas-search .filter-section > h6 {
	display: flex;
	align-items: center;
	margin-bottom: 1rem;
	gap: 12px;
}
.canvas-search .react-datepicker, .canvas-search .react-datepicker__month-container {
	width: 100%;
	box-shadow: none;
	background-color: #FFF;
}
.canvas-search .react-datepicker__header, .canvas-search .react-datepicker__day-name {
	background-color: #FFF;
}
.canvas-search .react-datepicker__header .react-datepicker__day-names, .canvas-search .react-datepicker__week,
#accordionFilter .react-datepicker__header .react-datepicker__day-names, #accordionFilter .react-datepicker__week,
.datepicker-full .react-datepicker__header .react-datepicker__day-names, .datepicker-full .react-datepicker__week {
	display: flex;
	justify-content: space-between;
}
.canvas-search .react-datepicker__month, #accordionFilter .react-datepicker__month {
	margin: 0;
}
.canvas-search .list-items {
	display: flex;
	padding: 1rem 0 1rem 12px;
	justify-content: space-between;
	align-items: center;
}
.canvas-search .list-items .form-check-label {
	width: 24px;
}
.nursing-top {
	position: relative;
}
.nursing-top img {
	width: 100%;
}
.nursing-top .description {
	position: absolute;
	margin: auto;
	left: 0;
	right: 0;
	bottom: 20px;
	max-width: 775px;
	text-align: center;
}
.nursing-top .description h2 {
	font-size: 32px;
}
.block-nursing .block-list {
	display: flex;
	margin: 0;
	gap: 40px;
}
.block-nursing .block-list > div:first-child {
	width: 320px;
	min-width: 320px;
}
.block-nursing .block-list > div:last-child {
	width: 100%
}
.block-nursing .block-list .list-item {
	display: flex;
	border-radius: 1rem;
	border: 1px solid $line-gray;
	background-color: $background;
	margin-bottom: 20px;
}
.block-nursing .block-list .list-item > div:first-child {
	width: 240px;
	min-width: 240px;
}
.block-nursing .block-list .list-item > div:last-child {
	display: flex;
	padding: 20px;
	flex-direction: column;
	justify-content: space-between;
	flex: 1 0 0;
}
.block-nursing .block-list .list-item .detail {
	display: grid;
	gap: 14px;
}
.block-nursing .block-list .list-item img, .block-room-style img, .images-overview img {
	cursor: pointer;
}
.block-nursing .block-list .list-item .img-1 {
	background-color: $light-gray;
	border: solid 1px #FFF;
	width: 240px;
	height: 240px;
	border-radius: 1rem 0 0;
}
.block-nursing .block-list .list-item .img-1 img, .block-room-style .img-1 img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 1rem 0 0;
}
.block-nursing .block-list .list-item .img-list, .block-room-style .img-list {
	display: flex;
}
.block-nursing .block-list .list-item .img-list > div {
	position: relative;
	width: 60px;
	height: 60px;
	background-color: $light-gray;
	border: solid 1px #FFF;
}
.block-nursing .block-list .list-item .img-list > div:first-child > img, .block-nursing .block-list .list-item .img-list > div:first-child, .block-room-style .img-list > div:first-child, .block-room-style .img-list > div:first-child > img {
	border-radius: 0 0 0 1rem;
}
.block-nursing .block-list .list-item .img-list > div:last-child span, .images-overview .img-sm > div:last-child > div:last-child span, .block-room-style .img-list > div:last-child > span {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	line-height: 20px;
	color: #FFF;
	background-color: rgb(0 0 0 / 0.5);
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	cursor: pointer;
}
.block-nursing .block-list .list-item .h3 {
	margin: 0;
	line-height: 36px;
}
.block-nursing .block-list .list-item .location {
	display: flex;
}
.block-nursing .block-list .list-item .location span {
	font-size: 14px;
}
.block-nursing .block-list .pagination, .block-nursing-detail .pagination {
	margin-top: 60px;
	justify-content: space-between;
}
.block-nursing-detail .pagination {
	margin-top: 28px;
}
.block-nursing .block-list .pagination > div, .block-nursing-detail .pagination > div {
	display: flex;
	align-items: center;
	justify-content: center;
	color: #FFF;
	border-radius: 8px;
	background-color: $primary;
	width: 40px;
	height: 40px;
	cursor: pointer;
}
.block-nursing .block-list .pagination > div.disabled, .block-nursing-detail .pagination > div.disabled {
	background-color: $line-gray;
	cursor: default;
}
.list-item .fav {
	text-align: right;
	color: $primary;
}
.list-item .fav svg {
	cursor: pointer;
}
.list-item .price {
	text-align: right;
}
.list-item .price .per-month {
	font-size: 20px;
	color: $secondary;
}
#accordionFilter .accordion-button, .guest .accordion-button {
	padding: 1rem 12px;
	justify-content: space-between;
	background-color: #fff;
	border: none;
	box-shadow: none;
}
#accordionFilter .accordion-button:not(.collapsed) > svg, .guest .accordion-button:not(.collapsed) > svg {
	transform: rotate(0);
	transition: transform 0.2s ease-in-out;
}
#accordionFilter .accordion-button.collapsed > svg, .guest .accordion-button.collapsed > svg {
	transform: rotate(-180deg);
	transition: transform 0.2s ease-in-out;
}
#accordionFilter .accordion-button::after, .guest .accordion-button::after {
	content: none;
}
#accordionFilter .accordion-button > span, .guest .accordion-button > span {
	display: flex;
	align-items: center;
}
#accordionFilter .accordion-body ul li {
	display: flex;
	justify-content: space-between;
	align-items: center;
	list-style: none;
	padding: 1rem 12px 1rem 28px;
}
#accordionFilter .accordion-body ul li > div {
	max-width: 230px;
}
#accordionFilter .accordion-body ul li .form-check-label {
	width: 24px;
}
#accordionFilter .react-datepicker, .datepicker-full .react-datepicker {
	background-color: #FFF;
	border: none;
	box-shadow: none;
	padding: 20px 0;
	width: 100%;
}
#accordionFilter .react-datepicker .react-datepicker__month-container, .datepicker-full .react-datepicker .react-datepicker__month-container {
	width: 100%;
}
#accordionFilter .react-datepicker .react-datepicker__header, #accordionFilter .react-datepicker__day-name, .datepicker-full .react-datepicker__header, .datepicker-full .react-datepicker__day-name {
	background-color: #FFF;
}
.back-forward {
	margin-bottom: 40px;
}
.back-forward svg {
	margin-right: 8px;
}
.images-overview {
	display: flex;
	justify-content: space-between;
	flex: 1 0 0;
	gap: 8px;
}
.images-overview .img-1 {
	width: 100%;
	height: 428px;
	background-color: $light-gray;
}
.images-overview .img-1 img, .images-overview .img-sm > div > div img, .block-nursing-detail .title .logo-center img, .block-reviews .img-profile img, .block-check-info .room .img img, .guest .img-guest img, .block-payment-info .room .img img, .block-nursing .block-list .list-item .img-list > div > img, .block-room-style .img-list > div > img, .slide-galleries .splide__slide img, .nh-center .img img, .nhb-detail .center-logo img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.modal .slide-galleries img {
	object-fit: contain;
}
.images-overview .img-sm {
	display: flex;
	gap: 8px;
	width: 436px;
}
.images-overview .img-sm > div > div {
	position: relative;
	background-color: $light-gray;
	width: 210px;
	height: 210px;
	margin-bottom: 8px;
}
.block-nursing-detail .title {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.block-nursing-detail .title > div {
	display: flex;
	align-items: center;
	gap: 1rem;
}
.block-nursing-detail .title .center-logo, .nh-center .img, .nhb-detail .center-logo {
	background-color: $light-gray;
	width: 100px;
	height: 100px;
	min-width: 100px;
	border-radius: 50%;
}
.nhb-detail .center-logo {
	width: 44px;
	height: 44px;
	min-width: auto;
}
.block-nursing-detail .title .center-name, .nh-center {
	display: flex;
	align-items: center;
	gap: 20px;
}
.nhb-detail .google-map {
	height: 100px;
}
.nh-center {
	gap: 8px;
}
.nh-center .img {
	width: 40px;
	height: 40px;
	min-width: 40px;
}
.block-nursing-detail .title .center-rating {
	display: flex;
	font-weight: bold;
	color: $warning;
	margin-left: 4px;
	gap: 8px;
}
.block-nursing-detail .description {
	margin: 24px 0;
}
.block-nursing-detail .sevices {
	display: flex;
	gap: 20px;
}
.block-nursing-detail .sevices > div {
	display: flex;
	flex-direction: column;
	padding: 1rem;
	gap: 1rem;
	flex: 1 0 0;
	border-radius: 1rem;
	border: 1px solid $line-gray;
	min-height: 320px;
}
.block-nursing-detail .sevices ul {
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin: 0;
}
.block-nursing-detail .sevices .have-price li > div {
	display: flex;
	justify-content: space-between;
}
.block-nursing-detail .sevices .google-map {
	height: 120px;
}
.block-nursing-detail .sevices .google-map > div, .nhb-detail .google-map > div {
	border-radius: 10px;
}
.block-nursing-detail .sevices .addr, .nhb-detail .addr {
	display: flex;
	padding: 0px 1rem 0;
	gap: 1rem;
}
.block-reviews {
	display: flex;
	flex-direction: column;
	gap: 12px;
	padding: 1rem;
	border-bottom: 1px solid $line-gray;
}
.block-reviews > div:first-child {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.block-reviews > div:first-child > div:first-child {
	display: flex;
	align-items: center;
	gap: 8px;
}
.block-reviews > div:first-child > div:last-child {
	display: flex;
	font-weight: bold;
	gap: 4px;
	color: $warning;
}
.block-reviews > div:last-child {
	font-size: 14px;
	line-height: 20px;
	color: $light-gray;
}
.block-reviews .img-profile {
	width: 60px;
	height: 60px;
	min-width: 60px;
	border-radius: 50%;
	background-color: $light-gray;
}
.block-reviews .img-profile img {
	border-radius: 50%;
}
.block-reviews .name {
	font-weight: bold;
}
.slide-certificates .card {
	border-radius: 10px;
	border: 1px solid $line-gray;
	width: 320px;
	height: 160px;
	background-color: $light-gray;
}
.slide-certificates .card img {
	object-fit: cover;
	border-radius: 10px;
	width: 100%;
	height: 100%;
}
.block-room-style {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
	border-radius: 1rem;
	border: 1px solid $line-gray;
	background-color: $background;
	margin-bottom: 24px;
}
.block-room-style > div:first-child {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 4px;
}
.block-room-style .img-1 {
	width: 400px;
	height: 280px;
	background-color: $light-gray;
	border-radius: 1rem 0 0;
}
.block-room-style .img-list {
	display: flex;
	align-items: flex-start;
	gap: 4px;
}
.block-room-style .img-list > div {
	position: relative;
	width: 97px;
	height: 97px;
	background-color: $light-gray;
}
.block-room-style > div:nth-child(2) {
	flex: 1 0 0;
}
.block-room-style .detail {
	display: flex;
	flex-direction: column;
	gap: 20px;
}
.block-room-style .detail .block-1 {
	display: flex;
	padding: 16px 16px 12px 0px;
	gap: 8px;
	border-bottom: 1px solid $line-gray;
}
.block-room-style .detail .block-1 > div:nth-child(2) {
	display: flex;
	align-items: center;
	gap: 8px;
}
.block-room-style .detail .block-1 > div:last-child {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 8px;
	flex: 1 0 0;
}
.block-room-style .detail .block-2 {
	display: flex;
	padding-right: 0px;
	gap: 20px;
}
.block-room-style .detail .block-2 > div {
	display: flex;
	flex-direction: column;
	gap: 8px;
	flex: 1 0 0;
}
.block-room-style .action {
	display: flex;
	margin-top: auto;
	padding: 0 1rem 1rem 0;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
	gap: 1rem;
}
.block-room-style .action .terms {
	display: flex;
	font-weight: bold;
	color: $primary;
	cursor: pointer;
}
.block-room-style .action > div:last-child {
	display: flex;
	align-items: flex-end;
	gap: 18px;
}
.block-room-style .action button {
	min-width: 180px;
}
.block-room-style .action .security-deposit {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
}
.block-room-style .action .security-deposit .remark {
	font-size: 14px;
	color: $light-gray;
}
.modal h6.title {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid $line-gray;
	padding-bottom: 12px;
	margin-bottom: 1rem;
}
.modal h6.title.none {
	padding: 1rem;
	justify-content: flex-end;
}
.modal h6.title span {
	cursor: pointer;
	color: $primary;
}
.modal h6.title span:hover {
	color: $primary-600;
}
.modal .footer {
	display: flex;
	padding: 12px 0 0;
	align-items: center;
	gap: 10px;
	border-top: 1px solid $line-gray;
}
.visit-note {
	font-size: 12px;
	line-height: normal;
}
.visit-note a {
	color: $secondary;
	font-weight: bold;
}
.visit-success {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 8px;
}
.block-check-info {
	display: flex;
	padding-top: 40px;
	align-items: flex-start;
	gap: 20px;
}
.block-check-info > div:first-child {
	display: flex;
	flex-direction: column;
	background: $background;
	border: 1px solid $line-gray;
	border-radius: 1rem;
	width: 400px;
	padding: 1rem;
	gap: 15px;
}
.block-check-info .form-info {
	display: flex;
	flex-direction: column;
	gap: 28px;
	flex: 1;
}
.block-check-info .home-name {
	display: flex;
	padding-bottom: 12px;
	align-items: center;
	gap: 8px;
	border-bottom: 1px solid $line-gray;
}
.block-check-info .home-name h5 {
	margin: 0;
}
.block-check-info .room, .block-payment-info .room {
	display: flex;
	align-items: center;
	gap: 20px;
}
.block-check-info .room .img, .block-payment-info .room .img {
	background-color: $light-gray;
	border: solid 1px #FFF;
	width: 120px;
	height: 160px;
	border-radius: 8px;
}
.block-check-info .room .img img, .block-payment-info .room .img img {
	border-radius: 8px;
}
.block-check-info .form-info > section {
	display: flex;
	flex-direction: column;
	gap: 20px;
}
.block-check-info .form-info > section h5 {
	display: flex;
	align-items: center;
	gap: 8px;
	margin: 0;
}
.block-check-info .form-info > section h5 span {
	color: $light-gray;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
}
.block-check-info .form-info > section .add-services, .block-check-info .form-info > section .travel {
	display: flex;
	flex-direction: column;
	border-radius: 1rem;
	border: 1px solid $line-gray;
}
.block-check-info .form-info > section .add-services > div, .block-check-info .form-info > section .travel > div {
	display: flex;
	border-top: 1px solid $line-gray;
	padding: 12px 16px;
	gap: 10px;
}
.block-check-info .form-info > section .travel > div {
	border: none;
}
.block-check-info .form-info > section .add-services .description {
	display: block;
	border: none;
	background: $background;
	font-size: 14px;
	line-height: 20px;
	border-radius: 1rem 1rem 0 0;
}
.block-check-info .list-service > div:first-child > label {
	width: 24px;
}
.block-check-info .list-service > div:last-child {
	display: flex;
	justify-content: space-between;
	flex: 1 0 0;
}
.block-check-info .form-info > section .guest {
	display: flex;
	padding: 1rem;
	flex-direction: column;
	gap: 25px;
	border-radius: 1rem;
	border: 1px solid $line-gray;
}
.date-w-100 .react-datepicker-wrapper, .datepicker-full .react-datepicker-wrapper {
	width: 100%;
}
.guest .accordion-header .accordion-button {
	padding: 15px;
	border: 1px solid $line-gray;
	border-radius: 8px !important;
}
.guest .block-guest {
	display: flex;
	align-items: center;
	gap: 8px;
}
.guest .accordion-header .accordion-button:not(.collapsed), .guest .accordion-header .accordion-button.collapsing {
	border-bottom-color: transparent;
	border-radius: 8px 8px 0 0 !important;
}
.guest .accordion-collapse {
	border: 1px solid $line-gray;
	border-top-color: transparent;
	border-radius: 0 0 8px 8px !important;
}
.guest .accordion-collapse > div {
	display: flex;
	align-items: center;
	padding: 16px;
	gap: 8px;
	border-bottom: 1px solid $line-gray;
	cursor: pointer;
}
.guest .accordion-collapse > div:last-child {
	border: none;
	justify-content: center;
	cursor: default;
}
.guest .img-guest {
	background-color: $light-gray;
	border-radius: 50%;
	width: 40px;
	height: 40px;
}
.guest .img-guest img, .nh-center .img img, .nhb-detail .center-logo img {
	border-radius: 50%;
}
.guest .accordion-collapse > div button {
	min-width: 358px;
}
.block-payment-info {
	display: flex;
	padding: 40px 0 0;
}
.block-payment-info .room-info, .block-payment-info .payment-info {
	display: flex;
	flex-direction: column;
	border-radius: 1rem;
	border: 1px solid $line-gray;
	padding: 0;
}
.block-payment-info h5.title {
	display: flex;
	align-items: center;
	border-bottom: 1px solid $line-gray;
	background: $background;
	border-radius: 1rem 1rem 0 0;
	margin: 0;
	padding: 16px;
	gap: 8px;
}
.block-payment-info .room-info > div, .block-payment-info .payment-info > div {
	display: flex;
	padding: 16px;
	align-items: center;
	gap: 20px;
	border-bottom: 1px solid $line-gray;
}
.block-payment-info .room-info > div:last-child, .block-payment-info .payment-info > div:last-child {
	border: none;
}
.block-payment-info .payment-info .coupon, .block-payment-info .payment-info .channel, .block-payment-info .payment-info .summary {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
}
.block-payment-info .payment-info .coupon > div:last-child {
	display: flex;
	width: 100%;
	gap: 16px;
}
.block-payment-info .payment-info .coupon button {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}
.block-payment-info .payment-info .coupon .input-group {
	.form-control {
		border-left-width: 0;
		padding-left: 0;
	}
	.input-group-text, .form-control:disabled {
		background-color: #FFF;
		padding-right: 0.5rem;
	}
	&.success .input-group-text, &.success .form-control {
		border-color: $success;
		color: $success;
		svg {
			color: $success
		}
	}
	&.error .input-group-text, &.error .form-control {
		border-color: $danger;
		color: $danger;
		svg {
			color: $danger
		}
	}
}
.block-payment-info .payment-info .channel {
	border-bottom: none;
}
.block-payment-info .payment-info .channel .block-payment {
	display: flex;
	padding: 7px 8px;
	justify-content: space-between;
	align-items: center;
	border-radius: 10px;
	border: 1px solid $line-gray;
	margin: 0;
	width: 100%;
	height: auto;
}
.block-payment-info .payment-info .channel .block-payment .payment-icon {
	svg, img {
		width: 40px;
		height: 40px;
	}
}
.block-payment-info .payment-info .channel .block-payment .form-check-label {
	width: auto;
}
.block-payment-info .payment-info .summary {
	gap: 8px;
}
.block-payment-info .payment-info .summary > div {
	display: flex;
	justify-content: space-between;
	padding: 16px 0px 15px;
	gap: 16px;
	border-bottom: 1px solid $line-gray;
	width: 100%;
}
.block-payment-info .payment-info .summary > div > div {
	display: flex;
	align-items: center;
	gap: 1rem;
}
.block-payment-info .payment-info .summary > div:first-child, .block-payment-info .payment-info .summary > div:nth-last-child(2) {
	border-bottom: none;
}
.block-payment-info .payment-info .summary .total {
	padding: 16px 8px;
	border-radius: 8px;
	background: $background;
	border-bottom: none;
	font-weight: bold;
}
.block-payment-info .payment-info .summary .cancel {
	cursor: pointer;
	color: $light-gray;
	line-height: 20px;
	margin-bottom: 3px;
}
.block-payment-info .payment-info .summary .cancel.hidden {
	color: transparent;
	cursor: default;
}
.slide-galleries.thumbnail .splide__slide img {
	border-radius: 4px;
}
.nhb-detail {
	margin-bottom: 80px;
}
.nhb-detail .booking-number {
	margin-bottom: 20px;
}
.nhb-detail .booking-number .dropdown > span {
	cursor: pointer;
	color: $primary;
}
.nhb-detail .booking-number .dropdown > span:hover {
	color: $primary-600;
}
.nhb-detail .timeline-status {
	display: flex;
	padding: 12px 16px;
	flex-direction: column;
	gap: 16px;
	border-radius: 16px;
	background: $background;
}
.follow-care {
	display: flex;
	flex-direction: column;
	gap: 28px;
}
.list-problem {
	padding: 12px 8px;
	border-bottom: 1px solid $line-gray;
}
.list-problem .form-check-label span.radio-text {
	padding: 0 0 0 40px;
}
.react-datepicker__day--highlighted-custom-1 {
	display: flex;
	align-items: center;
	justify-content: center;
	color: $primary;
	border: 2px solid $primary;
	border-radius: 40px;
}
.react-datepicker__day--selected, .react-datepicker__day--selected:hover, .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--ke {
	color: #FFF;
}
.modal-action {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 12px 16px;
	gap: 10px;
}
.form-bank-cancel {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 20px;
	max-width: 800px;
	margin: 0 auto;
}
.form-bank-cancel > div {
	margin: 0 !important;
}

@media (max-width: 1440px) {}

@media (max-width: 1366px) {}

@media (max-width: 1024px) {
	.cookie-permission .close {
		align-items: baseline;
		top: 1rem;
	}
	.permission-detail {
		flex: 1 1 auto;
		margin-bottom: 20px;
	}
	.cookie-permission .col-sm-auto {
		display: flex;
		width: 100%;
	}
	.cookie-permission .col-sm-auto .row {
		margin: 0 auto;
	}
	section.footer .contact-form .form-msg {
		font-size: 14px;
		margin-bottom: 0.75rem;
	}
	.app-download img {
		width: 140px;
	}
	.medical-dpt {
		margin: 0 44px 16px 0;
	}
	.doctor-list.max-width-820 {
		margin-right: calc(-0.5 * var(--bs-gutter-x));
		margin-left: calc(-0.5 * var(--bs-gutter-x));
	}
	.modal-terms {
		--bs-modal-width: 500px;
	}
	.modal-dialog.gallery {
		max-width: 95%;
	}
}

@media (max-width: 991px) {
	.container, .container-md, .container-sm {
		max-width: 800px;
	}
	.modal-open .navbar {
		padding-right: 0;
	}
	.navbar .navbar-brand, .navbar-button .nav-item.sign-in {
		margin: 0;
	}
	.navbar-nav, .canvas-menu .nav {
		flex-basis: 216px;
		flex-direction: row;
		justify-content: flex-end;
	}
	.navbar-toggler {
		flex-basis: 216px;
		padding: 0;
		border: none;
		text-align: left;
	}
	.navbar {
		padding: 4px 0;
	}
	.navbar .logo {
		height: 50px;
		margin: 3px 0;
	}
	.navbar-button .nav-item {
		margin-left: 1rem;
	}
	.offcanvas-body .navbar-button .nav-item.sign-in .btn {
		background-color: $background;
	}
	.canvas-menu .navbar-button .nav-item .btn.icon.account:hover {
		background-color: $surface;
		color: $primary-900;
	}
	.canvas-menu .navbar-button .nav-item .btn.icon:hover {
		background-color: #fff;
		color: $primary-900;
	}
	.canvas-menu .navbar-button h1.navbar-text {
		font-size: 28px;
		color: #fff;
		margin: 0;
		padding: 11.21px 0;
	}
	.canvas-menu .is-switch .navbar-button .container {
		justify-content: flex-start;
	}
	.canvas-menu .is-switch .navbar-button .navbar-toggler {
		flex-basis: 60px;
	}
	.canvas-menu .is-switch .navbar-button .nav {
		flex-basis: auto;
		justify-content: flex-start;
	}
	.canvas-menu .is-switch.is-search .navbar-button .container {
		justify-content: space-between;
	}
	.canvas-menu .is-switch .navbar-button .nav {
		justify-content: center;
	}
	section.main {
		margin-top: 64px;
	}
	section.download .description {
		font-size: 16px;
	}
	section.footer {
		padding: 0 0 20px;
	}
	section.footer h5 {
		margin-bottom: 0;
	}
	section.footer .accordion-button {
		background-color: $primary-900;
		color: #fff;
	}
	section.footer .accordion-item {
		background-color: transparent;
		border-color: rgba(229, 229, 229, 0.25);
	}
	section.footer .accordion-button:not(.collapsed) {
		box-shadow: none;
	}
	section.footer .icon-social a {
		margin-right: 30px;
	}
	.img-service {
		height: 120px;
	}
	.img-service img {
		height: 130px;
	}
	.free-program {
		margin-bottom: 0.75rem;
	}
	.free-program h1 {
		font-size: 28px;
	}
	.block-home {
		padding-top: 80px;
		padding-bottom: 80px;
	}
	.block-home .service {
		margin-bottom: 100px;
	}
	.block-home .service h3 {
		font-size: 20px;
	}
	.block-home .service p {
		font-size: 12px;
		line-height: 16px;
	}
	.block-home .medical-services-title {
		font-size: 32px;
	}
	.block-home .medical-services-desc {
		font-size: 16px;
		max-width: 550px;
		margin: 0.75em auto 65px;
		line-height: 24px;
	}
	.block-home .view-more {
		font-size: 16px;
		text-decoration: none;
	}
	.consult-program.pagination, .consult-anytime {
		margin-bottom: 100px;
	}
	.consult-anytime img {
		width: 190px;
	}
	.consult-anytime img.img-mobile-app {
		width: 100%;
	}
	.consult-program .img-wrap {
		max-height: 120px;
	}
	.consult-program .img-wrap img {
		height: 120px;
	}
	.consult-program.consult-sm {
		flex-wrap: unset;
		overflow: hidden;
		overflow-x: auto;
		height: 200px;
		margin-bottom: 1rem;
	}
	.consult-program.consult-sm > * {
		width: 262px;
		padding-right: 4px;
	}
	.consult-program.consult-sm .card .card-body {
		padding: 7px 8px 12px;
	}
	.fix-design.cookie .modal-header {
		padding: 25px 20px 8px;
	}
	.fix-design.cookie .modal-body {
		padding: 1rem 20px 20px;
	}
	.fix-design.cookie .action {
		margin: 20px 0 0 !important;
	}
	.cookie-permission {
		padding: 25px 60px;
	}
	.cookies .accordion-button .row {
		margin: 0;
	}
	.doctor-list {
		margin-bottom: 100px;
	}
	.doctor-list .card {
		padding: 14px 8px 8px;
	}
	.doctor-list .card.show {
		padding: 14px 8px 6px;
	}
	.doctor-list .card .card-detail .fev-star {
		top: 8px;
		right: 8px;
	}
	.doctor-list .card .card-detail .fev-star div:first-child {
		margin-right: 5px;
	}
	.doctor-list .card .card-detail h3 {
		font-size: 16px;
		margin-bottom: 2px;
	}
	.doctor-list .card .card-detail .text-secondary-200 svg {
		margin-right: 5px;
	}
	.doctor-list .card .card-detail h3 img {
		width: 16px;
	}
	.doctor-list .card .card-detail .text-secondary-200 strong {
		font-size: 12px;
	}
	.doctor-list .card .card-detail > .row .col:first-child {
		padding-right: 0;
	}
	.doctor-list .card .card-detail > .row .col:last-child {
		padding-left: 0;
	}
	.doctor-list .card .card-action {
		font-size: 12px;
		padding: 8px 0 0;
	}
	.doctor-list .card.show .card-action {
		padding: 6px 0 0;
	}
	.doctor-img {
		width: 52px;
		height: 52px;
		margin-bottom: 6px;
	}
	.doctor-list .card-action .consult-text .col svg {
		width: 14px;
		margin-right: 0;
	}
	.doctor-list .card.show .card-action .consult-btn .btn {
		padding: 0 0.5rem;
	}
	.consult-anytime p, .h-promotion p {
		font-size: 16px;
		line-height: 24px;
	}
	.consult-anytime .icon-download {
		justify-content: center;
		margin: 0;
	}
	.consult-anytime .icon-download .col-6:first-child {
		margin-bottom: 10px;
	}
	.h-promotion > div:last-child {
		display: none;
	}
	.h-promotion.consult-program.consult-sm {
		flex-wrap: wrap;
		overflow: hidden;
		height: auto;
	}
	.h-promotion.consult-program.consult-sm > * {
		width: 33.33333333%;
		padding-right: calc(var(--bs-gutter-x) * 0.5);
	}
	.h-promotion.consult-program .img-wrap {
		max-height: 168px;
	}
	.h-promotion.consult-program .img-wrap img {
		height: 168px;
	}
	.h-promotion p {
		padding-right: 0;
	}
	.h-promotion .action {
		margin-top: 1rem;
	}
	.offcanvas.canvas-menu, .canvas-menu .navbar {
		background-color: $primary-700;
		color: #FFFFFF;
		width: 100%;
	}
	.canvas-menu .offcanvas-body {
		padding: 0;
	}
	.canvas-menu .navbar {
		padding: 10px 0;
		box-shadow: none;
		list-style: none;
	}
	.canvas-menu .search {
		margin-top: 68px;
		padding: 1rem calc(var(--bs-gutter-x) * 0.5) 1rem;
	}
	.canvas-menu .is-switch .search {
		margin: 0;
		padding: 8px calc(var(--bs-gutter-x) * 0.5) 8px;
		width: 570px;
	}
	.canvas-menu .offcanvas-body > .container {
		max-width: 590px;
	}
	.canvas-menu .offcanvas-body.is-search > .container {
		max-width: 700px;
	}
	.canvas-menu .search .text-search {
		border-radius: 10px;
		text-align: center;
	}
	.canvas-menu .navbar-nav {
		flex-direction: column;
	}
	.canvas-menu hr {
		margin: 0;
		opacity: 0.7;
	}
	.canvas-menu .navbar-nav .nav-item {
		color: #FFFFFF;
		text-align: center;
		padding: 1rem 0;
	}
	.canvas-menu .is-switch .navbar-nav .nav-item {
		font-size: 20px;
		text-align: left;
	}
	.canvas-menu .is-switch .navbar-nav .nav-item .checkmark-radio {
		border-color: $secondary-200;
	}
	.canvas-menu .is-switch .navbar-nav .nav-item .form-check-label input:checked ~ .checkmark-radio.check {
		background-color: $secondary-200;
	}
	.canvas-menu .navbar-nav.setting .navbar-text {
		font-size: 20px;
		color: $secondary-200;
		padding: 20px 0 1rem;
	}
	.canvas-menu .navbar-nav.setting .nav-item {
		padding: 1rem;
		text-align: left;
	}
	.canvas-menu .navbar-nav.setting .nav-item .row {
		align-items: center;
	}
	.canvas-menu .navbar-nav.setting .nav-item .row > div:first-child {
		padding-right: 1rem;
	}
	.canvas-menu .navbar-nav.setting .nav-item .row .col {
		padding-left: 0;
	}
	.modal-custom.sign-in, .modal-custom.register {
		max-width: 630px;
		margin: 0.75rem auto;
	}
	.modal-custom.sign-in .block-form, .modal-custom.forgot-password .block-form, .modal-custom .block-form {
		padding: 25px 16px 20px;
		width: 100%;
		flex: auto;
	}
	.modal-custom .block-form.step-1, .modal-custom.forgot-password .block-form {
		min-height: 510px;
	}
	.modal-custom.sign-in .block-banner .logo-icon, .modal-custom .block-banner .logo-icon {
		background-image: url(../images/logo-icon-white-2.svg);
		height: auto;
		width: 230px;
		right: 0;
		top: 0;
		left: auto;
		background-size: 230px;
	}
	.modal-custom.sign-in .block-banner, .modal-custom .block-banner {
		border-radius: 0 0 20px 20px;
		padding: 24px 20px;
		max-width: 650px;
	}
	.modal-custom.sign-in .block-banner .action-login, .modal-custom .block-banner .action-login {
		padding: 0;
	}
	.modal-custom .block-banner .action-login button {
		justify-self: center;
		max-width: 300px;
	}
	.block-assessment, .assessments, .event, .drug-payment {
		padding-top: 40px;
		margin-bottom: 60px;
	}
	.drug-payment {
		padding-top: 32px;
	}
	.block-assessment .assessment {
		padding-left: 10px;
	}
	.block-assessment .assessment, .block-assessment .insurance {
		width: 100%;
	}
	.block-assessment .insurance {
		padding-right: 8px;
		background-position: -30px;
	}
	.block-assessment .assessment div h3, .block-assessment .insurance div h3 {
		font-size: 24px;
		margin-bottom: 0;
	}
	.block-assessment .assessment div p, .block-assessment .insurance div p {
		font-size: 12px;
	}
	.filter-doctor .dropdown-toggle, .filter-doctor .dropdown-toggle:active {
		font-size: 12px;
		min-width: auto;
		margin-left: 8px;
		padding: 8px 14px 8px 10px;
	}
	.filter-doctor .dropdown-toggle span {
		padding-right: 20px;
	}
	.filter-doctor .dropdown-toggle span.select {
		font-size: 12px;
		padding: 3px 26px 3px 8px;
	}
	.filter-doctor .dropdown-toggle::after {
		top: 14px;
	}
	.filter-doctor .tag-event {
		font-size: 12px;
		min-width: auto;
		padding: 12px 40px 12px 14px;
	}
	.health-record .filter-doctor .dropdown-toggle, .health-record .filter-doctor .dropdown-toggle:active {
		font-size: 16px;
	}
	.health-record .filter-doctor .dropdown-toggle span.select {
		padding: 3px 26px 3px 8px;
	}
	.record .card {
		margin-bottom: 60px;
	}
	.doctor-profile .card {
		padding: 1rem;
	}
	.doctor-profile img.avatar {
		width: 200px;
		height: 200px;
		margin: 0;
	}
	.doctor-profile .fev-star {
		margin-bottom: 0.5rem;
	}
	.doctor-profile .fev-star .score {
		font-size: 20px;
	}
	.doctor-profile .fev-star > div:first-child div span {
		font-size: 18px !important;
	}
	.doctor-profile .fev-star > div:last-child svg {
		width: 22px;
		height: 22px;
	}
	.doctor-profile .dr-name {
		margin-bottom: 0.25rem;
	}
	.doctor-profile h1 {
		font-size: 28px;
	}
	.doctor-profile .icon-insure {
		width: 28px;
	}
	.doctor-profile .doc-desc .hospital {
		margin-bottom: 0.5rem;
	}
	.doctor-profile .doc-desc .education {
		margin-bottom: 1rem;
	}
	.doctor-profile .dr-price {
		font-size: 12px;
		padding-top: 30px;
	}
	.doctor-profile .bage-price {
		font-size: 16px;
	}
	.doctor-profile .action button {
		font-size: 16px;
		min-width: 200px;
		padding: 5px;
	}
	.doctor-profile .action button svg {
		width: 21px;
		height: 21px;
	}
	.doctor-profile .doc-desc .specialty {
		margin-bottom: 1rem;
	}
	.list-reviews {
		padding: 8px 0;
	}
	.assessments .banner {
		background-size: 450px;
		background-position: top 0 right -100px;
		padding: 26px 0 20px 20px;
	}
	.assessments .banner div h3 {
		font-size: 32px;
		margin-bottom: 0.5rem;
	}
	.assessments .banner div p {
		font-size: 16px;
	}
	.assessments .banner div {
		width: 520px;
	}
	.commen-problems .badge {
		font-size: 16px;
		margin: 0 8px 12px 0;
	}
	.event .banner {
		background-position: top 0 right -40px;
	}
	.event .banner h3 {
		font-size: 32px;
	}
	.alert-appointment h4 {
		font-size: 20px;
	}
	.alert-appointment .booking-ref {
		margin-right: 1rem;
	}
	.alert-appointment .booking-detail {
		padding: 6px 0;
	}
	.alert-appointment .booking-detail .row:first-child {
		margin-bottom: 12px;
	}
	.alert-appointment .booking-detail .row:first-child .col-auto {
		padding-right: 0;
	}
	.alert-appointment .booking-detail .row:last-child {
		font-size: 12px;
		line-height: 15.6px;
	}
	.container.setting {
		margin-top: 100px;
	}
	.setting .tab-content.terms .tab-pane {
		padding: 1.5rem 0;
	}
	.d-menu-small {
		margin: 32px 0;
	}
	.account .d-menu-small {
		margin: 20px 0 32px;
	}
	.account .d-menu-small .btn {
		padding: 15px 1rem;
	}
	.account .d-menu-small .btn:first-child:active, .account .d-menu-small button:hover {
		border-color: $line-gray;
	}
	.d-menu-small.show .btn {
		border-radius: 10px 10px 0 0;
		box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
		border: none;
	}
	.d-menu-small .dropdown-toggle span:first-child {
		margin-top: -2px;
	}
	.d-menu-small .dropdown-toggle::after {
		position: initial;
		background-image: url(../images/arrow-down-light-blue.svg);
		background-size: 24px;
		width: 24px;
		height: 24px;
		margin-left: 1rem;
	}
	.d-menu-small .dropdown-menu {
		width: 100%;
		border-radius: 0 0 10px 10px;
		border: none;
		padding: 0;
		box-shadow: 0 8px 10px rgba(0, 0, 0, 0.25);
	}
	.d-menu-small .dropdown-menu .dropdown-item {
		display: inline-block;
		border-radius: 0;
		border: none;
	}
	.d-menu-small .dropdown-menu .dropdown-item.active {
		font-weight: bold;
		color: $primary;
	}
	.d-menu-small .dropdown-menu .dropdown-item:last-child {
		border-radius: 0 0 10px 10px;
	}
	.d-menu-small .dropdown-divider {
		border-color: $line-gray;
		margin: 0;
	}
	.account .header {
		margin-top: 104px;
	}
	.canvas-menu hr.line-switch {
		margin: 68px 0 1rem;
	}
	.basis {
		flex-basis: 60px;
	}
	.canvas-menu .is-search .dropdown .btn {
		background-color: $surface;
		color: $primary-900;
		border-radius: 10px;
		width: 100%;
		padding: 4px 8px;
		border: none;
	}
	.canvas-menu .is-search .dropdown-menu {
		width: 100%;
		max-height: 500px;
	}
	.contact-res .btn-w {
		min-width: 200px;
	}
	.health-record .tab-pane.document, .history .tab-pane.consultation, .history .tab-pane.drug {
		padding: 1.5rem 0;
	}
	.health-record .tab-pane.document .empty, .health-record .tab-pane.health .empty {
		margin-top: 140px;
	}
	.map, .canvas-address {
		margin-top: 68px;
	}
	.modal .map {
		padding-top: 68px;
	}
	.consult img.avatar {
		width: 75px;
		height: 75px;
		margin: 0 auto 1rem;
	}
	.consult h3.name {
		font-size: 20px;
	}
	.consult .check-have {
		padding-left: 35px;
	}
	.g-special {
		font-size: 12px;
	}
	.consult-time {
		display: block;
	}
	.btn-wm-400 {
		width: 100%;
		min-width: auto;
	}
	.use-insure-detail {
		font-size: 12px;
		line-height: 15.6px;
	}
	.font-md-12 {
		font-size: 12px;
	}
	.block-payment .payment-icon {
		width: 55px;
	}
	.block-payment .payment-icon svg, .block-payment .payment-icon img {
		width: 45px;
		height: 45px;
	}
	.block-payment .form-check-label .radio-text {
		padding-left: 10px;
	}
	ol.wizard {
		margin: 0;
		padding: 32px 0;
	}
	ol.wizard li {
		font-size: 15px;
	}
	ol.wizard li:before {
		font-size: 28px;
		width: 48px;
		height: 48px;
		line-height: 42px;
		margin-left: -24px;
	}
	ol.wizard li + li:after {
		top: 23px;
	}
	.block-attachments div, .block-attachments a {
		width: 99px;
		height: 99px;
		border-radius: 10px;
	}
	.payment .block-status {
		margin-top: 100px;
		padding: 24px;
	}
	.payment .block-status svg {
		margin-bottom: 1rem;
		width: 74px;
		height: 74px;
	}
	.link-his .col > span:first-child {
		margin-right: 1.5rem;
	}
	.video .menu-bar {
		padding: 14px 20px;
	}
	.video .menu-bar .name {
		margin-left: 1.5rem;
	}
	.video .menu-bar .end-call {
		margin: 0 12px;
	}
	.video .menu-bar .mic svg, .video .menu-bar .camera svg {
		width: 20px;
		height: 20px;
	}
	.block-video {
		padding: 32px 20px;
	}
	.container.telemed-no-response {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		margin-bottom: 80px;
	}
	.telemed-no-response .action {
		margin-top: 137px;
	}
	.account .tab-pane h1.title.icon-left > span {
		margin-right: 18px;
	}
	.history .action button {
		min-width: 180px;
	}
	.history .card.list .consult-no {
		font-size: 12px;
	}
	.history .card.list .consult-status-text {
		width: 110px;
	}
	.history .drug .card.list .consult-status-text {
		width: 120px;
	}
	.history .card.list h4.name {
		font-size: 20px;
	}
	.history .drug .card.list .h4 {
		font-size: 16px;
	}
	.history .card.list .consult-status {
		font-size: 12px;
		padding: 0.35rem 0.6rem;
	}
	.history .card.list table td button {
		min-width: 150px;
	}
	.history .card.list .consult-view {
		width: 150px;
	}
	.calling {
		min-width: auto;
	}
	.calling .modal-body {
		padding: 24px 1rem;
	}
	.map .address-secleced .container > div:last-child {
		font-size: 12px;
		line-height: 19px;
	}
	.font-size {
		line-height: 24px;
		padding: 20px 0 1rem;
	}
	.font-size .label {
		font-size: 20px;
		color: $secondary-200;
		margin-bottom: 0.5rem;
	}
	.font-size .size {
		justify-content: center;
		gap: 2rem;
		margin-bottom: 1rem;
	}
	.share-screen {
		display: none;
	}
	.block-home .landing {
		padding: 0 0 67px;
	}
	.block-home .landing .box-text {
		max-width: 365px;
	}
	.block-home .landing .box-text p {
		margin: 0;
	}
	.block-home .landing .box-image {
		width: 380px;
		margin: 0;
	}
	.block-home .landing .box-image img {
		margin-bottom: 33px;
	}
	.block-home .landing .box-search, .search-bar .box-search {
		bottom: 0;
	}
	.block-home .landing .box-search .react-datepicker-wrapper, .search-bar .box-search .react-datepicker-wrapper {
		display: none;
	}
	.block-home .landing .box-search .btn-search, .search-bar .box-search .btn-search {
		min-width: 128px;
		padding: 0;
	}
	.block-home .landing .box-search .filter, .search-bar .box-search .filter, .block-nursing .box-search .filter {
		padding: 7px 10px;
		border: 1px solid $line-gray;
		color: $primary;
	}
	.health-record img {
		width: 100%;
	}
	.card-news img {
		height: 120px;
	}
	.nursing-top .description {
		max-width: 750px;
	}
	.nursing-top .description h2 {
		font-size: 28px;
	}
	.block-nursing .box-search {
		display: flex;
		gap: 1rem;
	}
	.block-nursing .box-search .filter, .search-bar .box-search.nursing .filter {
		min-width: 120px;
	}
	.block-nursing .block-list > div:first-child {
		display: none;
	}
	.block-nursing .block-list > div:last-child h3.no-data {
		font-size: 24px;
	}
	.images-overview {
		flex-direction: column;
	}
	.images-overview .img-sm {
		width: 100%;
	}
	.images-overview .img-sm > div {
		display: flex;
		gap: 8px;
	}
	.images-overview .img-sm > div > div {
		margin: 0;
	}
	.images-overview .img-sm > div > div, .images-overview .img-sm > div > div img {
		width: 100%;
		height: 100%;
		aspect-ratio: 1 / 1;
	}
	.block-nursing-detail .title {
		display: block;
		flex-direction: column;
		gap: 16px;
	}
	.block-nursing-detail .title > div:first-child {
		margin-bottom: 20px;
	}
	.block-nursing-detail .title > div:last-child {
		justify-content: flex-end;
	}
	.block-nursing-detail .sevices, .block-room-style .detail .block-1 {
		flex-wrap: wrap;
	}
	.block-nursing-detail .sevices > div {
		flex: 1 0 auto;
		width: 48.5%;
		min-height: 248px;
	}
	.block-room-style .img-1 {
		width: 280px;
		height: 280px;
	}
	.block-room-style .img-list > div {
		width: 67px;
		height: 67px;
	}
	.block-room-style .img-list > div:first-child > img {
		border-radius: 0;
	}
	.block-room-style .detail .block-1 > div:last-child {
		flex: 1 0 auto;
		justify-content: flex-start;
	}
	.block-room-style .detail .block-2 {
		display: block;
	}
	.block-room-style .detail .block-2 .have-price {
		margin-bottom: 20px;
	}
	.block-room-style .detail .block-2 > div:last-child .have-price {
		margin: 0;
	}
	.block-room-style .action {
		position: unset;
		flex: 1;
	}
	.block-check-info {
		flex-direction: column;
	}
	.block-check-info > div:first-child {
		width: 100%;
	}
	.block-payment-info .room-info {
		margin-bottom: 20px;
	}
	.nhb-detail .addr {
		padding: 0;
	}
}

@media (max-width: 912px) {
	.medical-dpt {
		margin: 0 4px 16px 0;
	}
}

@media (max-width: 820px) {}

@media (max-width: 768px) {
	.container, .container-md, .container-sm {
		max-width: 740px;
	}
	.block-home .service h3 {
		font-size: 17px;
	}
	.cookie-permission {
		padding: 25px 30px;
	}
	.doctor-list .card .card-detail h3 {
		font-size: 14px;
	}
	.doctor-list .card .card-action {
		font-size: 11px;
	}
	.consult-anytime .col {
		padding-left: 0;
	}
	.medical-dpt {
		width: 179px;
		margin: 0 0px 16px 0;
	}
	.consult .check-have {
		padding-left: 20px;
	}
	.block-home .landing .box-image {
		width: 330px;
	}
	.history .card.list.nhc > div {
		display: flex;
		flex-direction: column;
		font-size: 12px;
		gap: 8px;
	}
	.history .card.list.nhc > div > div {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}
	.nhb-detail .block-payment-info .room-info > div {
		flex-direction: column;
		gap: 8px;
	}
}

@media (max-width: 430px) {
	.navbar {
		padding: 10px 0;
	}
	.navbar-toggler, .canvas-menu .nav {
		flex-basis: 108px;
	}
	.navbar .logo {
		height: 36px;
		margin: 2px;
	}
	section.main {
		margin-top: 60px;
	}
	.navbar-button.navbar-nav {
		flex-basis: 117px;
		justify-content: end;
	}
	.modal-open .navbar {
		padding-right: 0;
	}
	section.download {
		padding: 16px 0;
	}
	section.download .description {
		font-size: 14px;
	}
	section.download a, .guest .accordion-collapse > div button, .timeline-status button {
		min-width: auto;
		width: 100%;
	}
	section.footer {
		padding: 0 0 20px;
	}
	section.footer h5 {
		margin-bottom: 0;
	}
	section.footer .accordion-button {
		background-color: $primary-900;
		color: #fff;
	}
	section.footer .accordion-item {
		background-color: transparent;
		border-color: rgba(229, 229, 229, 0.25);
	}
	section.footer .accordion-button:not(.collapsed) {
		box-shadow: none;
	}
	section.footer .icon-download img {
		width: 100%;
	}
	.block-home {
		padding: 60px calc(var(--bs-gutter-x) * 0.5) 60px;
	}
	.block-home .medical-services-title {
		font-size: 24px;
	}
	.block-home .medical-services-desc {
		margin: 0.5em auto 60px;
	}
	.block-home .service, .block-home .fix-row-sm {
		margin: 0;
	}
	.carousel-indicators {
		margin-bottom: 0;
	}
	.carousel-control-next-icon, .carousel-control-prev-icon {
		width: 1rem;
		height: 1rem;
	}
	.modal-custom.sign-in, .modal-custom.register {
		margin: 0.75rem;
	}
	.modal-custom.sign-in .block-form, .modal-custom .block-form, .modal-custom.forgot-password .block-form {
		padding: 25px 16px 50px;
		min-height: auto;
	}
	.modal-custom.sign-in .block-banner .logo-icon, .modal-custom .block-banner .logo-icon {
		width: 170px;
		background-size: 170px;
	}
	.modal-custom.sign-in .block-banner, .modal-custom .block-banner {
		padding: 20px 16px;
	}
	.modal-custom.sign-in .block-banner .action-login, .modal-custom .block-banner .action-login {
		padding: 0;
	}
	.modal-custom.sign-in .block-banner .action-login h3, .modal-custom .block-banner .action-login h3 {
		font-size: 16px;
		margin-bottom: 6px !important;
	}
	.modal-custom .block-banner .action-login button {
		max-width: none;
	}
	.modal-custom.sign-in .block-banner .action-login p, .modal-custom .block-banner .action-login p {
		font-size: 12px;
		margin-bottom: 9px !important;
		line-height: 15.6px;
	}
	.modal-custom .block-form .position-relative {
		padding: 1rem 0;
	}
	.modal-custom .block-form .row .col.px-5 {
		padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
		padding-left: calc(var(--bs-gutter-x) * 0.5) !important;
	}
	.modal-custom .block-form .otp input, .otp input {
		width: 45px !important;
		height: 50px;
	}
	.modal-custom .block-form .bg-logo-left {
		background-size: 200px;
		width: 200px;
		height: 250px;
	}
	.modal-custom .block-form .bg-logo-right {
		background-size: 120px;
		width: 120px;
		height: 150px;
	}
	.login-with {
		padding: 0 !important;
	}
	.login-with span {
		margin: 0 10px;
	}
	.navbar-button .nav-item {
		margin-left: 7px;
	}
	.navbar-button .nav-item .btn {
		min-width: 93px;
	}
	.navbar-button .nav-item .btn.icon {
		width: 32px;
		height: 32px;
	}
	.navbar-button .nav-item .btn.icon.account {
		padding: 0;
		width: 32px;
		border: none;
		min-width: auto;
	}
	.navbar-button .nav-item .btn.icon.account:hover .account-icon {
		border-radius: 50%;
	}
	.navbar-button .nav-item .btn.icon:hover {
		color: $primary-900;
	}
	.navbar-button .nav-item.dropdown .dropdown-menu, .consult .timezone .dropdown-menu {
		position: fixed !important;
		top: 60px !important;
		left: 50% !important;
		transform: translate(-50%, 0) !important;
		width: 396px;
		max-height: calc(100% - 150px);
	}
	.Toastify__toast-container {
		width: 96%;
		margin: 0 auto;
		top: 1rem;
		right: 0;
		left: 0;
	}
	.font-sm-12 {
		font-size: 12px;
	}
	.font-sm-32 {
		font-size: 32px;
	}
	.cookie-permission {
		font-size: 12px;
		padding: 2.5rem 0 1rem;
	}
	.permission-detail {
		line-height: 19px;
		margin-bottom: 0.5rem;
	}
	.cookie-permission .col-sm-auto .row {
		margin-top: calc(-1 * var(--bs-gutter-y));
		margin-right: calc(-0.5 * var(--bs-gutter-x));
		margin-left: calc(-0.5 * var(--bs-gutter-x));
	}
	.fix-design.cookie .modal-header .modal-title {
		font-size: 24px;
	}
	.fix-design.cookie .modal-header {
		padding: 20px 1rem 8px;
	}
	.fix-design.cookie .modal-header .modal-title > div {
		font-size: 12px;
	}
	.fix-design.cookie .modal-header .btn-close {
		right: 1rem;
	}
	.fix-design.cookie .modal-body {
		padding: 1rem 1rem 20px;
	}
	.fix-design.cookie .action {
		margin: 1rem 0 0 !important;
	}
	.fix-design.cookie .action .col {
		padding: 0;
	}
	.cookies .accordion-button::before {
		background-size: 14px;
	}
	.cookies .accordion-button .row .col-auto {
		padding: 0;
	}
	.cookies .accordion-button h5 {
		font-size: 1rem;
	}
	.cookies .accordion-button::before {
		margin-right: 10px;
	}
	.cookies .accordion-body {
		padding: 8px;
	}
	.text-open-alway {
		font-size: 12px;
		width: 55px;
		text-align: left;
		top: 2rem;
	}
	.img-service {
		height: 100px;
		width: 100px;
	}
	.img-service img {
		height: 100px;
	}
	.block-home .service {
		align-items: center;
		margin: 0 0 1rem;
		text-align: left;
	}
	.block-home .service h3 {
		font-size: 20px;
		margin: 0 0 4px;
	}
	.block-home .service p {
		margin: 0;
	}
	.mt-sm-80 {
		margin-top: 80px;
	}
	.doctor-list, .consult-anytime {
		margin-bottom: 80px;
	}
	.doctor-list .card {
		padding: 1rem;
		margin-bottom: 20px;
	}
	.doctor-list .card.show {
		padding: 1rem 1rem 0.646rem;
	}
	.doctor-img {
		width: 72px;
		height: 72px;
		margin-bottom: 10px;
	}
	.doctor-list .card .card-detail .fev-star div:first-child {
		margin-right: 0.5rem;
	}
	.doctor-list .card .card-detail h3 {
		font-size: 20px;
		margin-bottom: 0.25rem;
	}
	.doctor-list .card .card-detail .text-secondary-200 svg {
		margin-right: 0.5rem;
	}
	.doctor-list .card .card-detail h3 img {
		width: 24px;
	}
	.doctor-list .card .card-detail .text-secondary-200 strong {
		font-size: 16px;
	}
	.doctor-list .card .card-action {
		font-size: 16px;
		padding: 1rem 0 0;
	}
	.doctor-list .card.show .card-action {
		padding: 0.605rem 0 0;
	}
	.doctor-list .card-action .consult-text .col svg {
		width: 21px;
		margin-right: 0.5rem;
	}
	.doctor-list .card.show .card-action .consult-btn .btn {
		padding: 0.25rem 0.5rem;
	}
	.block-home .view-more {
		font-size: 12px;
	}
	.consult-program.pagination {
		margin-bottom: 80px;
	}
	.consult-anytime img.img-mobile-app {
		padding: 0 50px;
	}
	.consult-anytime {
		text-align: center;
	}
	.consult-anytime h1 {
		margin-top: 20px;
	}
	.consult-anytime .icon-download .col-6 {
		width: 50%;
		padding: 0 0.5rem 0 0;
	}
	.consult-anytime .icon-download .col-6:first-child {
		margin-bottom: 0;
	}
	.consult-anytime .icon-download .col-6:last-child {
		padding: 0 0 0 0.5rem;
	}
	.consult-anytime img {
		width: 183px;
	}
	.consult-anytime .col {
		padding-left: calc(var(--bs-gutter-x) * 0.5);
	}
	.h-promotion .action {
		text-align: left;
		margin: 1rem 0 30px 0;
	}
	.h-promotion.consult-program.consult-sm {
		flex-wrap: unset;
		overflow-x: auto;
		height: 240px;
	}
	.h-promotion.consult-program.consult-sm > * {
		width: 262px;
		padding-right: 4px;
	}
	.h-promotion > div:last-child {
		display: block;
	}
	.block-assessment .assessment {
		margin-bottom: 1rem;
	}
	.list-department {
		overflow: hidden;
		height: 310px;
		margin-bottom: 24px;
	}
	.list-department.show {
		height: auto;
		margin-bottom: 80px;
	}
	.department-viewmore {
		display: block;
		text-align: center;
		color: $secondary-200;
		margin-bottom: 80px;
		cursor: pointer;
	}
	.d-sm-inline-block {
		display: inline-block;
	}
	.filter-doctor .dropdown-toggle, .filter-doctor .dropdown-toggle:active {
		display: flex;
		align-items: center;
		font-size: 16px;
		padding: 7px 20px 7px 8px;
		margin-left: 12px;
		width: 190px;
	}
	.filter-doctor .dropdown-toggle span.icon {
		display: inline-block;
		background-color: $primary-900;
		color: #FFFFFF;
		width: 24px;
		height: 24px;
		border-radius: 50%;
		padding: 0px 6px;
		margin-right: 8px;
	}
	.filter-doctor .dropdown-toggle span.icon svg {
		margin-top: -3px;
	}
	.filter-doctor .dropdown-toggle span.select {
		margin-right: -11px;
		padding: 3px 30px 3px 8px;
	}
	.filter-doctor .dropdown-toggle::after {
		top: 16px;
	}
	.filter-doctor .tag-event {
		margin: 0;
	}
	.filter-doctor .dropdown.show .dropdown-toggle {
		border-radius: 20px 20px 0 0;
		border-bottom: none;
	}
	.filter-doctor .dropdown-menu.show {
		font-size: 12px;
		border-radius: 0 0 20px 20px;
		top: -1px !important;
		border-top: none;
		width: 190px;
		min-width: auto;
		padding-top: 0;
	}
	.filter-doctor .dropdown-header {
		color: $primary-900;
		border-bottom: 1px solid $line-gray;
		margin: 0 12px 4px;
		padding: 8px 0;
	}
	.filter-doctor .dropdown-item {
		padding: 6px 12px;
		line-height: 15.6px;
	}
	.filter-doctor .dropdown-menu .checkmark-radio {
		top: -2px;
		width: 18.3px;
		height: 18.3px;
	}
	.filter-doctor .dropdown-menu .form-check-label .checkmark-radio.check::after {
		left: 4.5px;
		top: 1px;
		width: 5px;
		height: 10px;
	}
	.health-record .filter-doctor .dropdown-menu.show {
		font-size: 16px;
		inset: auto 0 auto auto !important;
	}
	.health-record .filter-doctor .dropdown-item {
		padding: 10px 1rem;
	}
	.record .card {
		margin-bottom: 30px;
	}
	.value-status {
		font-size: 12px;
	}
	.doctor-profile .doc-pic {
		padding-top: 40px;
	}
	.doctor-profile .doc-desc {
		font-size: 12px;
		flex: 0 0 auto;
		width: 66.66666667%;
	}
	.doctor-profile img.avatar {
		width: 100px;
		height: 100px;
	}
	.doctor-profile h1 {
		font-size: 20px;
	}
	.doctor-profile .icon-insure {
		width: 20px;
	}
	.doctor-profile .b-header {
		font-size: 12px;
	}
	.doctor-profile .action button {
		min-width: 155px;
	}
	.doctor-profile .action button:first-child {
		margin-right: 8px;
	}
	.doctor-profile .action button:last-child {
		margin-left: 8px;
	}
	.doctor-profile .doc-desc .specialty, .doctor-profile .doc-desc .hospital {
		margin-bottom: 0.25rem;
	}
	.doctor-profile .doc-desc .education {
		margin-bottom: 12px
	}
	.doctor-profile .dr-price {
		display: block;
		width: 100%;
		padding-top: 24px;
	}
	.doctor-profile .dr-price div {
		justify-content: flex-end;
	}
	.doctor-profile .nav-tabs {
		margin-bottom: 20px;
		padding: 8px 0;
	}
	.doctor-profile .nav-tabs .nav-link {
		font-size: 12px;
		padding: 8px 0;
		margin: 0;
	}
	.doctor-profile .nav-item {
		flex-basis: 0;
		flex-grow: 1;
		text-align: center;
	}
	.doctor-profile .nav-item .nav-link {
		border-right: solid 1px $line-gray;
		border-radius: 0;
	}
	.doctor-profile .nav-tabs .nav-link.active, .doctor-profile .nav-tabs .nav-link:hover {
		background-color: transparent;
		border-right: solid 1px $line-gray;
		color: $primary-900;
		border-radius: 0;
	}
	.doctor-profile .nav-item:last-child .nav-link, .doctor-profile .nav-item:last-child .nav-link.active {
		border: none;
	}
	.assessments .banner {
		background-size: 450px;
		background-position: top 0 right -130px;
		padding: 36px 0 26px 12px;
	}
	.assessments .banner div h3 {
		font-size: 20px;
	}
	.assessments .banner div p {
		font-size: 12px;
		line-height: 15.6px;
	}
	.assessments .banner div {
		width: 210px;
	}
	.list-assessment .assess-item {
		height: 100px;
	}
	.list-assessment .assess-item > div > p {
		font-size: 16px;
		margin-top: 0;
	}
	.list-assessment .assess-item img {
		max-height: 50px;
	}
	.event .banner {
		background-size: 160px;
		background-position: bottom 0 right -45px;
	}
	.event .banner h3 {
		font-size: 20px;
	}
	.event .event-img {
		width: 100%;
	}
	.event .col h3 {
		margin-top: 0.5rem;
	}
	.alert-appointment {
		padding: 14px 0;
		font-size: 12px;
	}
	.alert-appointment .booking-ref .badge {
		font-size: 12px;
		padding: 4px 8px;
	}
	.alert-appointment .booking-ref > * {
		display: inline-block;
		font-size: 12px;
		margin-right: 8px;
	}
	.alert-appointment .booking-detail {
		padding-right: calc(var(--bs-gutter-x) * 0.5);
		padding-left: calc(var(--bs-gutter-x) * 0.5);
	}
	.alert-appointment .booking-detail .row:first-child {
		justify-content: center;
	}
	.alert-appointment .booking-detail .row:first-child .col-auto:first-child {
		padding: 0;
	}
	.alert-appointment .booking-detail img.avatar.avatar-60 {
		width: 52px;
		height: 52px;
	}
	.alert-appointment .btn {
		margin-top: 0.25rem;
	}
	.d-menu-small {
		margin: 24px 0;
	}
	.container.setting {
		margin-top: 92px;
	}
	.setting .tab-pane button, .tab-pane .btn-w, .add-contact .btn-w, .contact-res .btn-w, .btn-w-200, .btn-w-400 {
		width: 100%;
		min-width: auto;
	}
	.contact-res .btn-w, .btn-w-200 {
		font-size: 12px;
	}
	.setting h2 {
		font-size: 24px;
		margin: 0;
	}
	.account .tab-pane h1.title, .setting .tab-pane h1.title {
		font-size: 24px;
	}
	.setting .pad.r1 {
		margin: 3rem 0 0.5rem;
	}
	.setting .pad.r2 {
		margin: 0.5rem 0 3rem;
	}
	.manual h1.title.icon-left > span {
		margin-right: 10px;
	}
	.help .accordion-button, .help .accordion-body {
		padding: 1rem 0;
	}
	.setting .check-have {
		padding-left: 60px;
	}
	.account .header {
		margin-top: 100px;
		background: url(../images/bg-header.svg) no-repeat center right, linear-gradient(90deg, $primary 0%, $secondary 100%);
	}
	.account .header > div {
		font-size: 28px;
		line-height: 36px;
	}
	.account .connection .social {
		margin: 0;
	}
	.account .check-have {
		padding-left: 12px;
	}
	.account .form-check-label span.radio-text, .consult .form-check-label span.radio-text {
		padding: 0 0 0 30px;
	}
	.account .add-contact, .account .close-account, .account .cancel-booking {
		margin: 84px 0 40px;
		padding: 1rem;
	}
	.account .close-account h2 {
		font-size: 20px;
	}
	.account .close-account p {
		font-size: 16px;
	}
	.account .close-account p.detail {
		font-size: 12px;
		line-height: 15.6px;
		margin-bottom: 24px;
	}
	.canvas-menu .is-switch .navbar-button .navbar-toggler {
		flex-basis: 25px;
	}
	.canvas-menu .is-switch .search {
		width: 333px;
	}
	.canvas-menu .is-search .dropdown-menu {
		max-height: 250px;
	}
	.canvas-menu .is-search .btn-search {
		min-width: auto;
		width: 100%;
	}
	.health-record .tab-pane.document .row h5, .cargiver .tab-pane.search .block-form h5 {
		font-size: 16px;
	}
	.health-record .tab-pane.document .empty, .health-record .tab-pane.health .empty {
		margin-top: 60px;
		font-size: 16px;
	}
	.list-coupons .block-coupon {
		background: transparent url(../images/bg-coupon-sm-white.svg) no-repeat left;
		width: auto;
		padding: 1rem 8px 1rem 40px;
		border-radius: 10px;
	}
	.list-coupons .block-coupon .btn {
		min-width: 140px;
	}
	.list-accounts {
		margin-bottom: 100px;
	}
	.list-accounts .account-block {
		padding-bottom: 0.5rem;
	}
	.map, .canvas-address {
		margin-top: 60px;
	}
	.modal .map {
		padding-top: 60px;
	}
	.map .map-overlay .address-confirm {
		padding: 0 1rem;
	}
	.map .drug-address-list {
		bottom: 80px;
	}
	ol.wizard {
		padding: 24px 0;
	}
	ol.wizard li {
		font-size: 12px;
		width: 33%;
		line-height: 18px;
	}
	ol.wizard li:before {
		font-size: 24px;
		width: 40px;
		height: 40px;
		line-height: 35px;
		margin-left: -20px;
	}
	ol.wizard li + li:after {
		top: 19px;
	}
	.consult img.avatar {
		width: 60px;
		height: 60px;
		margin: 0;
	}
	.consult h3.name {
		margin: 0;
	}
	.consult .doc-detail {
		margin-bottom: 20px;
		padding: 8px 1rem;
	}
	.consult .doc-datetime-select {
		padding-top: 1rem;
	}
	.consult .block-group-time {
		margin-bottom: 40px;
	}
	.consult .block-group-time .row {
		margin-right: calc(-0.2 * var(--bs-gutter-x));
		margin-left: calc(-0.2 * var(--bs-gutter-x));
	}
	.consult .block-group-time .row > * {
		padding-right: calc(var(--bs-gutter-x) * 0.2);
		padding-left: calc(var(--bs-gutter-x) * 0.2);
	}
	.input-min-w-400 {
		min-width: auto;
		width: 100%;
	}
	.mt-sm-0 {
		margin-top: 0 !important;
	}
	.consult-time {
		display: inline-block;
	}
	.consult .doc-showtime {
		padding: 8px 1rem;
	}
	.consult .doc-showtime > div:last-child {
		text-align: center;
	}
	.consult .check-have {
		padding-left: 12px;
		padding-right: 0;
	}
	.block-payment .payment-icon {
		width: 70px;
	}
	.block-payment .payment-icon svg, .block-payment .payment-icon img {
		width: 52px;
		height: 52px;
	}
	.consult .block-payment .form-check-label .radio-text {
		padding-left: 20px;
	}
	.block-attachments div.s-52 {
		margin-bottom: 0;
	}
	.consult .block-attachments.list {
		overflow: hidden;
		overflow-x: auto;
		display: inline-flex;
		height: 52px;
		flex-wrap: nowrap;
	}
	.consult .block-attachments.list.none {
		height: auto;
	}
	.modal-coupon .modal-header {
		border: 0;
		padding-bottom: 0;
	}
	.modal-coupon .modal-header .btn-close, .modal-coupon .modal-header .btn-close:focus {
		padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
	}
	.list-coupons .block-coupon.slide {
		width: 100%;
		margin-top: 8px;
		padding: 1rem 8px 1rem 40px;
	}
	.payment .block-status {
		margin-top: 84px;
		padding: 20px;
	}
	.payment .block-status svg {
		margin-bottom: 10px;
	}
	.payment h5.card-title {
		font-size: 16px;
	}
	.link-his {
		margin-bottom: 100px;
		text-align: left;
	}
	.link-his .col > span:last-child {
		margin-top: 1rem;
		text-align: center;
	}
	.video .menu-bar {
		display: flex;
		align-items: center;
		height: 100px;
		padding: 8px;
	}
	.video .menu-bar > .row {
		align-items: center;
		width: 100%;
		margin: 0;
	}
	.video .menu-bar > .row > .col-3 {
		padding: 0;
	}
	.video .menu-bar .end-call {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 8px;
		width: 70px;
		height: 44px;
	}
	.video .menu-bar #period {
		font-size: 16px;
		line-height: 15.6px;
	}
	.block-video {
		height: calc(100% - 100px);
		padding: 0;
	}
	.video .menu-bar .mic, .video .menu-bar .camera {
		width: 44px;
		height: 44px;
	}
	.container.review {
		margin-top: 80px;
	}
	.review .star span {
		font-size: 40px !important;
	}
	.block-waiting-period {
		height: calc(100% - 100px);
	}
	.block-waiting-period > div {
		width: 100%;
	}
	.block-waiting-period .doc-img {
		width: 120px;
		height: 120px;
		margin-bottom: 20px;
	}
	.block-waiting-period .name {
		font-size: 20px;
		margin-bottom: 0.5rem;
	}
	.block-waiting-period > div > div:last-child img {
		width: 80%;
	}
	.share-screen {
		margin-right: 8px;
		padding: 0;
	}
	.share-screen svg, .icon-chat {
		width: 27px;
		height: 27px;
	}
	.container.telemed-no-response {
		margin-bottom: 50px;
	}
	.telemed-no-response .desc {
		padding: 1rem 8px;
	}
	.telemed-no-response .desc p {
		margin: 0;
	}
	.telemed-no-response .block {
		font-size: 14px;
	}
	.telemed-no-response .action {
		margin-top: 48px;
	}
	.telemed-no-response .action .icon {
		width: 60px;
		height: 60px;
	}
	.telemed-no-response .action .icon svg {
		width: 30px;
		height: 30px;
	}
	.telemed-no-response .action .icon.videocam svg, .telemed-no-response .action .icon.change svg {
		width: 35px;
		height: 35px;
	}
	.history .card.list .consult-no {
		font-size: 14px;
		padding-bottom: 0;
	}
	.history .card.list .consult-no > span {
		margin: 0;
	}
	.history .card.list .consult-status-text {
		width: auto;
		padding-bottom: 0;
	}
	.history .card.list .consult-view {
		width: auto;
		padding-bottom: 0;
	}
	.account .tab-pane h1.title.icon-left > span {
		margin-right: 5px;
	}
	.consultation .consult-title > div {
		margin-top: 0;
	}
	.consultation .consult-title-action {
		width: 100%;
	}
	.consultation .consult-title-action button {
		width: 100%;
		min-width: auto;
		padding: 5px;
	}
	.consultation .consult-title-action .btn-send-doc {
		margin: 0.75rem 0 24px;
	}
	.follow-case {
		font-size: 12px;
		padding: 10px;
		line-height: 18px;
	}
	.card-body.follow-up {
		font-size: 12px;
		padding: 1rem;
	}
	.follow-up h4 {
		font-size: 16px;
	}
	.follow-up .col-12 {
		text-align: center;
	}
	.follow-up .col-12 > svg:last-child {
		margin-left: 3rem;
	}
	.follow-up .col > div:last-child {
		margin: 5px 0 0.75rem;
		line-height: 15.6px;
	}
	.timeline-status {
		font-size: 12px;
	}
	.timeline-status .text-is {
		margin-right: 0.25rem;
	}
	.timeline-status .text-status {
		font-size: 16px;
	}
	.line-status-consult {
		padding: 1rem 0;
	}
	.line-status-consult .icon svg, .line-status-consult .icon img {
		width: 28px;
		height: 28px;
	}
	.line-status-consult .line {
		margin: 0 10px;
	}
	.block-detail-consult {
		flex-direction: column-reverse;
	}
	.block-detail-consult .payment h5.card-title .badge {
		font-size: 12px;
	}
	.receipt-drug .btn-close {
		position: absolute;
		right: 1rem;
		top: 1rem;
		font-size: 12px;
	}
	.wait-pharmacy {
		margin-top: 100px;
	}
	.wait-pharmacy h3 {
		font-size: 20px;
	}
	.wait-pharmacy img {
		width: 80%;
	}
	.drug-payment {
		padding-top: 24px;
	}
	.drug-payment h1 {
		font-size: 24px;
	}
	.calling .consult {
		margin: 32px 0;
		text-align: center;
	}
	.calling .consult h2, .calling .consult img {
		margin-bottom: 0.5rem !important;
	}
	.calling .btn {
		min-width: auto;
		width: 100%;
	}
	.filter-doctor {
		display: grid;
		gap: 8px;
	}
	.history .card.list .icon-drugs {
		margin: -2px 0 0 6px;
	}
	.consultation .block-coupon {
		background: transparent url(../images/bg-coupon-sm.svg) no-repeat left;
		max-width: 360px;
		padding: 1rem 1rem 1rem 50px;
	}
	.list-coupons .block-insure {
		padding: 1rem;
	}
	.block-home > section {
		margin: 88px 0;
	}
	.block-home .landing {
		display: block;
		padding: 0;
		background: none;
	}
	.block-home .landing .box-text {
		max-width: 100%;
	}
	.block-home .landing .box-text > div {
		font-size: 30px;
	}
	.block-home .landing .box-text p {
		padding: 20px 0;
	}
	.block-home .landing .box-text img {
		border-radius: 20px;
		margin-bottom: 20px;
	}
	.block-home .landing .box-image {
		width: 100%;
	}
	.block-home .landing .box-image img {
		margin-bottom: 20px;
	}
	.block-home .landing .box-search, .search-bar .box-search {
		position: relative;
		gap: 8px;
	}
	.block-home .landing .box-search .btn-search, .search-bar .box-search .btn-search {
		min-width: 48px;
	}
	.health-record {
		flex-direction: column-reverse;
	}
	.health-record img {
		margin-bottom: 40px;
	}
	.health-record .maw-200, .title-content.news .maw-200 {
		max-width: 100%;
	}
	.title-content.news {
		margin-top: 40px;
	}
	.nursing-top .description {
		max-width: 380px;
		padding: 0 1rem;
	}
	.nursing-top .description h2 {
		font-size: 20px;
		line-height: 28px;
	}
	.block-nursing .box-search .filter, .search-bar .box-search.nursing .filter {
		min-width: 60px;
	}
	.block-nursing .block-list > div:last-child h3.no-data {
		font-size: 20px;
	}
	.block-nursing .block-list .card-nursing {
		margin-bottom: 20px;
	}
	.images-overview, .images-overview .img-sm > div {
		gap: 4px;
	}
	.block-nursing-detail .title .center-name {
		flex-direction: column;
		align-items: flex-start;
		gap: 10px;
	}
	.block-nursing-detail .title .center-name h3 {
		font-size: 20px;
	}
	.block-nursing-detail .title .center-rating {
		margin: 0;
	}
	.block-nursing-detail .title > div:last-child {
		justify-content: space-between;
	}
	.block-nursing-detail .title button {
		flex: 1 0;
	}
	.block-room-style .img-1, .block-room-style .img-1 img {
		width: 100%;
		border-radius: 1rem 1rem 0 0;
	}
	.block-room-style .img-list > div {
		width: 100%;
		height: 100%;
		aspect-ratio: 1/1;
	}
	.block-room-style .detail {
		padding: 0 8px;
	}
	.block-room-style > div:nth-child(2) {
		flex: unset;
	}
	.block-room-style .action {
		padding: 0 8px 1rem;
	}
	.block-room-style .action > div:last-child, .block-reviews > div:first-child {
		flex-direction: column;
		width: 100%;
	}
	.block-room-style .action button, .block-room-style .action .security-deposit {
		width: 100%;
	}
	.block-room-style .action .security-deposit .remark {
		display: flex;
		width: 100%;
		gap: 4px;
	}
	.block-reviews > div:first-child {
		align-items: baseline;
		gap: 8px;
	}
	.react-tel-input.custom.max-width-300 {
		max-width: 100%;
	}
	.block-payment-info .payment-info .summary > div > div:first-child > span:first-child {
		min-width: 23px;
	}
	.block-payment-info .payment-info .summary > div > div:last-child > span:first-child {
		min-width: 80px;
		text-align: right;
	}
	.account .history .nav-tabs {
		display: block;
		overflow-x: auto;
		white-space: nowrap;
	}
	.history .nav .nav-item {
		display: inline-block;
	}
	.history .nav .nav-item .nav-link {
		min-width: 220px;
	}
	.history .card.list.nhc > div {
		display: flex;
		flex-direction: column;
		font-size: 12px;
		gap: 8px;
	}
	.history .card.list.nhc > div > div {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}
	.nhb-detail .block-payment-info .room-info > div {
		flex-direction: column;
		gap: 8px;
	}
	.nhb-detail .block-payment-info .room .img {
		width: 100%;
	}
}

@media (max-width: 414px) {
	.medical-dpt {
		width: 194px;
	}
}

@media (max-width: 393px) {
	.navbar-button .nav-item.dropdown .dropdown-menu, .consult .timezone .dropdown-menu {
		width: 358px;
	}
	.modal-custom .block-form .otp input, .otp input {
		width: 42px !important;
		height: 45px;
	}
	.consult-anytime img {
		width: 170px;
	}
	.medical-dpt {
		width: 180px;
	}
}

@media (max-width: 375px) {
	.navbar-button .nav-item.dropdown .dropdown-menu, .consult .timezone .dropdown-menu {
		width: 343px;
	}
	.consult-anytime img {
		width: 163px;
	}
	.block-home .medical-services-title, .home-title, .h-promotion .home-title, .free-program h1 {
		font-size: 20px;
	}
	.block-home .medical-services-desc, .doctor-list .card .card-detail .text-secondary-200 strong, .doctor-list .card .card-action, .consult-anytime p, .h-promotion p {
		font-size: 14px;
	}
	.doctor-list .card .card-detail h3 {
		font-size: 18px;
	}
	.doctor-list .card .card-detail h3 img {
		width: 20px;
	}
	.medical-dpt {
		width: 175px;
	}
}

@media (max-width: 360px) {
	.navbar-button .nav-item.dropdown .dropdown-menu, .consult .timezone .dropdown-menu {
		width: 328px;
	}
	.modal-custom .block-form .otp input, .otp input {
		width: 40px !important;
		height: 45px;
	}
	.medical-dpt {
		width: 168px;
	}
	.navbar-toggler, .canvas-menu .nav {
		flex-basis: 78px;
	}
}


.is-not-support-browser {
	text-align: center;
	padding: 10px;
	padding-top: 150px;
}
.is-not-support-videocam {
	font-size: 30px;
}
.url-copy-link {
	padding: 10px;
	border: 1px solid #dddddd;
	width: 400px;
	margin: auto;
	margin-bottom: 20px;
	margin-top: 20px;
}
.show-room-video {
	position: fixed;
	top: 10px;
	left: 10px;
	padding: 5px;
	padding-left: 10px;
	padding-right: 10px;
	background-color: #a7a7a7;
	border-radius: 5px;
	font-size: 12px;
	opacity: 0.5;
}
.text-link-android {
	padding: 10px;
}
.text-link-android-copy {
	margin-top: 10px;
}
.flex-menubar-fixed {
	position: fixed;
	bottom: 0px;
	left: 0px;
	width: 100%;
	z-index: 100;
	justify-content: center;
	background-color: $primary-700;
	padding-top: 5px;
	padding-bottom: 5px;
}
.fixed-menu {
	background-color: $primary-700;
	text-align: center;
	color: #ffffff;
}
.fixed-icon-menu {
	position: relative;
	width: 25px;
	margin: auto;
}
.search-map-box {
	position: relative;
	margin-top: -300px;
	margin: auto;
	background-color: $primary-900;
	padding: 10px;
}
.search-map-input {
	position: relative;
	width: 300px;
	margin: auto;
}
#pac-input {
	width: 250px;
	padding: 5px;
	border: 1px solid $primary;
	text-align: center;
}
.search-map-result-lists {
	position: absolute;
	width: 300px;
	margin: auto;
	background-color: #FFFFFF;
	padding: 10px;
	max-height: 300px;
	overflow-y: auto;
	margin-left: -150px;
	left: 50%;
	z-index: 1;
	border: 1px solid $primary;
}
.place-list {
	cursor: pointer;
	border-bottom: 1px solid #dddddd;
	padding-top: 5px;
	padding-bottom: 5px;
}
.button-search-place {
	width: 50px;
	border: 1px solid $primary;
	padding: 5px;
}
.chat.dropdown-menu {
	padding: 0 !important;
}
.chat.dropdown-menu > :nth-child(2), .chat.dropdown-menu .dropdown-item.first-child {
	border-radius: 0;
}
.chat .dropdown-header {
	position: sticky;
	top: 0;
	padding: 16px;
	margin-bottom: 0;
	background-color: #FFFFFF;
	z-index: 9;
}
.chat .dropdown-item {
	background-color: #FFFFFF;
	padding: 0 16px;
}
.chat .dropdown-item .row {
	border-bottom: solid 1px $line-gray;
	padding: 8px 0;
}
.chat .dropdown-item .menu-icon {
	width: auto;
	padding-right: 1rem !important;
}
.chat .dropdown-item .menu-icon > div {
	background-color: $surface;
	border-radius: 50%;
	width: 52px;
	height: 52px;
	padding: 12px
}
.chat .dropdown-item .time {
	position: absolute;
	font-size: 12px;
	margin-top: 0.5rem;
	right: 1rem;
	color: $secondary-200;
}
.chat .dropdown-item .noti-title {
	font-weight: bold;
	word-break: break-all;
	white-space: normal;
	max-width: 225px;
}
.chat .dropdown-item .noti-desc {
	font-size: 12px;
	line-height: 15.6px;
	word-break: break-all;
	white-space: normal;
	margin-top: 2px;
}
.un-read-count {
	position: absolute;
	background-color: #FF2828;
	padding: 10px;
	border-radius: 50%;
}

.video-to-room {
	padding: 5px;
	cursor: pointer;
}

.label-to-room {
	text-align: center;
	color: #FF2828;
	font-size: 12px;
}

.icon-video-animation {
	margin-top: 13px;
}

.bg-video-to-room {
	background-color: #E4D3B5;
	padding: 1rem 0;
}

.video-call-animation {
	background: $primary-900;
	width: 64px;
	height: 64px;
	position: relative;
	margin: 0 auto;
	border-radius: 100%;
	border: solid 5px #fff;
	animation: play 2s ease infinite;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	backface-visibility: hidden;
	text-align: center;
}

@keyframes play {
	0% {
		transform: scale(1);
	}
	15% {
		box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.4);
	}
	25% {
		box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.4), 0 0 0 20px rgba(255, 255, 255, 0.2);
	}
	25% {
		box-shadow: 0 0 0 15px rgba(255, 255, 255, 0.4), 0 0 0 30px rgba(255, 255, 255, 0.2);
	}
}
.text-alert-video {
	color: #FF2828;
}
.button-fixed-bottom {
	position: fixed;
	width: 50px;
	height: 50px;
	padding: 8px;
	border: 1px solid #ff7556;
	background-color: #ffffff;
	box-shadow: 0 0 10px #0000001a;
	bottom: 8%;
	right: 10px;
	border-radius: 50%;
	color: #ff7556;
	z-index: 1000;
}
.alert-consult-before-time {
	border: 1px solid #eeeeee;
	background-color: #eeeeee;
	box-shadow: 0 0 10px #0000001a;
	margin: 10px;
	border-radius: 30px;
	padding: 10px 20px 10px 20px;
}
.text-green {
	color: green;
}
.text-red {
	color: red;
}
.alert-consult-before-time-detail {
	border: 1px solid #ffffff;
	background-color: #ffffff;
	box-shadow: 0 0 10px #0000001a;
	margin: 10px;
	border-radius: 30px;
	padding: 10px 20px 10px 20px;
}
.prepare-video {
	position: fixed;
	width: 100%;
	height: 100%;
	background: $primary-700;
	z-index: 1100;
	top: 0;
	left: 0;
}
.prepare-image {
	position: relative;
	background-color: #ffffff;
	width: 150px;
	height: 150px;
	margin: auto;
	margin-top: 100px;
	border-radius: 50%;
	text-align: center;
}
.prepare-name {
	position: relative;
	margin: auto;
	text-align: center;
	color: #ffffff;
	font-size: 24px;
	font-weight: bold;
	margin-top: 30px;
}
.prepare-text-info {
	position: relative;
	margin: auto;
	text-align: center;
	color: #ffffff;
	margin-top: 100px;
}
.prepare-text-auto {
	position: relative;
	margin: auto;
	text-align: center;
	font-size: 12px;
	color: #dddddd;
	margin-top: 10px;
}
.prepare-action {
	position: relative;
	width: 220px;
	margin: auto;
	text-align: center;
	margin-top: 20px;
}
.prepare-avatar {
	width: 150px;
	height: 150px;
	border-radius: 50%;
}
.text-none {
	display: none;
}
.prepare-text-time {
	position: relative;
	margin: auto;
	text-align: center;
	color: #ffffff;
}
.close-link {
	margin-top: 20px;
	text-align: center;
}
.close-link .btn-outline-primary {
	color: #dddddd;
}
.video-text-information {
	position: absolute;
	border: 1px solid #a7a7a7;
	border-radius: 20px;
	margin-top: -100px;
	width: 96%;
	padding: 10px;
	text-align: center;
	left: 2%;
	background: #292929;
	color: #ffffff;

	@media (max-width: $screen-sm) {
		margin-top: -220px;
	}
}
.video-text-information .end-call-alert {
	background: #FF2828;
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
	border-radius: 30px;
	width: 40px;
	height: fit-content;
	padding-left: 10px;
	padding-right: 10px;
	text-align: center;
	margin-left: 5px;
	margin-right: 5px;
	cursor: pointer;

	svg {
		transform: rotate(135deg);
		height: 16px;
		width: 16px;
	}
}
.image-verify img {
	width: 300px;
}
.number-verify {
	border: 1px solid #dddddd;
	padding: 10px;
	margin-bottom: 10px;
	background-color: #eeeeee;
	font-weight: bold;
}
.check-my-coupon {
	text-align: center;
}
.campaign-name-text {
	color: #22c55e;
	padding-top: 0;
	padding-left: 5px;
	padding-right: 5px;
	padding-bottom: 0;
	font-weight: bold;
}
.campaign-name-text svg {
	margin: 0 !important;
}
.lock-drug-with-campaign {
	border: 1px solid #ffeeba;
	padding: 10px;
	margin-bottom: 10px;
	border-radius: 10px;
	background-color: #fff3cd;
}
.lang-switch {
	padding: 5px;
	padding-left: 10px;
	padding-right: 10px;
	margin: 5px;
	background-color: #ffffff;
	border: solid 1px #ffffff;
	border-radius: 10px;
}
.lang-switch-active {
	padding: 5px;
	padding-left: 10px;
	padding-right: 10px;
	margin: 5px;
	background-color: #ffffff;
	border: solid 1px $primary-50;
	border-radius: 10px;
}
.lang-switch:hover {
	border: solid 1px $primary-50;
	cursor: pointer;
}
.lang-switch-active:hover {
	border: solid 1px $primary-50;
	cursor: pointer;
}
.calendar-component {
  ._header {
    ._month-select {
      display: flex;
      gap: 4px;
      align-items: center;
      justify-content: center;
      padding: 0 12.5px;
      .dropdown {
        display: flex;
        flex-shrink: 1;
        color: $primary;
        padding: 8px;
        border-radius: 10px;
        padding-right: 36px;
        background-color: $primary-50;
        border: unset;
        font-family: 'Noto Sans Thai';
        font-size: $size-14;

        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        /* Some browsers will not display the caret when using calc, so we put the fallback first */
        background: url(../images//arrow-down.png) $primary-50 no-repeat calc(100% - 1rem) !important;
        /* Better placement regardless of input width */
      }
    }
    .custom-group-btn {
      display: flex;
      gap: 4px;
      .btn-link {
        color: $secondary;
      }
    }
    ._day-names {
      display: flex;
      gap: 2px;
      align-items: center;
      justify-content: center;
      font-family: 'Noto Sans Thai';
      ._day-name {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        padding: 8px 2px;
        width: 36px;
        text-align: center;
      }
    }
  }
  ._month-display {
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: center;
    justify-content: center;
    ._week {
      display: flex;
      flex-direction: row;
      gap: 2px;
      align-items: center;
      justify-content: center;
      ._day {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0;
        width: 36px;
        height: 36px;
        font-size: $size-12;
        font-weight: 400;
        padding: 8px;
        color: $light-gray;
        cursor: default;
      }
      ._day.active {
        cursor: pointer;
        color: $primary;
      }
      ._day._selected {
        border-radius: 50%;
        background-color: $primary;
        color: $color-white;
      }
      ._day::after {
        position: absolute;
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 50%;
        bottom: 4px;
      }
      ._day.booking::after {
        background-color: $danger;
      }
    }
  }
}

.text-inline {
  display: flex;
  gap: 8px;
  align-items: center;
  >._flex-right {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
  }
}
.text-inline.column {
  flex-direction: column;
  gap: 4px;
  align-items: start;
}
.health-record2 {
  .nav-tabs {
    border-radius: 10px;
    border-top-left-radius: unset;
    border-top-right-radius: unset;
    background-color: $bg-surface;
    margin: auto;
    .nav-item {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      .nav-link {
        border: unset !important;
        color: $light-gray;
        padding: 16px 0;
        background-color: $bg-surface;
      }
      .nav-link.active {
        border-radius: 10px;
        color: $primary;
      }
      .nav-link.active:hover {
        color: $primary-500;
      }
    }
    .nav-item:hover, .nav-item:active {
      border-radius: 10px;
      .nav-link {
        color: $primary;
      }
      .nav-link:hover {
        background-color: $bg-surface;
      }
      background-color: $bg-surface;
    }
    .nav-item.active::after {
      content: "";
      border-bottom: 2px solid $primary;
      margin: auto;
      width: 120px;
      margin-top: 1px;
      margin-bottom: -1px;
    }
    hr {
      border-top: unset;
      border-left: 2px solid $color-white;
      height: 40px;
      margin: auto;
      opacity: 1;
    }
  }
  .tab-content {
    .tab-pane.active {
      display: flex;
      flex-direction: column;
    }
    .tab-pane {
      gap: 20px;
      .drug-selected-total {
        background-color: $danger;
        padding: 2px 10px;
        border-radius: 20px;
      }
      .custom-input-number {
        .custom-number {
          font-size: $size-16;
          font-weight: 300;
          line-height: 24px;
        }
      }
      .date-display {
        .form-control, .input-group-text {
          cursor: pointer;
          background-color: $bg-light;
        }
      }
      .record {
        .card-body {
          background-color: $bg-surface;
          border-radius: 10px;
        }
        .dropdown {
          .dropdown-toggle {
            border: unset;
            border-radius: 16px;
            padding: 8px;
            background-color: $color-white;
            .label {
              margin-right: 2rem;
            }
          }
        }
        .dropdown-toggle::after {
          top: 18px;
          background-image: url(../images/arrow-down-blue.svg);
        }
      }
      .latest {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .block-data {
          border: 1px solid $primary-300;
          border-radius: 10px;
          padding: 8px;
          display: flex;
          flex-direction: column;
          gap: 4px;
          align-items: center;
          justify-content: center;
          margin-bottom: 0;
          :last-child {
            margin-top: 4px;
          }
        }
      }
    }
    .tab-pane.document, .tab-pane.health, .tab-pane.latest {
      margin: auto;
      .row {
        align-items: center;
        margin: 0;
        padding: 1rem 0;
        border-bottom: 1px solid $line-gray;
      }
      .empty {
        margin: 100px auto 40px !important;
        margin: auto;
        color: $light-gray;
      }
    }
  }
}
.health-record2.custom {
  .nav-tabs {
    background-color: unset;
    .nav-item {
      a {
        padding: 10px 0;
        color: $light-gray;
      }
    }
    .nav-item.active {
      a {
        color: $primary-300;
      }
    }
    .nav-item.active::after {
      width: 90%;
    }
  }
  .tab-content {
    .tab-pane.details {
			min-height: 300px;
      > * {
        padding: 10px 0;
        border-bottom: 1px solid $primary;
      }
    }
  }
}
.weekly {
  display: flex;
  justify-content: space-around;
  > * {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .btn {
    width: 52px;
    height: 52px;
    border-radius: 30px;
  }
  .btn.active {
    background-color: $primary;
    border-radius: 30px;
    color: $color-white;
  }
}
